import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ToastController } from "@ionic/angular";
import { TreeNode } from "primeng/api";
import { DocumentManagementService } from "src/app/Services/document-management-service.service";
import { Events } from "src/app/Services/events.service";
import { CreateNode } from "src/app/Models/CreateNode";
import { Node } from "../../Models/Node";
import { AdvanceTextEditor } from "../advance-text-editor";

@Component({
  selector: "app-view-document",
  templateUrl: "./view-document.component.html",
  styleUrls: ["./view-document.component.scss"],
})
export class ViewDocumentComponent implements OnInit {
  @ViewChild("Levelform") Levelform: NgForm | undefined;
  @ViewChild("Nodeform") Nodeform: NgForm | undefined;

  treeModuleData: any;
  editorDisabled: boolean = true;
  isEditButtonClicked: boolean = true;
  nodeData: any;
  ShowLevel;
  ShowContent;
  currentNodeArray: any;
  currentNode: any;
  nodeid: any;
  Parentid: any;
  NodelLevel: any;
  currentNodeLevel: any;
  selectedNode: any;
  IsAddContent: boolean = true;
  displayDialog: boolean;
  show: boolean;
  config: any = AdvanceTextEditor.config;
  users: any;
  selectedUsers: number[] = [];
  username: string;
  checked: boolean = false;

  constructor(
    private dms_service: DocumentManagementService,
    private toastController: ToastController,
    private events: Events
  ) {
    this.events.publish("PageName", "documentation");
    this.username = localStorage.getItem("username");
  }

  ionViewDidEnter() {
    this.getDocumentNodes(this.username);
  }

  getDocumentNodes(username) {
    this.dms_service.getDocumentNodes(username).subscribe((resp) => {
      this.treeModuleData = <TreeNode[]>resp;
      this.treeModuleData.forEach((node) => {
        this.expandTreeRecursive(node, false);
      });
      console.log('treeModuleData',this.treeModuleData);
    });
  }

  expandTreeRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandTreeRecursive(childNode, false);
      });
    }
  }

  getNodeData(node, nodeId) {
    this.editorDisabled = true;
    this.dms_service.getNodeData(nodeId).subscribe((resp) => {
      if (resp != null) {
        this.nodeData = atob(resp.toString());
      } else {
        this.editorDisabled = false;
        this.nodeData = null;
      }
    });
    this.editorDisabled = true;
  }

  addParentNode(parentNodeId, ParentNodeName, ParentNodeLevel) {
    var createNode: CreateNode = {
      NodeId: parentNodeId,
      NodeName: ParentNodeName,
      NodeLevel: ParentNodeLevel,
      Ids: this.selectedUsers,
    };
    this.dms_service.AddParentNode(createNode).subscribe((resp) => {
      let toast = this.toastController.create({
        message: resp.toString(),
        duration: 3000,
        position: "bottom",
      });
      toast.then((toast) => toast.present());
      this.getDocumentNodes(this.username);
      this.Nodeform.reset();
    });
  }
  addChild(parentnode) {
    this.ShowLevel = true;
    this.currentNode = parentnode.label;
    this.currentNodeLevel = parentnode.NodeLevel;
    this.nodeid = parentnode.ID;
    this.NodelLevel = parentnode.NodeLevel;
  }

  addChildNode(parentNodeId, childNodeName, childNodeLevel) {
    var createNode: CreateNode = {
      NodeId: parentNodeId,
      NodeName: childNodeName,
      NodeLevel: childNodeLevel,
      Ids: this.selectedUsers,
    };
    this.dms_service.AddNextLevel(createNode).subscribe((resp) => {
      let toast = this.toastController.create({
        message: resp.toString(),
        duration: 3000,
        position: "bottom",
      });
      toast.then((toast) => toast.present());
      this.getDocumentNodes(this.username);
      this.Levelform.reset();
    });
  }

  showContent(node) {
    this.ShowContent = true;
    this.currentNode = node.label;
    this.nodeid = node.ID;
    this.Parentid = node.parent?.ID;
    this.NodelLevel = node.NodeLevel;
  }

  onSaveContent(content, selectedNode) {
    var base64textString = btoa(unescape(encodeURIComponent(content)));
    this.dms_service
      .AddContent(base64textString, selectedNode)
      .subscribe((resp) => {
        let toast = this.toastController.create({
          message: resp.toString(),
          duration: 3000,
          position: "bottom",
        });
        toast.then((toast) => toast.present());
      });
  }

  ngOnInit() {
    this.editorDisabled = true;
    this.selectedNode = {};
    this.dms_service.getUser().subscribe((x) => {
      this.users = <any[]>x;
      console.log(this.users);
    });
  }

  EditData(LabelName, NodeID) {
    this.dms_service
      .EditLabel(LabelName.EditLabel, NodeID)
      .subscribe((resp) => {
        let toast = this.toastController.create({
          message: resp.toString(),
          duration: 3000,
          position: "bottom",
        });
        toast.then((toast) => toast.present());
        this.getDocumentNodes(this.username);
      });
  }

  deleteContent(node) {
    this.displayDialog = true;
  }

  confirmDeleteAction() {
    this.dms_service.DeleteNode(this.selectedNode.ID).subscribe((resp) => {
      let toast = this.toastController.create({
        message: "Record Got Deleted",
        duration: 3000,
        position: "bottom",
      });
      this.displayDialog = false;
      toast.then((toast) => toast.present());
      this.getDocumentNodes(this.username);
    });
  }

  cancelDeleteAction() {
    this.displayDialog = false;
  }

  moveNodeUpAndDown(move: string, node) {
    var parentId = node.parent === undefined ? 0 : node.parent.ID;
    var newNode: Node = { SequenceId: node.SequenceID, Move: move, ParentId: parentId };
    this.dms_service.UpdateSequenceNumber(newNode).subscribe((result) => {
      if (result) {
        this.getDocumentNodes(this.username);
      } else {
        let toast = this.toastController.create({
          message: "Something went wrong",
          duration: 3000,
          position: "bottom",
        });
        this.displayDialog = false;
        toast.then((toast) => toast.present());
      }
    });
  }

  demo(event) {
    console.log(event)
  }
}
