import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IUser } from '../Models/User';
import { catchError } from 'rxjs/operators';

const apiUrl = environment.apiUrl;

@Injectable()
export class LoginService {
   
    private UserName;

    constructor( private _http: HttpClient) {
      
    }
   public getUserDetails(UserName, Password): Observable<IUser> {
    //let apiUrl = this.config.get('apiUrl');
    const formData = new FormData();
    formData.append('UserName', UserName);
    formData.append('Password', Password);
    return this._http.post<IUser>(`${apiUrl}/` +
      `api/Login/ValidateUser`, formData).pipe(catchError(this.errorhandler));;

  }
   public getUserDetailsWithoutPassword(UserName): Observable<IUser> {
      //debugger;
        return this._http.get<IUser>(`${apiUrl}/` +
            `api/Login/ValidateUserName?UserName=${UserName}`)
            .pipe(catchError(this.errorhandler));
    }
    public errorhandler(error: HttpErrorResponse) {
        return throwError(error.message);
    }
	
    public resetPassword(json: any) {
        console.log(json);
        return this._http.post(`${apiUrl}/${'api/Login/ResetPassword'}`,json);
      }

     public forgotPassword(email: any) {
    var  host="http://localhost:8100/";
        return this._http.get(`${apiUrl}/${'api/Login/ForgotPassword?Email_Id=' + email+'&host='+host}`);
      }
      
	 public GenerateOTPEmail(Email) {
        return this._http.get(`${apiUrl}/${'api/Login/SendOTPEmail_v1?Email_Id=' + Email}`);
    }
}
