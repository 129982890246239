import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { Events } from '../Services/events.service';
import { DashboardPage } from './dashboard.page';
import { SharedModule } from '../shared/shared.module';
import {ButtonModule} from 'primeng/button';

// import { Menu149Page } from '../mainpage/menu149/menu149.component';
// import { Menu151Page } from '../mainpage/menu151/menu151.component';
// import { Menu152Page } from '../mainpage/menu152/menu152.component';
//import { DashboardAuthGuard } from '../Services/dashboard-auth-guard.service';
import { MainPageService } from '../Services/MainPage.service';

import { InputSwitchModule } from 'primeng/inputswitch';


const routes: Routes = [
    {
        path: '',
        component: DashboardPage
    }
];


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        InputSwitchModule,
        ButtonModule,
        // MatTableModule, MatSortModule, MatButtonModule,
        //   MatFormFieldModule,
        //   MatInputModule,
        SharedModule,
        // MatPaginatorModule,
        // MatRippleModule,
        // MatCardModule,
        // MatProgressSpinnerModule,
        // MatButtonToggleModule,MatIconModule,
        // MatExpansionModule,
        RouterModule.forChild(routes)

    ],
    declarations: [DashboardPage],
    providers: [],
    entryComponents: []
})
export class DashboardPageModule {

}
