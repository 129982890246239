import { Component, OnInit, Type, NgModuleFactory, Compiler, ViewChild } from '@angular/core';
import { ActivatedRoute, Routes, Route, NavigationExtras } from '@angular/router';
import { MenuService } from '../Services/menu.service';
import { AzureCommunication } from '../Services/AzureCommunication.service';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
//import { Page1Component } from './page1/page1.component';
import { MenuItem } from 'primeng/api';
import { Events } from '../Services/events.service';
import { AuthenticationService } from '../Services/authentication.service';
// import { labeledStatement } from 'node_modules_1/@babel/types/lib';
import { MenuController } from '@ionic/angular';
import { VirtualScroller } from 'primeng/virtualscroller';
import { AUTO_STYLE } from '@angular/animations';
import { ChatComponent } from '../components/ACS/chat-component/chat-component.component';
import { map } from 'jquery';

@Component({
    selector: 'app-mainpage',
    templateUrl: './mainpage.page.html',
    styleUrls: ['./mainpage.page.scss'],
})
export class MainpagePage implements OnInit {
    items = [];
    home = { icon: 'pi pi-home' };
    showSubMenu: boolean = false;
    currentMenuPath: any;
    showMenuFlow: boolean = false;
    showTopMenu: boolean = false;
    submenuindex: any;
    OpenMenu: boolean = false;
    UserName: string;
    routerEventSubscription: any;
    ID: number;
    SubMenu: any;
    MenuData: any;
    ParentID: any;
    recentMenus = [];
    frequentlyUsedMenus = [];
    recentlyUsedMenus = [];

    CurrentActiveMenuIndex: any;
    CurrentActiveMenuParentIndex: any;
    highlightParent: any;
    tempMenuData: any[] = [];
    timelineData: any[] = [];
    allMenus: any[];
    breadcrumbsitems: any[];
    showTopMenusWithFlow: boolean = false;
    activeTopMenuItem: any = {};



    constructor(private activatedroute: ActivatedRoute, private menuservice: MenuService,
        private router: Router, public events: Events,
        private activateRouter: ActivatedRoute,
        private menu: MenuController,
        private _compiler: Compiler,
        private authentication: AuthenticationService, ) {

        console.log('beforactive', activatedroute.routeConfig);
        this.events.publish('PageName', '');
        let CurrentSelectedMenu = localStorage.getItem('PageName')
        if (CurrentSelectedMenu) {
            this.events.publish('PageName', CurrentSelectedMenu);
        }
        events.subscribe('CurrentActiveMenuParentIndex', (indexofmenu) => {
            if (indexofmenu == null) {
                this.CurrentActiveMenuParentIndex = indexofmenu;
            }
        });

        events.subscribe('breadcrumb', (breadcrumb) => {//published in menupage component
            this.items = breadcrumb;
        });

        events.subscribe('timelineData', (timelineData) => {//get timelinedata on every menupage-> publish in menupage components
            this.timelineData = timelineData;
            localStorage.setItem('timelineData', timelineData);
        });

        events.subscribe("showTopMenu", show => {//published in header.page.ts
            this.showTopMenu = show;
        })

        events.subscribe("activeTopMenu", item => {//published in activeTopMenu()
            this.activeTopMenuItem = item;
        })

        // this.events.subscribe("AppMenus", appId => { this.getAppMenus(appId) });

        this.recentMenus = JSON.parse(localStorage.getItem('menus'));
        this.recentMenus.map(m => {
            if (!m.HasTopMenus && !m.items) { m.routerLink = '/menu/first/tabs/' + m.ID }
        });
        if (this.recentMenus) {
            this.frequentlyUsedMenus = this.recentMenus.sort(function (a, b) {
                return a.Frequency > b.Frequency ? -1 : (a.Frequency < b.Frequency) ? 1 : 0
            });
            this.frequentlyUsedMenus = this.frequentlyUsedMenus.slice(0, 5);
            this.recentlyUsedMenus = this.recentMenus.sort(function (a, b) {
                let date1 = new Date(a.LastVisitedOn);
                let date2 = new Date(b.LastVisitedOn);
                return date1 > date2 ? -1 : (date1 < date2) ? 1 : 0;
            });
            this.recentlyUsedMenus = this.recentlyUsedMenus.slice(0, 5);
        }


        events.subscribe('CanActivateNavigation', (menu) => {
            this.CurrentActiveMenuIndex = menu;
            if (this.CurrentActiveMenuIndex) {
                this.MenuData = JSON.parse(localStorage.getItem('StoreddSideMenu'));
                if (this.MenuData) {
                    this.MenuData.forEach(element => {
                        this.nestedFilter(this.CurrentActiveMenuIndex, element, element, element);
                    });
                }
            }
        });


        this.CurrentActiveMenuIndex = location.pathname;
        if (this.CurrentActiveMenuIndex) {
            this.MenuData = JSON.parse(localStorage.getItem('StoreddSideMenu'));
            if (this.MenuData) {
                this.MenuData.forEach(element => {
                    this.nestedFilter(this.CurrentActiveMenuIndex, element, element, element);
                });
            }
        }

    }

    updateSubMenuOnReload(menu, top) {
        console.log(this.MenuData);
        console.log('vari1', menu, 'vari2', top);
        if (menu != null) {
            this.events.publish('PageName', menu.MenuName);
            localStorage.setItem('PageName', menu.MenuName);

            localStorage.setItem('CurrentActiveMenuParentIndex', menu.ID);
            this.CurrentActiveMenuParentIndex = localStorage.getItem('CurrentActiveMenuParentIndex');
            if (top && top.TopMenus != null) {
                localStorage.setItem('CurrentActiveMenuIndex', top.routerLink);
                this.CurrentActiveMenuIndex = localStorage.getItem('CurrentActiveMenuIndex');
                this.getTopMenus(top.TopMenus);
                this.showTopMenu = true;
                if (top.ShowFlow) this.showTopMenusWithFlow = true;
            }
            else {
                this.showTopMenu = false;//if doesn't have topmenus
            }
            if (!menu.TopMenus && !menu.items) {
                this.updateFrequentAndRecentMenus(menu);
            }
        }
    }

    navigate(i, LinkedMenu) {
        this.showMenuFlow = false;
        let qm = { "rowval": i };

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "rowval": i
            }

        };
        localStorage.setItem('navigationExtras', JSON.stringify(qm));
        this.events.publish('navigationExtras', qm);
        if (LinkedMenu != 0) {
            this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);
        }

    }

    appmenuclick(menu, menuIndex) {
        
        // this.events.publish('PageName', menu.MenuName);
        // localStorage.setItem('PageName',menu.MenuName);
        //localStorage.setItem('CurrentActiveMenuIndex',menu.routerLink);
        // this.CurrentActiveMenuIndex = localStorage.getItem('CurrentActiveMenuIndex');
        // this.CurrentActiveMenuParentIndex = localStorage.getItem('CurrentActiveMenuParentIndex');
        // if (menu.TopMenus != null)
        // {
        //   this.getTopMenus(menu.TopMenus);
        //   this.showTopMenu=true;
        // }
        // else 
        // {
        //   this.showTopMenu=false;//if don't have topmenus
        // }
        // this.updateFrequentAndRecentMenus(menu.ID);
        this.showMenuFlow = false;
        this.showTopMenu = false;
        this.showTopMenusWithFlow = false;
        if (menu.routeLink) {
            this.MenuData.forEach(element => {
                this.nestedFilter(menu.routerLink, element, element, element);
            });
        }
        if (menu.routerLink) {
            let sidebar = document.querySelector(".sidemenubar");
            if (!sidebar.classList.contains('close')) {
                sidebar.classList.toggle("close");
            }
            this.router.navigate([menu.routerLink]);
        }
        else {
            this.submenuindex = menuIndex;
            this.showSubMenu = !this.showSubMenu;
        }
    }

    activeTopMenu(menuitem) {
        localStorage.setItem("activeTopMenu", menuitem);
        this.router.navigateByUrl('/menu/first/tabs/' + menuitem.ID)
            .then(succeeded => {
                if (succeeded) {
                    this.events.publish("activeTopMenu", menuitem);
                }
                else {
                    console.log("Unable to route");
                }
            })
            .catch(error => {
                // Handle the error
            });;
    }

    nestedFilter(search, element, parentElement, top) {
        if (element.routerLink == search) {
            this.updateSubMenuOnReload(parentElement, top);
            if (!element.TopMenus && !element.items) {
                this.updateFrequentAndRecentMenus(element);
            }
        }
        else {
            if (element.items != null) {
                element.items.forEach(element => {
                    this.nestedFilter(search, element, parentElement, element);
                });
            }
            else {
                if (element.TopMenus != null) {
                    element.TopMenus.forEach(elements => {
                        this.nestedFilter(search, elements, parentElement, element);
                    });
                }
                else {
                    return false;
                }
            }
        }

    }


    ngOnInit() {
        var encrypted = localStorage.getItem('username');
        this.UserName = localStorage.getItem('username');
        this.UserName = encrypted;


        this.recentMenus = JSON.parse(localStorage.getItem('menus'));
        this.recentMenus.map(m => {
            if (!m.HasTopMenus && !m.items) { m.routerLink = '/menu/first/tabs/' + m.ID }
        });

        if (this.recentMenus) {
            this.frequentlyUsedMenus = this.recentMenus.sort(function (a, b) {
                return a.Frequency > b.Frequency ? -1 : (a.Frequency < b.Frequency) ? 1 : 0
            });
            this.frequentlyUsedMenus = this.frequentlyUsedMenus.slice(0, 5);
            this.recentlyUsedMenus = this.recentMenus.sort(function (a, b) {
                let date1 = new Date(a.LastVisitedOn);
                let date2 = new Date(b.LastVisitedOn);
                return date1 > date2 ? -1 : (date1 < date2) ? 1 : 0;
            });
            this.recentlyUsedMenus = this.recentlyUsedMenus.slice(0, 5);
        }

        let timelineDatas = JSON.parse(localStorage.getItem('timelineData'));
        if (timelineDatas) {
            this.timelineData = Object.keys(timelineDatas).map(function (i) { return timelineDatas[i]; });
        }

        let currentApp = localStorage.getItem("CurrentApp");
        let appID: number;
        if (currentApp != undefined && currentApp != null) {
            appID = JSON.parse(currentApp).ID;
        }
        
        if(appID!=undefined)
        {
             if (!Number.isNaN(appID)) {
            this.getAppMenus(appID);//for initial load
            if (!appID) {
                this.MenuData = JSON.parse(localStorage.getItem('StoreddSideMenu'));
            }
            this.events.subscribe("CurrentAppID", (appID) => {//for revisit
                this.getAppMenus(appID);
                // var CacheSubMenu = localStorage.getItem('SubMenu');
                // if (CacheSubMenu != "null") {
                //   CacheSubMenu = JSON.parse(localStorage.getItem('SubMenu'))
                //   this.SubMenu = CacheSubMenu;
                //   this.showTopMenu=true;
                // }
                this.CurrentActiveMenuIndex = localStorage.getItem('CurrentActiveMenuIndex');
            });
        }
        
        }
        else {
            let CurrentApp = localStorage.getItem("CurrentApp");
            if (CurrentApp != "" && CurrentApp != null && CurrentApp != undefined)
                this.MenuData = JSON.parse(localStorage.getItem('StoreddSideMenu'));
            else
                this.getAppMenus(0);//for no subgroup or app
        }
        

       
        // if(localStorage.getItem("activeTopMenu")!="undefined")
        // {
        //     let menuitem=JSON.parse(localStorage.getItem("activeTopMenu"));
        //     this.events.publish("activeTopMenu",menuitem);
        // }
    }

    getAppMenus(appID) {

        this.menuservice.getAppMenus(this.UserName, appID).subscribe(data => {

            // this.runRecursive(data);
            var resultArray = Object.keys(data).map(function (personNamedIndex) {
                let person = data[personNamedIndex];
                return person;
            });
            // resultArray.forEach(element => {
            //   if (element.items != null) {
            //     element.items.forEach(subelement => {
            //       subelement.icon = null;
            //     });
            //   }
            //   if (element.routerLink != null && element.routerLink != "") {
            //     var routerLink = [];
            //     routerLink.push(element.routerLink);
            //     element.routerLink = routerLink;
            //   }
            // });
            localStorage.setItem('StoreddSideMenu', JSON.stringify(resultArray));
            this.MenuData = resultArray;
            this.tempMenuData = this.MenuData;
            console.log(this.MenuData, 'menudata');
        });
    }
    sidemenubarclick() {
        let sidebar = document.querySelector(".sidemenubar");
        sidebar.classList.toggle("close");
    }
    ngAfterViewInit() {
        // let menuitem = document.getElementsByClassName("menu-link");
        // if (menuitem[0] != undefined)
        //   menuitem[0].classList.add("activeMenu");

        this.menuservice.GetApps(this.UserName).subscribe(resp => {
            let apps = resp;
            var appsArray = Object.keys(apps).map(function (appIndex) {
                let person = apps[appIndex];
                return person;
            });
            // appsArray.forEach(element => {
            //   element.icon = 'pi pi-chart-line';
            // });
            this.events.publish('Apps', apps);
        })
    }




    getTopMenus(topMenus) {
        if (topMenus.length > 0) {
            this.SubMenu = topMenus;
        }

        localStorage.setItem("SubMenu", JSON.stringify(this.SubMenu));
    }

    updateFrequentAndRecentMenus(menu) {
        let menuClicked = this.recentMenus.find(m => m.ID == menu.ID);
        console.log('recentmenus', this.recentMenus);
        if (menuClicked != undefined) {
            menuClicked.Frequency = menuClicked.Frequency + 1;
            menuClicked.routerLink = menu.routerLink;
            menuClicked.LastVisitedOn = this.GetDateTime(new Date());
            this.frequentlyUsedMenus = this.recentMenus.sort(function (a, b) { return a.Frequency > b.Frequency ? -1 : (a.Frequency < b.Frequency) ? 1 : 0 });
            this.frequentlyUsedMenus = this.frequentlyUsedMenus.slice(0, 5);
            let recentlyUsedMenus = this.recentMenus.sort(function (a, b) {
                let date1 = new Date(a.LastVisitedOn);
                let date2 = new Date(b.LastVisitedOn);
                return date1 > date2 ? -1 : (date1 < date2) ? 1 : 0;
            });
            this.recentlyUsedMenus = recentlyUsedMenus.slice(0, 5);
            console.log("recentlyUsedMenus", this.recentlyUsedMenus, 'frek', this.frequentlyUsedMenus);
        }
        this.menuservice.UpdateFrequentAndRecentMenus(menu.ID, this.UserName).subscribe(resp => {
            console.log(resp);
        });
    }

    GetDateTime(date) {
        let adate = date;
        var ayear = adate.getFullYear();
        var amonth: any = adate.getMonth() + 1;
        var ahour: any = adate.getHours();
        var aminute: any = adate.getMinutes();
        var adt: any = adate.getDate();
        if (adt < 10) { adt = '0' + adt; }
        if (amonth < 10) { amonth = '0' + amonth; }
        return ayear + '-' + amonth + '-' + adt + " " + ahour + ":" + aminute + ":00";
    }

    activeMenu(event) {
        let node;
        if (event.target.tagName === "A") {
            node = event.target;
        } else {
            node = event.target.parentNode;
        }
        let menuitem = document.getElementsByClassName("ui-menuitem-link");
        for (let i = 0; i < menuitem.length; i++) {
            menuitem[i].classList.remove("active");

        }
        node.classList.add("active");
    }
}

