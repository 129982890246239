import { Injectable, OnInit } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Table } from "primeng/table";
import { Events } from "../Services/events.service";
import { MainPageService } from "../Services/MainPage.service";
import { MessageService } from "primeng/api";
 
@Injectable({
    providedIn: 'root'
})
 
export class RuleEngine implements OnInit{
 
    RuleJson:any
    highlightcard: number;
    Sys_Menu_ID: number =1450;
    filterRuleIndex:number;
    ruleErrorData: any[] = [];
    ModulesRuleData: any[] = [];
    Validation: boolean = false;
    ngOnInit(): void {
    }
 
    constructor(private mainpageservice:MainPageService,private router:Router,private events: Events,private messageService: MessageService){}
 
    GetPropertyRule(pkColumn, pkColumnValue, column, ModuleDataWithRuleArray, moduleIndex?: number) {
        let propertyList: string = "";
        if (moduleIndex == null) moduleIndex = 0;
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            return "";
        let dataWithRules = ModuleDataWithRuleArray;
        if (dataWithRules != null) {
            // let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
            //     let data = dataWithRules[index];
            //     return data;
            // });

 
 
            if (column != "") {
                propertyList = ModuleDataWithRuleArray.find(d => d[pkColumn].Value == pkColumnValue)[column].PropertyList;
 
                this.RuleJson = ModuleDataWithRuleArray.find(d => d[pkColumn].Value == pkColumnValue)[column];
            }
 
 
        }
 
        return propertyList;
    }
 
    GetRuleLink(pkColumn, pkColumnValue, column, ModuleDataWithRuleArray, dt_count) {//navigate to menu when rule is applied
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            this.navigate(pkColumnValue, this.Sys_Menu_ID);
        else {
            // let dataWithRules = ModuleDataWithRuleArray[dt_count];
            // let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
            //     let data = dataWithRules[index];
            //     return data;
            // });
 
            let ruleLinkMenu: number;
            ruleLinkMenu = ModuleDataWithRuleArray.find(d => d[pkColumn].Value == pkColumnValue)[column].LinkMenuId;
            if (ruleLinkMenu != 0) {
                this.Validation = false;
                setTimeout(() => {
                    this.navigate(pkColumnValue, ruleLinkMenu);
                }, 2000)
            } 
        }
    }
 
    navigate(i, LinkedMenu, FilterName?: string, rowIndex?: number) {
        if (rowIndex > -1) { this.highlightcard = rowIndex; }
        let qm = {};
        if (FilterName == null) {
            qm = { "rowval": i };
        }
        else {
            qm[FilterName] = {};
            qm[FilterName].Text = i;
        }
        let navigationExtras: NavigationExtras =
            { queryParams: qm };
        localStorage.setItem('navigationExtras', JSON.stringify(qm));
        this.events.publish('navigationExtras', qm);
        if (LinkedMenu != 0) this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);
        // this.router.navigate(["/menu/first/tabs/GotoForm"], navigationExtras);
    }

 
    filterRuleData(count, dt_count, ruleName, ruleMessage, dt: Table,Module,ModuleDataWithRuleArray,primeNgTableArray,totalRecords,firstcount,rowscount) {
        debugger;
        this.filterRuleIndex = dt_count;
        // let filteredData=this.ModuleDataWithRuleArray[dt_count].filter(val=>val[ruleName].PropertyList!=null);
        let filteredData = ModuleDataWithRuleArray[dt_count].filter(val => val[ruleName].Tooltip == ruleMessage);
        let ids = filteredData.map(item => Number(item.ID.Value))
        this.ruleErrorData = ids;
        console.log('RuleData', this.ruleErrorData);
        console.log('DataTable', dt)
        let dataToFilter = Module[0].moduleList[dt_count].moduleData;
        console.log('moduleData', dataToFilter);
        console.log('moduleDataLength', totalRecords[dt_count]);
        // console.log('filteredData',dataToFilter.filter(RuleFilterData.bind(this)))
        this.ModulesRuleData = [];
        this.ModulesRuleData = dataToFilter.filter(this.RuleFilterData.bind(this))
        console.log(this.ModulesRuleData)
        totalRecords[dt_count] = this.ModulesRuleData.length;
        // this.primeNgTableArray[dt_count]=dataToFilter.filter(this.RuleFilterData.bind(this)).slice(this.firstcount,(this.firstcount + this.rowscount))
        primeNgTableArray[dt_count] = <any[]>this.ModulesRuleData.slice(firstcount, (firstcount + rowscount));
 
    }
    RuleFilterData(data) {
        let reusltArray: boolean = false
        // for(let i=0;i<this.ruleErrorData.length;i++){
        //     if(data.filter(val=>val.ID==this.ruleErrorData[i])) reusltArray=true
        // }
        this.ruleErrorData.forEach(errorId => {
            if (data['ID'].toString() === (errorId.toString())) reusltArray = true
        })
        return reusltArray;
    }
 
    clearFilter(moduleIndex,Module,primeNgTableArray,totalRecords,firstcount,rowscount) {
        let dataToFilter = Module[0].moduleList[moduleIndex].moduleData;
        //   let moduleData=[];
        //   moduleData=dataToFilter
        totalRecords[moduleIndex] = dataToFilter.length;
        primeNgTableArray[moduleIndex] = dataToFilter.slice(firstcount, (firstcount + rowscount))
        this.filterRuleIndex = undefined;
    }

    //Expression Maker code is Used For Admin Portal Only, don't touch it please

    //Expression Maker Code Starts Here

    showExpMaker: any;
    arithmaticArray: any[] = [{Text:"+", Value:"+"},{Text:"-", Value:"-"},{Text:"x", Value:"*"},{Text:"%", Value:"%"}];
    comparisonArray: any[] = [{Text:"=", Value:"Equal"},{Text:">", Value:"GreaterThan"},{Text:"<", Value:"LessThan"},{Text:">=", Value:"GreaterThanEqual"},{Text:"<=", Value:"LessThanEqual"}];
    currentCol: any = "";
    arithmaticOp: any = "";
    comparisonOp: any = "";
    benchmarkValue1: any = "";
    benchmarkValue2: any = "";
    LHSExp: any = "";
    finalExp: any;

    showToast(message: string)
    {
        this.messageService.add({severity:'warn', summary: message});
    }

    makeFinalExp()
    {
        if(this.comparisonOp != "")
        {
            this.finalExp = this.LHSExp +" "+ this.comparisonArray.find(item => item.Value == this.comparisonOp).Text  +" "+ this.benchmarkValue1;
        }
        else{
            this.finalExp = this.LHSExp +" "+ this.benchmarkValue1;
        }
    }

    removeLastColumn(expression: string): string {
        // Split the expression by the operators (+, -, *, %)
        const operators = /[+\-*%]/;
        const parts = expression.split(operators);
      
        // If there are at least two parts (columns) separated by an operator, remove the last part
        if (parts.length >= 2) 
        {
          const lastOperatorIndex = expression.lastIndexOf(parts[parts.length - 1]);
          const updatedExpression = expression.substring(0, lastOperatorIndex).trim();
          return updatedExpression;
        } 
        else 
        {
          // No column to remove, return the original expression
          return expression;
        }
      }

    removeLastOperator(expression: string): string {
        // Define a regular expression to match the last occurrence of +, -, %, or *
        const lastOperatorRegex = /[+\-*/%](?!.*[+\-*/%])/;
      
        // Find the last operator
        const match = expression.match(lastOperatorRegex);
      
        if (match) 
        {
          // Found a last operator, remove it while preserving the rest of the string
          const lastOperatorIndex = expression.lastIndexOf(match[0]);
          const updatedExpression = expression.slice(0, lastOperatorIndex) + expression.slice(lastOperatorIndex + 1);
          return updatedExpression;
        } 
        else 
        {
          // No last operator found, return the original expression
          return expression;
        }
      }

    setLHSExpCol()
    {
        if(this.LHSExp == "")
        {
            this.LHSExp = (this.currentCol +" "+ this.arithmaticOp).replace(/\s+$/, ''); //remove whitespace at the end
        }
        else
        {
            if(/[+\-*%]$/.test(this.LHSExp)) //To check whether string contains +,-,*,% at the end
            {
                this.LHSExp = this.LHSExp +" "+ this.currentCol;
            }
            else
            {
                if(/[+\-*%]/.test(this.LHSExp)) //To check whether string contains +,-,*,%
                {
                    this.LHSExp = this.removeLastColumn(this.LHSExp); //Remove last column
                    this.LHSExp = this.LHSExp +" "+ this.currentCol; // And replace it with current selected column
                }
                else
                {
                    this.LHSExp = this.currentCol; // Replace column with current selected column
                }
            }
        }

        if(this.benchmarkValue2 == "")
        {
            this.makeFinalExp();
        }
        else
        {
            this.setFinalExpBench2();
        } 
    }

    setLHSExpArith()
    {
        this.benchmarkValue2 = "";

        if(this.currentCol != "")
        {
            if(this.LHSExp != "")
            {
                if(/[+\-*%]$/.test(this.LHSExp)) //To check whether string contains +,-,*,% at the end
                {
                    this.LHSExp = this.removeLastOperator(this.LHSExp); //Remove the last operator
                    this.LHSExp = this.LHSExp + this.arithmaticOp; //And set current selected operator there
                }
                else
                {
                    this.LHSExp = this.LHSExp + " " + this.arithmaticOp;
                }
            }
            else
            {
                this.LHSExp = this.currentCol + " " + this.arithmaticOp;
            }

            this.makeFinalExp();
        }      
    }

    setExpComp()
    {
        if(this.benchmarkValue2 == "")
        {
            if(this.LHSExp != "")
            {
                this.makeFinalExp();
            }
        }
        else
        {
            this.setFinalExpBench2();
        }
    }

    setFinalExpBench1()
    {
        if(this.benchmarkValue2 == "")
        {
            this.makeFinalExp();
        }
        else
        {
            this.setFinalExpBench2();
        }       
    }

    setFinalExpBench2()
    {
        if(this.currentCol == "")
        {
            this.showToast("Please select the column first");
        }
        else
        {
            if(this.comparisonOp == "Equal")
            {
                this.showToast("Please select another comparison operator to set range"); //because range expression doesn't contains equalTo sign
            }
            else
            {
                if(this.benchmarkValue1 == "")
                {
                    this.showToast("Please enter Benchmark Value 1 first");
                }
                else
                {
                    this.finalExp = "";
                    this.LHSExp = "";
                    this.arithmaticOp ="";

                    switch (this.comparisonOp) 
                    {
                        case "LessThan":
                            this.finalExp = this.benchmarkValue1 + " < " + this.currentCol + " > " + this.benchmarkValue2;
                        break;
                        case "GreaterThan":
                            this.finalExp = this.benchmarkValue1 + " > " + this.currentCol + " < " + this.benchmarkValue2;
                        break;
                        case "LessThanEqual":
                            this.finalExp = this.benchmarkValue1 + " <= " + this.currentCol + " >= " + this.benchmarkValue2;
                        break;
                        case "GreaterThanEqual":
                            this.finalExp = this.benchmarkValue1 + " >= " + this.currentCol + " <= " + this.benchmarkValue2;
                        break;
                    }
                }
            }
        }
    }

    clearExp()
    {
        this.showExpMaker = false;
        this.currentCol = "";
        this.arithmaticOp = "";
        this.comparisonOp = "";
        this.benchmarkValue1 = "";
        this.benchmarkValue2 = "";
        this.LHSExp = "";
        this.finalExp = "";
    }

    saveFinalExp()
    {
        if(this.benchmarkValue2 == "") //For normal expressions
        {
            if(this.LHSExp != "" && this.comparisonOp != "" && this.benchmarkValue1 != "")
            {
                if(/[+\-*%]$/.test(this.LHSExp)) //To check whether string contains +,-,*,% at the end
                {
                    this.showToast("Please Put a Column after the last Arithmatic Operator In the Expression");
                }
                else
                {
                    this.showExpMaker = false;
                }
            }
            else if(this.LHSExp == "" && this.comparisonOp != "" && this.benchmarkValue1 != "")
            {
                this.showExpMaker = false;
            }
            else
            {
                this.showToast("Please make a valid Expression");
            }
        }
        else
        {
            //Code for range expression
            if(this.currentCol != "" && this.comparisonOp != "" && this.benchmarkValue1 != "")
            {
                this.showExpMaker = false;
            }
            else
            {
                this.showToast("Please make a valid Expression");
            }
        }
    }

    //XXXXXXXXXXXxxXX Admin Portal Expression Makser Code Ends Here  XXXXXXXXXXXXXXXXXXXXXXXXxx
 
}