import { Component, NgZone, OnInit } from '@angular/core';
import { Events } from '../Services/events.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../Services/authentication.service';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';
import { NotificationsComponent } from './notifications/notifications.component';
import { PopoverController } from '@ionic/angular';
import { MainPageService } from '../Services/MainPage.service';
import { Badge } from '@ionic-native/badge/ngx';
import { MenuItem, MessageService } from 'primeng/api';
import { MenuService } from '../Services/menu.service';
import { NavigationExtras } from '@angular/router';


const AppName = environment.AppName;
@Component({
    selector: 'app-header',
    templateUrl: './header.page.html',
    styleUrls: ['./header.page.scss'],
})
export class HeaderPage implements OnInit {
    checked: boolean;
    name: string;
    companyname: string;
    userName: string;
    AppName = AppName;
    SelectedAppName: any;
    badgeValue: any;
    UserName: string;
    items = [{
        label: 'Options',
        items: [
            //     {
            //     label: 'Home',
            //     icon: 'pi pi-home',
            //     command: () => {
            //         this.home();
            //     }
            // },
            {
                label: 'Help',
                icon: 'pi pi-question-circle',
                command: () => {
                    //this.delete();
                }
            },
            {
                label: 'Raise Ticket',
                icon: 'pi pi-ticket',
                command: () => {
                    this.TicketAppLink();
                }
            },


        ]
    },
    {
        label: 'Profile',
        items: [
            {
                label: 'Go To Profile',
                icon: 'pi pi-user',
                command: () => {
                    this.GoToProfile();
                }
            },
            {
                label: 'Sign out',
                icon: 'pi pi-sign-out',
                command: () => {
                    this.logout();
                }
            },
        ]
    }
    ];


    recentlyUsedMenus: any;
    allMenus: any[];
    frequentlyUsedMenus: any;
    apps: any[];
    visibleSidebar1;

    visibleSidebar2;
    visibleSidebar3;
    fullScreenMode: boolean = false;

    Sys_Menu_ID: number = 10;
    Module: any;
    MyDataSourceArray: Array<any> = [];
    MyDataSource: any;
    displayedColumns: any[] = [];

    NoOfGroups: any;
    groupCount: any;
    appGroups: any;


    constructor(public events: Events, private router: Router, private badge: Badge,private messageService:MessageService,
        private mainpageservice: MainPageService, public popoverController: PopoverController,
        private menuservice: MenuService,
        private authentication: AuthenticationService, private _location: Location,
        public zone: NgZone
    ) {
        if (localStorage.getItem('CurrentAppName')) {
            this.SelectedAppName = localStorage.getItem('CurrentAppName');
        }
        events.subscribe('PageName', (name) => {
            // user and time are the same arguments passed in `events.publish(user, time)`
            console.log('name', name);
            this.name = name;
            this.userName = localStorage.getItem('username');
            this.companyname = localStorage.getItem('Profile_Type');

        });
        events.subscribe('Notification', () => {
            this.mainpageservice.GetBadgeValue(this.UserName).subscribe(response => { this.badgeValue = response });
        });
        events.subscribe('PopUp', () => {
            this.messageService.add({ severity: 'success', summary: 'Notification Alert', detail: 'New Notification', life: 5000, sticky: true });
        })
        events.subscribe('RunTask',()=>{
            this.messageService.add({severity:'info', summary:'Run Task', detail:'Run Task has been started. Please wait for task completion'});
        })

        events.subscribe('Apps', (apps) => {
            this.apps = apps;
            let appNames = [];
            this.appGroups = [];
            const groupCount = apps.reduce((prev, current) => (prev.GroupByIndex > current.GroupByIndex) ? prev : current).GroupByIndex;
            if (groupCount > 1) {
                for (var i = 0; i < groupCount; i++) {
                    let appsGroupWise = apps.filter(app => app.GroupByIndex == (i + 1));
                    this.appGroups[i] = appsGroupWise;
                }
            }
            else {
                appNames = apps.sort(function (a, b) {
                    return a.OrderKey < b.OrderKey ? -1 : (a.OrderKey > b.OrderKey) ? 1 : 0;
                });
                this.appGroups[0] = appNames;

            }
            //console.log(this.appGroups,'appGroups');

            // let appNames=[]; 
            // appNames=apps.sort(function(a,b){
            //     return a.OrderKey < b.OrderKey?-1:(a.OrderKey>b.OrderKey)?1:0;
            // });
            // this.apps=appNames;
        });
        events.subscribe('navigationExtras', (name) => {
            // alert('change'+name);
            let data: any;
            data = JSON.stringify(name);
            data = '{Filter:' + data + '}';
            // alert(data);
            this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, data).subscribe(data => {
                console.log("Moduleapi", data);
                this.Module = data;
                console.log(data);
            });
        });
        events.subscribe('FrequentMenus', (menus) => {
            let allMenus = menus.filter(menu => menu.Frequency > 0);
            this.allMenus = [...allMenus];
            this.frequentlyUsedMenus = allMenus.slice(0, 5);
            let recentlyUsedMenus = allMenus.sort(function (a, b) {
                let date1 = new Date(a.LastVisitedOn);
                let date2 = new Date(b.LastVisitedOn);
                return date1 > date2 ? -1 : (date1 < date2) ? 1 : 0;
            });
            this.recentlyUsedMenus = recentlyUsedMenus.slice(0, 5);
            console.log(this.frequentlyUsedMenus, this.recentlyUsedMenus);
        })
    }
    sidemenubarclick() {
        let sidebar = document.querySelector(".sidemenubar");
        if (sidebar) sidebar.classList.toggle("close");
    }
    redirect(event: any) {

        this.router.navigate(['/dashboard/subscription']);
        // console.log('The buttob is pressed')
        if (!event) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();

    }

    
    appclicked(app) {
        debugger;
        localStorage.setItem("CurrentApp", JSON.stringify(app));
        this.SelectedAppName = app.label;
        this.events.publish('PageName', '');
        var CacheSubMenu = localStorage.getItem('SubMenu');
        if (CacheSubMenu != null) {
            CacheSubMenu = null;
            localStorage.removeItem('SubMenu');
        }
        localStorage.removeItem('CurrentActiveMenuParentIndex');
        this.events.publish('CurrentActiveMenuParentIndex', null);//subscribed in MainPage
        localStorage.removeItem('SubMenu');
        this.events.publish("showTopMenu", false);//subscribed in mainpage
        this.name = "";
        localStorage.setItem("CurrentAppName", app.label);
        localStorage.setItem('CurrentActiveMenuIndex', '/menu/first/tabs/' + app.DashboardLink);
        localStorage.setItem("CurrentAppName", app.label);
        this.events.publish("CurrentAppID", app.ID);
        this.events.publish("AppChanged", app);
        if (app.DashboardLink != 0) {

            localStorage.setItem('CurrentActiveMenuIndex', '/menu/first/tabs/' + app.DashboardLink);

            this.events.publish("CurrentAppMenus",app.ID);//subscribed in Mainpage/Menu Component

            this.zone.run(() => {

                this.router.navigate(['menu', 'first', 'tabs', app.DashboardLink], { queryParams: { id: app.ID } });

            })

        }

        else {

            this.router.navigate(['menu', 'first', 'tabs']).then(() => {

                this.events.publish("CurrentAppMenus",app.ID);//subscribed in Mainpage/Menu Component

            });
        }
        this.visibleSidebar1 = false;

    }
    // appclicked(app) {
    //     debugger;
    //     localStorage.setItem("CurrentApp", JSON.stringify(app));
    //     this.SelectedAppName = app.label;
    //     var CacheSubMenu = localStorage.getItem('SubMenu');
    //     if (CacheSubMenu != "null") {
    //         CacheSubMenu = null;
    //         localStorage.removeItem('SubMenu');
    //     }
    //     localStorage.removeItem('CurrentActiveMenuParentIndex');
    //     this.events.publish('CurrentActiveMenuParentIndex', null);
    //     localStorage.removeItem('SubMenu');
    //     this.name = "";
    //     localStorage.setItem("CurrentAppName", app.label);
        
    //     this.events.publish("CurrentAppID", app.ID);
    //     if (app.DashboardLink != 0 && app.ID==1) {
    //         localStorage.setItem('CurrentActiveMenuIndex', '/menu/first/tabs/' + app.DashboardLink);
    //         this.zone.run(() => {
    //             this.router.navigate(['menu', 'first', 'tabs', app.DashboardLink], { queryParams: { id: app.ID } });
    //         })
    //     }
    //     else {
    //         if(app.ID==1)
    //         {
    //             this.router.navigate(['menu', 'first', 'tabs']).then(() => {
    //                 this.events.publish("CurrentAppMenus");//subscribed in Mainpage/Menu Component
    //             });
    //         }
            

    //     }
    //     this.visibleSidebar1 = false;
    // }

    toggleFullScreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
            this.fullScreenMode = true;
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                this.fullScreenMode = false;
            }
        }
    }
    zoomIn() {
        var Page = document.getElementById('Body');
        var zoomI = parseInt(Page.style.zoom) + 10 + '%'
        Page.style.zoom = zoomI;
        return false;
    }
    zoomOut() {
        var Page = document.getElementById('Body');
        var zoomO = parseInt(Page.style.zoom) - 10 + '%'
        Page.style.zoom = zoomO;
        return false;
    }
    home() {
        this.zone.run(() => {
            this.router.navigate(['menu/first/tabs']);
        })
    }
    sidebarappclick() {
        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.toggle("open");
    }


    token: any;
    TicketAppLink() {
        let userID = localStorage.getItem("UserId");
        let userName = localStorage.getItem("username");
        let profileType = localStorage.getItem("Profile_Type");

        this.mainpageservice.GetUserToken(userID, userName).subscribe(resp => {
            this.token = resp["Token"];
        });

        if(this.token != undefined && this.token != null && this.token != "")
        {
            //Here in Query string, the parameters are UserID, UserName and Token respectively.
           // window.open('http://ticketingapp.datasavi.in/#/login?dIresU='+ btoa(userID) + "&emaNresU=" +  btoa(userName) + "&nekoT=" +  btoa(this.token) + "&epyTeliforP=" +  btoa(profileType), '_blank');
           window.open('http://ticketingapp.datasavi.in/#/login?dIresU='+ btoa(userID) + "&emaNresU=" +  btoa(userName) + "&nekoT=" +  btoa(this.token) + "&epyTeliforP=" +  btoa(profileType), '_blank');
        }    
    }

    ngOnInit() {
        this.UserName = localStorage.getItem('username');
         this.mainpageservice.GetBadgeValue(this.UserName).subscribe(response => { this.badgeValue = response });

    }
    markAsRead(i, LinkedMenu, Notifications, InAppID) {
        debugger;

        this.visibleSidebar2 = false;
        this.mainpageservice.AddReadInAppNotifications(this.UserName, InAppID).subscribe(resp => {

            this.events.publish('Notification');
        });

        if (LinkedMenu != 0)
            this.navigate(i, LinkedMenu)
    }
    navigate(i, LinkedMenu) {
        let qm = { "rowval": i };

        let navigationExtras: NavigationExtras = {
            queryParams: {
                "rowval": i
            }

        };
        this.events.publish('navigationExtras', qm);
        this.router.navigate(["/menu/first/tabs/" + LinkedMenu], navigationExtras);
    }
    shownotifications() {
        debugger;
        this.UserName = localStorage.getItem('username');
       
        this.mainpageservice.getNotifications(this.UserName).subscribe(filter => {

            this.MyDataSourceArray[0] = filter;
            console.log('filter', this.MyDataSourceArray[0]);
            // this.MyDataSource = [];
            // this.Module = filter;
            // this.displayedColumns = ['Notifications'];
            // this.Module.forEach((val) => {
            //         this.MyDataSource = val;
            //         this.MyDataSourceArray.push(this.MyDataSource);

            // });
        });
        this.visibleSidebar2 = true;
        // const popover = await this.popoverController.create({ component: NotificationsComponent, event });
        // return await popover.present();
    }
    GoToProfile() {
        //alert('profile');
        this.router.navigate(['profile']);
    }

    logout() {
        this.authentication.logout();
    }

    back() {
        this.zone.run(() => {
            this._location.back();
        })
    }

    route(menu) {
        debugger;
        let menuClicked = this.allMenus.find(m => m.ID == menu.ID);
        if (menuClicked != undefined) {
            menuClicked.Frequency = menuClicked.Frequency + 1;
            menuClicked.LastVisitedOn = this.GetDateTime(new Date());
            let frequentMenus = this.allMenus.sort(function (a, b) { return a.Frequency > b.Frequency ? -1 : (a.Frequency < b.Frequency) ? 1 : 0 })
            this.events.publish("FrequentMenus", frequentMenus);
            this.events.publish("PageName", menu.MenuName);
            this.events.publish('submenus', menu);
            //console.log(this.frequentlyUsedMenus);
        }
        this.menuservice.UpdateFrequentAndRecentMenus(menu.ID, this.UserName).subscribe(resp => {
            console.log(resp);
        });
        this.router.navigateByUrl(menu.routerLink);
    }

    GetDateTime(date) {
        let adate = date;
        var ayear = adate.getFullYear();
        var amonth: any = adate.getMonth() + 1;
        var ahour: any = adate.getHours();
        var aminute: any = adate.getMinutes();
        var adt: any = adate.getDate();
        if (adt < 10) { adt = '0' + adt; }
        if (amonth < 10) { amonth = '0' + amonth; }
        return ayear + '-' + amonth + '-' + adt + " " + ahour + ":" + aminute + ":00";
    }
    TimeStamp(value) {
       
        let todaysDate = new Date()
        let NotifDate = new Date(value)
        let timeStamp = todaysDate.getTime() - NotifDate.getTime()
        let todaysMonth = todaysDate.getMonth()
        let NotifMonth = NotifDate.getMonth()
        let monthDiff = Math.abs(NotifMonth - todaysMonth)
        let cd = 24 * 60 * 60 * 1000;
        let ch = 60 * 60 * 1000;
        let cm = 60 * 1000
        let d = Math.floor(timeStamp / cd);
        let h = Math.floor((timeStamp - d * cd) / ch);
        let m = Math.floor((timeStamp - d * cd - h * ch) / 60000);

        // let pad = function(n){ return n < 10 ? '0' + n : n; };

        if (m === 60) {
            h++;
            m = 0;
        }

        if (h === 24) {
            d++;
            h = 0;
        }
        return ((m == 0) ? 'few sec ago'

            : (d == 0 && h == 0) ? m + ' min ago'

                : (d == 0 && h != 0) ? h + ' hours ago'

                    : (monthDiff == 0 && todaysDate.getFullYear() == NotifDate.getFullYear()) ? d + ' days ago'

                        : (d > 1 && todaysDate.getFullYear() == NotifDate.getFullYear()) ? monthDiff + ' months ago'

                            : value.slice(0, 10));

    }
}
