import {Menu5Page} from './menu5/menu5.component';
import {Menu8Page} from './menu8/menu8.component';
import {Menu9Page} from './menu9/menu9.component';
//import {Menu35Page} from './menu35/menu35.component';
import {Menu36Page} from './menu36/menu36.component';
import {Menu45Page} from './menu45/menu45.component';
import {Menu46Page} from './menu46/menu46.component';
import {Menu47Page} from './menu47/menu47.component';
import {Menu51Page} from './menu51/menu51.component';
import {Menu53Page} from './menu53/menu53.component';
//import {Menu56Page} from './menu56/menu56.component';
import {Menu57Page} from './menu57/menu57.component';
import {Menu58Page} from './menu58/menu58.component';
import {Menu59Page} from './menu59/menu59.component';
import {Menu60Page} from './menu60/menu60.component';
import {Menu61Page} from './menu61/menu61.component';
import {Menu62Page} from './menu62/menu62.component';
import {Menu63Page} from './menu63/menu63.component';
//import {Menu79Page} from './menu79/menu79.component';
import {Menu80Page} from './menu80/menu80.component';
import {Menu81Page} from './menu81/menu81.component';
// import {Menu82Page} from './menu82/menu82.component';
// import {Menu91Page} from './menu91/menu91.component';
import {Menu1130Page} from './menu1130/menu1130.component';
import {Menu1131Page} from './menu1131/menu1131.component';
import {Menu1132Page} from './menu1132/menu1132.component';
import {Menu1153Page} from './menu1153/menu1153.component';
import {Menu1161Page} from './menu1161/menu1161.component';
import {Menu1162Page} from './menu1162/menu1162.component';
import {Menu1163Page} from './menu1163/menu1163.component';
import {Menu1164Page} from './menu1164/menu1164.component';
import {Menu1165Page} from './menu1165/menu1165.component';
import {Menu1166Page} from './menu1166/menu1166.component';
import {Menu1168Page} from './menu1168/menu1168.component';
import {Menu1169Page} from './menu1169/menu1169.component';
//import {Menu1171Page} from './menu1171/menu1171.component';
import {Menu1172Page} from './menu1172/menu1172.component';
//import {Menu1173Page} from './menu1173/menu1173.component';
import {Menu1174Page} from './menu1174/menu1174.component';
//import {Menu1176Page} from './menu1176/menu1176.component';
import {Menu1177Page} from './menu1177/menu1177.component';
import {Menu1178Page} from './menu1178/menu1178.component';
import {Menu1179Page} from './menu1179/menu1179.component';
import {Menu1180Page} from './menu1180/menu1180.component';
import {Menu1181Page} from './menu1181/menu1181.component';
import {Menu1182Page} from './menu1182/menu1182.component';
import {Menu1183Page} from './menu1183/menu1183.component';
import {Menu1184Page} from './menu1184/menu1184.component';
import {Menu1186Page} from './menu1186/menu1186.component';
import {Menu1187Page} from './menu1187/menu1187.component';
import {Menu1188Page} from './menu1188/menu1188.component';
import {Menu1189Page} from './menu1189/menu1189.component';
import {Menu1190Page} from './menu1190/menu1190.component';
import {Menu1191Page} from './menu1191/menu1191.component';
import {Menu1192Page} from './menu1192/menu1192.component';
import {Menu1193Page} from './menu1193/menu1193.component';
import {Menu1194Page} from './menu1194/menu1194.component';
import {Menu1195Page} from './menu1195/menu1195.component';
import {Menu1197Page} from './menu1197/menu1197.component';
//import {Menu1199Page} from './menu1199/menu1199.component';

import { MenuComponent } from './menu/menu.component';
import {TreeModule} from 'primeng/tree';
import { NgModule } from '@angular/core';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { routes } from './mainpage.routes';
import { MainpagePage } from './mainpage.page';
import {CustomService} from '../Services/custom-service.service'
import { Data } from '../Services/data.service';
// import { MatPaginatorModule } from '@angular/material';
import { IonicStorageModule } from '@ionic/storage';
import { SharedModule } from '../shared/shared.module';
//import { AgmCoreModule } from '@agm/core';
import {TableModule} from 'primeng/table'; 
import {PaginatorModule} from 'primeng/paginator';  //used no where til 15-4-2022
import {DropdownModule} from 'primeng/dropdown';        //used for all the dropdowns in datasavi
import {MultiSelectModule} from 'primeng/multiselect';  //used in Forms module and crudtable not used in pivot and unfrozen
import {AccordionModule} from 'primeng/accordion';      //used for accordion displayType in code gen //for ex. used in terrago project
import {TreeTableModule} from 'primeng/treetable';  //A displayType. For ex.-- used in snop and hafele
import {CalendarModule} from 'primeng/calendar';    // An Input control // used for date, datetime, month, and time.
import {InputTextModule} from 'primeng/inputtext';  // An Input control used with datatype string and for primeng input UI.
import {CheckboxModule} from 'primeng/checkbox';    //A type of input control// Used in both form and tables
import {RadioButtonModule} from 'primeng/radiobutton';  // Input control // Used in forms
import {FileUploadModule} from 'primeng/fileupload';    // Input control for file upload like excel and others.
import {ButtonModule} from 'primeng/button';    // primeng buttons in datasavi // used every where.
import {PanelModule} from 'primeng/panel';  // This is used as a container for modules. // which is collapsible, responsive and expandable
import {InputNumberModule} from 'primeng/inputnumber';  // Input control for  input which has datatype int or decimal.
import {MenubarModule} from 'primeng/menubar';  // Used in pwa mainpage as topbarMenu level
import {PanelMenuModule} from 'primeng/panelmenu';  // This was used in previous versions.
import {OverlayPanelModule} from 'primeng/overlaypanel';    // Not used anywhere
import {BreadcrumbModule} from 'primeng/breadcrumb';    // Breadcrumb for menu // published from menu and subscribed in mainpage.html
import {CardModule} from 'primeng/card';    // Used for tiles mainly and for formwithgrouping
import {DialogModule} from 'primeng/dialog';    // Used every place where dialog popup is there. // Used in tables mostly
import {MenuModule} from 'primeng/menu';    // Used for recent and frequent menu
import {TabViewModule} from 'primeng/tabview';  // used as displayType and grouping of module.
import { GoogleMapLatLongComponent } from '../google-map-lat-long/google-map-lat-long.component';
import {DataViewModule} from 'primeng/dataview';    //Dataview module is a displayType which is used as filter in page to filter all other modules on same page. // Filter but in a new way.
import { ChipModule } from 'primeng/chip';  // Till 15-4-2022 it is used only for showing appied filter input values.
import {ContextMenuModule} from 'primeng/contextmenu';  // Used as right click options in table
import {VirtualScrollerModule} from 'primeng/virtualscroller';  //It works for scrolling in primeng components like dropdown
import {BadgeModule} from 'primeng/badge';      // badge module is used to show nubmers of count of notifications and can be used for new requirement also.
import {TooltipModule} from 'primeng/tooltip';      //Tooltip module is now being used every where in apps where a description is needed on hover. For ex. menu hover, Module icons hover, tabview header hovers.
import {TimelineModule} from 'primeng/timeline';    // Timeline module is being used for quick menus displaytypes and processflow dispalyType.
import {SidebarModule} from 'primeng/sidebar';  //Used for popups like profile panel, chat-panel, app-panel, notification-panel and others.
import {SplitButtonModule} from 'primeng/splitbutton';  // Used for split button in filter which gives option of keeping filter vertical or horizonal.
import{SplitterModule} from 'primeng/splitter';
import {NgxSummernoteModule} from 'ngx-summernote';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {ToastModule} from 'primeng/toast'
//ACS custom library and component
import { ChatComponent } from '../components/ACS/chat-component/chat-component.component';
import { Chat } from '../components/ACS/Chat';
import { TokenManager } from '../components/ACS/TokenManager';
import { CommunicationClient } from '../components/ACS/CommunicationClient';
import { UserManager } from '../components/ACS/UserManager';
import { VoiceCall } from '../components/ACS/VoiceCall';
import { AzureCommunicationDAL } from '../components/ACS/AzureCommunicationDAL';
import { CallCard } from '../components/ACS/CallCard';
import { VideoCall } from '../components/ACS/VideoCall';

import { InlineCalendarComponent } from '../components/inline-calendar/inline-calendar.component';
import { DateTimeCalendarComponent } from '../components/datetime-calendar/datetime-calendar.component';
import { DateCalendarComponent } from '../components/date-calendar/date-calendar.component';

import { MenuflowComponent } from '../components/menuflow/menuflow.component';

// By Sudarshan, Date -> 15-Jun-2023
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TieredMenuModule } from 'primeng/tieredmenu';
//Sudarshan - Divider
import {DividerModule} from 'primeng/divider';
import { SelectButtonModule } from 'primeng/selectbutton';
import {InputTextareaModule} from 'primeng/inputtextarea';

@NgModule({
 imports: [
    CommonModule,
    FormsModule,
      IonicModule,
      RouterModule.forChild(routes),
      SharedModule,
      ReactiveFormsModule,
       MultiSelectModule,
      AccordionModule,
      DropdownModule,CardModule,
      PanelMenuModule,OverlayPanelModule,
      MenubarModule,BreadcrumbModule,MenuModule,TabViewModule,TooltipModule,SplitButtonModule,
      TableModule,PaginatorModule,TreeTableModule,InputNumberModule,PanelModule,ButtonModule,FileUploadModule,
     RadioButtonModule,CheckboxModule,InputTextModule,CalendarModule,DialogModule,DataViewModule,TreeModule,
     TimelineModule,SidebarModule,SplitterModule,
    NgxSummernoteModule,
    ToastModule,
     BadgeModule,ChipModule,ContextMenuModule,VirtualScrollerModule,

// By Sudarshan, Date -> 15-Jun-2023
     ConfirmDialogModule,ConfirmPopupModule,InputSwitchModule,TieredMenuModule,
//Sudarshan - Divider
     DividerModule,SelectButtonModule,InputTextareaModule,

      IonicStorageModule.forRoot()
      /* AgmCoreModule.forRoot({
        apiKey: 'AIzaSyBrfK8btHhw4Xfc9EjNJjwYilg0t0s2DcI',//api key
        libraries: ['places']
       }) */

    ],
    providers: [Data,CustomService,ChatComponent,InlineCalendarComponent,MessageService,ConfirmationService,
      Chat,TokenManager,CommunicationClient,UserManager,VoiceCall,VideoCall,CallCard
//,
      //{ provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } } 
],
declarations: [MainpagePage,GoogleMapLatLongComponent,ChatComponent,InlineCalendarComponent,DateTimeCalendarComponent,
  DateCalendarComponent,MenuflowComponent,MenuComponent,
                    
Menu5Page,
Menu8Page,
Menu9Page,
//Menu35Page,
Menu36Page,
Menu45Page,
Menu46Page,
Menu47Page,
Menu51Page,
Menu53Page,
//Menu56Page,
Menu57Page,
Menu58Page,
Menu59Page,
Menu60Page,
Menu61Page,
Menu62Page,
Menu63Page,
//Menu79Page,
Menu80Page,
Menu81Page,
//Menu82Page,
//Menu91Page,
Menu1130Page,
Menu1131Page,
Menu1132Page,
Menu1153Page,
Menu1161Page,
Menu1162Page,
Menu1163Page,
Menu1164Page,
Menu1165Page,
Menu1166Page,
Menu1168Page,
Menu1169Page,
//Menu1171Page,
Menu1172Page,
//Menu1173Page,
Menu1174Page,
//Menu1176Page,
Menu1177Page,
Menu1178Page,
Menu1179Page,
Menu1180Page,
Menu1181Page,
Menu1182Page,
Menu1183Page,
Menu1184Page,
Menu1186Page,
Menu1187Page,
Menu1188Page,
Menu1189Page,
Menu1190Page,
Menu1191Page,
Menu1192Page,
Menu1193Page,
Menu1194Page,
Menu1195Page,
Menu1197Page,
//Menu1199Page,

                    ],
    entryComponents: []
})
export class MainpagePageModule {}
                    
