import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateDocumentComponent } from './create-document/create-document.component';
import { ViewDocumentComponent } from './view-document/view-document.component';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PanelModule } from 'primeng/panel';
import {TreeModule} from 'primeng/tree';
import { SplitterModule} from 'primeng/splitter';
import { NgxSummernoteModule } from 'ngx-summernote';
import { ButtonModule } from 'primeng/button';
import {SidebarModule} from 'primeng/sidebar';
import {InputTextModule} from 'primeng/inputtext';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
const routes:Routes=
[
  {
    path:'Create',
    component:CreateDocumentComponent
  },
  {
    path:'',
    component:ViewDocumentComponent
  }
]


@NgModule({
  declarations: [CreateDocumentComponent,ViewDocumentComponent],
  imports: [IonicModule, SplitterModule,ButtonModule,NgxSummernoteModule,TreeModule,PanelModule, FormsModule, ReactiveFormsModule,RouterModule.forChild(routes),
    CommonModule,SidebarModule,InputTextModule,OverlayPanelModule,TooltipModule,MultiSelectModule,
  ],
  exports: [RouterModule]
})
export class DocumentManagementModule { }
