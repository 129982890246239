import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menuflow',
  templateUrl: './menuflow.component.html',
  styleUrls: ['./menuflow.component.scss'],
})
export class MenuflowComponent implements OnInit {
  timelineData:any=[];
  constructor() {
    alert("constructor");
   }

  ngOnInit() {
    let timelineData=localStorage.getItem("MenuFlowData");
    this.timelineData=JSON.parse(timelineData);
  }

}
