import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, Route } from '@angular/router';
import { MenuService } from 'src/app/Services/menu.service';
import { Events } from '../../Services/events.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    MenuList: any = [];
    show: boolean;

    constructor(private router: Router,
        private events: Events,
        private menuservice: MenuService) {

        this.events.subscribe("CurrentAppMenus", () => { this.getMenus() });//published in dashboard and header module
    }

    ngOnInit() {

        this.getMenus();
    }

    ionViewDidEnter() {
        this.events.publish('breadcrumb', []);
        this.getMenus();
    }

    getMenus() {
       
        let appID = JSON.parse(localStorage.getItem("CurrentApp")).ID;
        let UserName = localStorage.getItem('username');
        this.show = true;
        this.menuservice.getAppMenus(UserName, appID).subscribe(data => {

            // this.runRecursive(data);
            var resultArray = Object.keys(data).map(function (personNamedIndex) {
                let person = data[personNamedIndex];
                return person;
            });
           
            localStorage.setItem('StoreddSideMenu', JSON.stringify(resultArray));
            let menus = resultArray;

            menus.map(function (menu) {
                if (menu.items || menu.TopMenus)
                    return menu;
                else {
                    let index = menus.indexOf(menu);
                    menus.splice(index, 1);
                }
            })
            this.MenuList = menus;
            this.show = false;
        });


    }

    route(routerLink: string) {
        this.router.navigateByUrl(routerLink);
    }
}
