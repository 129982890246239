import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
// import { Headers, RequestOptions, ResponseContentType } from "@angular/common/http";
import { fromEvent, merge, of, from, throwError } from 'rxjs';
import { mapTo, catchError } from "rxjs/operators";

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
//import { ServerSideColumn } from '../Models/ServersideColumn';
//import { Module } from '../Models/Module';
//import { Observable } from 'rxjs/Observable';


const apiUrl = environment.apiUrl;
@Injectable(
    {
        providedIn: 'root'
    })
export class WorkFlowService {


    constructor(private _http: HttpClient) {
    }

    validateDataset(datasets: any[], sp: string) {
        const data: FormData = new FormData();
        let UserName: string = localStorage.getItem("username");
        data.append('dataset', JSON.stringify(datasets));
        return this._http.post(`${apiUrl}/${'api/workflow/validateDataset?storedProcedure=' + sp + '&UserName=' + UserName}`, data);
    }

    RunWorkflowTask(Run_ID, ScenarioId, username, data) {
        return this._http.post(`${apiUrl}/api/workflow/RunWorkflowTask?RowValue=${Run_ID}&ScenarioId=${ScenarioId}&UserName=${username}`, data);
    }

    CreateOrUpdateDataset(tableName: string, username: string) {
        return this._http.get(`${apiUrl}/api/workflow/CreateOrUpdateDataset?tablename=${tableName}&username=${username}`);
    }
    saveAllModules(menuId, UserName, AppId, data) {
        debugger;
        return this._http.post(`${apiUrl}/${'api/workflow/SaveAllModules?Id=' + menuId + '&UserName=' + UserName + '&AppId=' + AppId}`, data)
    }
    getDatasetList(username) {
        return this._http.get(`${apiUrl}/${'api/workflow/getDatasetList?username=' + username}`)
    }
}
