import { Component, OnInit, ViewChild, ViewChildren, AfterViewChecked, QueryList, ElementRef, HostListener } from '@angular/core';
import { MainPageService } from '../../Services/MainPage.service';  
import { NavigationExtras } from '@angular/router';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import {  filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Events } from '../../Services/events.service';
import { ToastController } from '@ionic/angular';
import { saveAs } from 'file-saver';
import { NgZone } from "@angular/core"; //added
import { Storage } from '@ionic/storage';//added
import { trigger, state, transition, style, animate } from '@angular/animations';
import { CustomService } from '../../Services/custom-service.service';
import { FileSystemNode } from '../../../assets/data/file-system-node';
import { Table } from 'primeng/table';
import { NavigationStart, Event as NavigationEvent } from "@angular/router";
import { Dataview } from '../../components/dataview';
import * as $ from 'jquery';
import { XLSX$Utils } from "xlsx/types";
//import { MapsAPILoader, MouseEvent } from '@agm/core';
//import { GeocoderModule } from 'angular-geocoder';
import { environment } from '../../../environments/environment'; // added for FileDownload
import { ConfirmationService, LazyLoadEvent, TreeNode, MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../../Services/pending-changes.guard';  //added to restrict user unload the current page if page includes changes.
import { UserManager } from 'src/app/components/ACS/UserManager';
import { AzureCommunication } from 'src/app/Services/AzureCommunication.service';
import { TieredMenu } from 'primeng/tieredmenu';
import { WorkFlow } from "../../components/workflow";
import { DatabaseService } from "src/app/Services/database.service";
import { Topic } from 'src/app/Models/Topic';
import * as XLSX from 'xlsx';
import { RuleEngine } from 'src/app/components/ruleEngine';

interface FileObject {
        name: string,
    FileData: Blob
}

//This variable for set value of Environment
const apiUrl = environment.apiUrl;

@Component({
    selector: 'app-menu1193',
    templateUrl: './menu1193.component.html',
    styleUrls: ['./menu1193.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class Menu1193Page implements OnInit, ComponentCanDeactivate, AfterViewChecked {
    //Sudarshan - Cell Context Menus
    cellContextMenus: any[] = [];
    listviewdata: any;
    listviewsubmoduledata: any;
    displaySiebar: boolean = false; // display sidebr on  view deatils feature
    filterValues: any[] = []; //filter values to show as chips 
    addNew: boolean = false;
    highlightcard: number; //to highlight dataview selected record
    showFooter: boolean = true;     //to show footer in table
    displayDialog: boolean;     // display dialog box in pivotwithdialog if true
    displayDialogArray: any[] = []; // display dialog box of formtable module // used array because of formtable can have multiple submodules and can multiple dialog box
    thisrow: any = {};  // used for cloning and keeping current selected row value from formtable/crudtable/pivottablewithdialog
    selectedrow: any;   // less used current selected row of table variable 
    selectedValues: any;   // current selected row of table is stored in this variable
    newrow: boolean;    // to add a new row we check for true of this variable which becomes true when we click on add or plus button of table
    rows: any[];   
    Sys_Menu_ID: number =1193;  

    Module: any;   
    ModuleIDList: Array<any> = [];  
    ModuleDetailIDList: Array<any> = [];    
    hideColumnFilters: boolean = false; //  used to show/hide filters of table either it is global filter or columns filter
    Paginators: boolean = false;    // this variable was added to show/hide paginators in table but in new UI removed
    showfilter: boolean = false;    // used to show/hide filters using button click
    horizontalFilter: boolean = true;   // used to keep filter inputs in horizontal line
    appliedFilters: any;    // temporary var for keeping applied inputs and can be used to show in chip tags
    public searchElementRef: ElementRef;    
    //public minDate: any = new Date().toISOString();           
    public minDate: any = "";   
    public maxDate: any = "2299-12-31";
    FileName: any;//Added   
    UserName: string;   
    datatableparam: any = {};
    DropDown: any = {};
    PageMenuDetails: any;
    id: any;//Added
    WizardFormValue: any = {};
    isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
    expandedElementArray: Array<any> = [];  
    expandedElement: any;
    isLoadingResults = true;
    displayedColumnArray: Array<any> = [];
    displayedColumns: any[] = [];
    Feedback: any;
    childColumnArray: Array<any> = [];
    childColumns: any[] = [];
    FileList: Array<File> = [];
    public show: boolean = false;
    public loading: boolean;
    // added for File Download : Sonal
    api_url: string = apiUrl;

    //-accordions-----
    step = 0;   
    showContainerEmail: boolean = false;    //for email with otp container
    //--
    //EmailwithOTP
    ValidatedEmail: boolean = false;    
    VerifiedEmailId: any;

    //TreeTable
    files: FileSystemNode[] = [];
    cols: any[];
    colsdisplay: any[];
    editableCols: any[];
    totalRecords: any[];

    primengTableData: any[];    //to store table data with index used in primengtablearray[] with dt_count
    scrollableCols: any;    //to store list of column or scrollable column name in scrollableColsArray[] which is to be shown in table with dt_count
    frozenCols: any[];          //to store list of frozen columns name in frozenColsArray[] which is to be shown in table with dt_count
    scrollableColsArray: any[]; // to store scrollabeCols with dt_index
    frozenColsArray: any[]; //to store  frozenCols with dt_index
    primeNgTableArray: any[];   //to store primengTableData with dt index;
    frozenWidthArray: any[];    // to store frozen width property value with dt_index(how much width to provide for all frozen area of table)
    selectedColumnsArray: any[];    // this variable is used for toggle features in pivottable(the list which it contains will be shown only)
    treetabledataArray: any;    // used to store tree table data with dt index
    filterCols: any[];  // to be passed in global filter(it shows global filter as which columns are to be used when someone serches in global filter)
    filterColsArray: any[]; // to store filterCols for different table with dt_index(data table index)
    headerLevelOne: any[];  // used in multiheader table for first th level
    headerLevelOneArray: any[]; // stores headerLevelOne of different table with dt_index
    reportTableArray: any[];    //created for report table display Type but not used now
    reportTableColumnsArray: any[]; //created for report table display Type but not used now

    SubmoduleDataSource: any[] = [];    //Used in formtable display type
    SubmoduleScrollableCols: any[] = [];    // used in pivottablewithdialog displayType
    SubmoduleFrozenCols: any[] = [];    // used in formtable displayType
    SubModuleFrozenWidth: any[] = [];// used in formtable displayType
    SubmoduleDataSourceArray: any[] = [];// used in pivottablewithdialog displayType
    SubmoduleScrollableColsArray: any[] = [];// not used right now
    SubmoduleFrozenColsArray: any[] = [];// used in formtable displayType
    SubModuleFrozenWidthArray: any[] = [];// not used right now but was created to be used in pivottablewithdialog displayType
    displayDialogModule: any[] = [];// used in pivottablewithdialog displayType
    displayDialogSubmodule: any[] = [];// used in pivottablewithdialog displayType
    frozenColsSubmoduleArray: any[] = [];// used in pivottablewithdialog displayType
    frozenWidthSubModuleArray: any = [];// used in pivottablewithdialog displayType 
    displayedSubmoduleColumnArray: any[] = [];// not used right now but was created to be used in pivottablewithdialog displayType and to be used in pivottablewithdialog displayType to pass column list of different table with dt_index in global filter
    displayedSubmoduleColumns: any[] = [];// used in pivottablewithdialog displayType to store column list of single table

    visitedPagesData: any[];

    treeModuleData: TreeNode[];

    timelineData: any;
    timelineTableArray: any;
    timelineTableColumnsArray: any;

    // commented by Karan on 18-05-21 @ViewChild('slides') slides: IonSlides; // added for wizard slides
    @ViewChildren('LatLong') latlong: QueryList<ElementRef>;
    @ViewChild('cellContextMenu', { static: false }) cellContextMenu: TieredMenu;

    lat: number;
    long: number;
    lat_long: string;

    @ViewChildren('dt') tables: QueryList<Table>;

    @ViewChild('dt', { static: false }) primengTable: Table;
    ModuleDataWithRule: any;
    routerEventSubscription: any;
    rowval: string;

    Locale: string;
    Currency: string;
    DateFormat: string;
    TimeZone: string;
    hourFormat: number;
    dateTimeFormat: string;

    appid: string;
    CurrentApp: any;

    disable: boolean = false;
    ModuleDataWithRuleArray: any[];
    filterSplitButtonOptions = [    //filter options to keep it horizontal or vertical/ open or hidden
        {
            label: 'Horizontal',
            //icon: 'pi pi-caret-left', 
            command: () => {
                this.horizontalFilter = true; this.showfilter = !this.showfilter;
            }
        },
        {
            label: 'Vertical',
            //icon: 'pi pi-caret-down', 
            command: () => {
                this.horizontalFilter = false; this.showfilter = !this.showfilter;
            }
        }
    ];
    tableSecondaryOptions = [   // was trying to show table primary option icons in three dot icon for mobile but applied
        {
            label: 'Pagination',
            icon: 'pi pi-align-justify',
            command: () => {
                this.Paginators = !this.Paginators
            }
        },
    ];

    haveChangesOnPage: any;
    temptableArray: any;
    rowGroupMetadata: {};
    applyCSSRuleToRow: boolean;

    RowRightClickOptions: any[] = [];
    RowRightClickOption: any[] = [];
    selectedProduct: any;
    contextMenu_tableIndex: number=0;
    multichartdata: any[];
    CardViewModuleDetails: any;
    selectedCellValue: any;
    html: string = "<h1><span style=\"font-family: &quot;Segoe UI&quot;;\">Welcome to SummerNote</span></h1>";
    newHtml: string;
    config: any = {
        airMode: false,
        tabDisable: false,
        popover: {
            table: [
                ["add", ["addRowDown", "addRowUp", "addColLeft", "addColRight"]],
                ["delete", ["deleteRow", "deleteCol", "deleteTable"]],
            ],
            image: [
                ["image", ["resizeFull", "resizeHalf", "resizeQuarter", "resizeNone"]],
                ["float", ["floatLeft", "floatRight", "floatNone"]],
                ["remove", ["removeMedia"]],
            ],
            link: [["link", ["linkDialogShow", "unlink"]]],
            air: [
                [
                    "font",
                    [
                        "bold",
                        "italic",
                        "underline",
                        "clear",
                    ],
                ],
            ]
        },
        height: "500px",
        width: "800px",
        uploadImagePath: "/api/upload",
        toolbar: [
            ["misc", ["codeview", "undo", "redo", "codeBlock"]],
            [
                "font",
                [
                    "bold",
                    "italic",
                    "underline",
                    "clear",
                ],
            ],
            ["fontsize", ["fontname", "fontsize", "color"]],
            ["para", ["style0", "ul", "ol", "paragraph", "height"]],
            ["insert", ["table", "picture", "link", "video", "hr"]],
            ['view', ['fullscreen', 'help']]
        ],
        fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times', 'Segoe UI'],
        lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0', '5.0', '10.0', '12.0', '14.0', '16.0', '18.0', '20.0'],
        codeviewFilter: true,
        codeviewFilterRegex:
            /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
        codeviewIframeFilter: true,
    };

    editorDisabled = true;
    topicId: number;
    isEditButtonClicked: boolean = true;
    selectedCellHeader: string;

    constructor(private mainpageservice: MainPageService,
        private customService: CustomService,
        private router: Router,
        private toastController: ToastController,//Added
        private activateRouter: ActivatedRoute,
        private zone: NgZone,//Added
        private events: Events,
        private storage: Storage,//Added
        private Dataview: Dataview,
        private ACSUser: UserManager,
        private ACS: AzureCommunication,
        // private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone,
        private workflow: WorkFlow,
        private confirmationService: ConfirmationService,
        private database: DatabaseService,
        private messageService: MessageService,
        public ruleEngine: RuleEngine
    ) {
        events.subscribe('navigationExtras', (name) => {
            this.show = true;

            // alert('change'+name);
            let data: any;
            data = JSON.stringify(name);
            data = '{Filter:' + data + '}';
            // alert(data);
            this.GetModulewithFilter(name);
        });

        events.subscribe('AppChanged', (app: any) => {
            let appid = localStorage.getItem("CurrentApp");
            this.CurrentApp = JSON.parse(appid);

            this.getPageMenuDetails().then(
                () => this.getModuleDetailIdToHide(),
                () => this.getModulesToShow(),
            )
        });


    }

    exportExcel(data) {     // not in use //initially added to download primeng table in excel format
        var xlsx = require('xlsx');
        const worksheet = xlsx.utils.json_to_sheet(data);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "export");
    }

    saveAsExcelFile(buffer: any, fileName: string): void {  // not in use //initially added to download primeng table in excel format
        var FileSaver = require('file-saver');
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    }
    //added Sonal - Menu Display Type : Wizard 
    Next(ID: any, wizardFormdata: any) {        //next button click of ionic wizard display // very less used
        debugger;
        this.WizardFormValue = Object.assign(this.WizardFormValue, wizardFormdata);
        // commented by Karan on 18-05-21 this.slides.lockSwipes(false);
        // commented by Karan on 18-05-21 this.slides.slideNext();
        // commented by Karan on 18-05-21 this.slides.lockSwipes(true);
    }

    Prev() {    // click function of ionic wizard display   // very less used
        // commented by Karan on 18-05-21 this.slides.lockSwipes(false);
        // commented by Karan on 18-05-21 this.slides.slidePrev();
        // commented by Karan on 18-05-21 this.slides.lockSwipes(true);
    }
    ionViewDidEnter() {


        this.getPageMenuDetails().then(
            () => this.getModuleDetailIdToHide(),
            () => this.getModulesToShow(),
        )

        //this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        //).subscribe(() => {//refresh module on reload/navigation like back or submenu click
        //    this.getPageMenuDetails().then(
        //        () => this.getModuleDetailIdToHide(),
        //        () => this.getModulesToShow(),
        //    )
        //});//uncomment this if component should reload on every routing


        let menus = JSON.parse(localStorage.getItem("menus"));
        let current_menu = menus.find(m => m.ID == this.Sys_Menu_ID);
        this.events.publish('PageName', current_menu.MenuName);
        this.events.publish('CanActivateNavigation', current_menu.routerLink);
        this.events.publish('activeTopMenu', current_menu);
    }

    public latitude(event: any, fieldName) {////////////returns location latitude 

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }
    public longitude(event: any, fieldName) {///////////////returns location longitude
        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        found.nativeElement.dispatchEvent(new Event('input'));

    }

    public latitudeLongitude(event: any, fieldName) {////////////////returns latitude and longitude

        const found = this.latlong.toArray().find(element => element.nativeElement.id == fieldName);
        found.nativeElement.value = event;
        debugger;
        found.nativeElement.dispatchEvent(new Event('input'));
    }

    public getMatDate(date) {
        var date1 = new Date(date);
        return date1;
    }
     
    resizeColumn(event, index: number) {
        let width: number;
        width = parseInt(this.frozenWidthArray[index].substring(0, this.frozenWidthArray[index].indexOf("px")));
        width = width + event.delta;
        this.frozenWidthArray[index] = width + "px";
    }

    formatDropdownValue(value: any) {   
        if (value == null || value == undefined)
            return "";
        return value.Text || value;
    }
    assignDDselectedVal(fromvalue) { //used in primeng dropdown to assign selected value for tables
        let ddValue: any;
        if (fromvalue.value == null) {
            ddValue = null; 
        }
        else {
            ddValue = fromvalue.value.Value;
        }
        return ddValue;
    }
    getDefaultValue(value1, value2) {   // to show default value in dropdown in table
        let result: any;
        if (value1 != null && value1 != -1 && value2 != null && value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1) {
                    result = element;
                }
            });
            return result;
        }
        return null;

    }
    getDefaultText(value1, value2) {    //created to get default text of selected default values in dropdown in table but not used now
        let result: any;
        if (value1 != null && value1 != -1 && value2 != null && value2 != -1) {
            value2.forEach(element => {
                if (element.Value == value1 || element.Text == value1) {
                    result = element.Text;
                }
            });
            return result;
        }
        return null;
    }

    getMultiDropdownText(selectedValues, valueList) {   

        let selected: any[] = [];
        if (selectedValues === undefined || selectedValues.trim() == "") {
            return null;
        }
        selected = selectedValues.split(",");
        var selectedText = "";
        selected.forEach(value => {
            var text = valueList.find(v => v.Value == value).Text;
            selectedText = text + ", " + selectedText;
        });
        return selectedText.substring(0, selectedText.length - 1);//returns comma separated values
    }


    isNumber(value: any) {  // check number or not for new input controls in progress bar inputcontrols
        if (value) {
            return isNaN(value);
        }
    }
    removePercentSign(value) {  // used in new input control progress bar 
        if (value) {
            return value.replace('%', '');
        }
    }
    isPositiveNumber(value: any) {  // checking positive or negative for progressbar to align left or right
        if (value) {
            value = value.replace('%', '');
            return Math.sign(value);
        }
    }

    filterTablesFromTree(node) {
        console.log(node);
        let that = this;
        let tableModules = this.Module[0].moduleList.filter(m => m.DisplayType.toLowerCase().includes("table") || m.DisplayType.toLowerCase() == "" || m.DisplayType.toLowerCase() == "dataview");
        let columns: any = [];
        this.Module[0].moduleList.forEach(module => {
            if (module.DisplayType == '' || module.DisplayType.toLowerCase() == "primeunfrozentable" || module.DisplayType.toLowerCase() == "crudtable" || module.DisplayType.toLowerCase() == 'primengpivottable') {
                let dtIndex = tableModules.indexOf(module);
                let dataToFilter = module.moduleData;
                columns = this.filterColsArray[dtIndex];
                dataToFilter = dataToFilter.filter(filterData);

                if (node.parent != undefined) {
                    dataToFilter = this.filterByParentNode(dataToFilter, columns, node.parent);
                }

                that.primeNgTableArray[dtIndex] = dataToFilter;//.slice(that.tables.first, (that.tables.first + that.tables.));
            }
        });


        function filterData(data) {//callback function
            let matched: boolean = false;//flag to check if value exist in the row
            columns.forEach(column => {
                if (typeof data[column] == 'string') {//if column datatype is string
                    if (data[column].toLowerCase() === node.label.toLowerCase()) matched = true;//sets flag to true if value matched
                }
                else {//if column datatype is not string(integers,date etc.)
                    if (data[column].toString() === node.label.toString()) matched = true;//sets flag to true if value matched
                }
            });
            return matched;
        }


        // this.tables.forEach(dt => {
        //     dt.filterGlobal(node.label, 'equals');
        //     if (node.parent != undefined) {
        //         this.filterByParentNode(dt, node.parent);
        //     }
        // });
    }

    filterByParentNode(dataToFilter, columns, parentNode)//filter by parents till root element
    {
        dataToFilter = dataToFilter.filter(filterData);

        if (parentNode.parent != undefined) {
            this.filterByParentNode(dataToFilter, columns, parentNode.parent);
        }
        return dataToFilter;

        function filterData(data) {//callback function
            let matched: boolean = false;//flag to check if value exist in the row
            columns.forEach(column => {
                if (typeof data[column] == 'string') {//if column datatype is string
                    if (data[column].toLowerCase() === parentNode.label.toLowerCase()) matched = true;//sets flag to true if value matched
                }
                else {//if column datatype is not string(integers,date etc.)
                    if (data[column].toString() === parentNode.label.toString()) matched = true;//sets flag to true if value matched
                }
            });
            return matched;
        }


        // dt.filterGlobal(parentNode.label, 'equals')
        // if (parentNode.parent != undefined) {
        //     this.filterByParentNode(dt, parentNode.parent);
        // }
    }

    calculateTotalInRowGroup(data, groupByColumnName, groupByColumnValue, totalOf) {
        debugger;
        let groupByRows = data.filter(d => d[groupByColumnName] == groupByColumnValue);
        var resultArray = Object.keys(groupByRows).map(function (index) {
            let d = groupByRows[index];
            return d;
        });
        let rows = resultArray;

        let total = rows.map(r => r[totalOf]).reduce((acc, value) => acc + (value / 1), 0);
        return total;
    }

    public calculatePrimengTotal(col: string, primengdata) {
        let columnName = col;
        let footerTotal: Array<any> = [];

        let data = [];
        let mymodule;
        if (primengdata.hasFilter()) {
            if (primengdata.filteredValue)
                mymodule = primengdata.filteredValue;
            else
                mymodule = primengdata.value;
        }
        else {
            mymodule = primengdata.value;
        }

        var resultArray = Object.keys(mymodule).map(function (index) {
            let data = mymodule[index];
            return data;
        });
        data = resultArray;
        footerTotal[columnName] = data.map(t => t[columnName]).reduce((acc, value) => acc + (value / 1), 0)
        if (isNaN(footerTotal[columnName]) || footerTotal[columnName] == 0) {
            footerTotal[columnName] = "";
        }

        return footerTotal[columnName];
    }

    public onSubmoduleSubmit(form: any, dt, ID: any, LinkedMenu: any, moduleIndex: number): void {
        let formObject: any = form;
        let appId: any = null;
        if (this.CurrentApp != null || this.CurrentApp != undefined) appId = this.CurrentApp.ID;
        form = form.value;
        var submodules = this.Module[0].moduleList[moduleIndex].submodule;
        submodules.forEach(submodule => {
            var dateColumns = [];
            var datetimeColumns = [];
            var checkboxColumns = [];
            var switchColumns = [];
            dateColumns = submodule.subModuleDetails.filter(md => md.DataType == "date");
            datetimeColumns = submodule.subModuleDetails.filter(md => md.DataType == "datetime");
            checkboxColumns = submodule.subModuleDetails.filter(md => md.InputControls == "Checkbox");
            switchColumns = submodule.subModuleDetails.filter(md => md.InputControls == "Switch");

            if (submodule.DisplayType == "Form") {
                dateColumns.forEach(column => {
                    if (form[column.ColumnName] != null) {
                        let adate = new Date(form[column.ColumnName]);
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        form[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                    }
                })
                datetimeColumns.forEach(column => {

                    if (form[column.ColumnName] != null) {

                        let adate = new Date(form[column.ColumnName]);

                        var ayear = adate.getFullYear();

                        var amonth: any = adate.getMonth() + 1;

                        var adt: any = adate.getDate();

                        var aHour: any = adate.getHours();

                        var aMin: any = adate.getMinutes();

                        if (adt < 10) { adt = '0' + adt; }

                        if (amonth < 10) { amonth = '0' + amonth; }

                        form[column.ColumnName] = ayear + '-' + amonth + '-' + adt + ' ' + aHour + ':' + aMin;

                    }

                });

                checkboxColumns.forEach(column => {
                    if (form[column.ColumnName] == null || form[column.ColumnName] == "") {
                        form[column.ColumnName] = false;
                    }
                });

                switchColumns.forEach(column => {
                    if (form[column.ColumnName] == null || form[column.ColumnName] == "") {
                        form[column.ColumnName] = false;
                    }
                });

            }
            else if (submodule.DisplayType == "" || submodule.DisplayType == "PrimeUnfrozenTable") {
                dt.forEach(table => {

                table.map(d => {
                    dateColumns.forEach(column => {
                        if (d[column.ColumnName] != null) {
                            let adate = new Date(d[column.ColumnName]);
                            var ayear = adate.getFullYear();
                            var amonth: any = adate.getMonth() + 1;
                            var adt: any = adate.getDate();
                            if (adt < 10) { adt = '0' + adt; }
                            if (amonth < 10) { amonth = '0' + amonth; }
                            d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                        }
                    })
                    checkboxColumns.forEach(column => {
                        if (d[column.ColumnName] == null || d[column.ColumnName] == "") {
                            d[column.ColumnName] == false;
                        }
                    })
                    switchColumns.forEach(column => {
                        if (d[column.ColumnName] == null || d[column.ColumnName] == "") {
                            d[column.ColumnName] == false;
                        }
                    })
                    datetimeColumns.forEach(column => {

                        if (d[column.ColumnName] != null) {

                            let adate = new Date(d[column.ColumnName]);

                            var ayear = adate.getFullYear();

                            var amonth: any = adate.getMonth() + 1;

                            var adt: any = adate.getDate();

                            var aHour: any = adate.getHours();

                            var aMin: any = adate.getMinutes();

                            if (adt < 10) { adt = '0' + adt; }

                            if (amonth < 10) { amonth = '0' + amonth; }

                            if (aHour < 10) { aHour = '0' + aHour; }

                            if (aMin < 10) { aMin = '0' + aMin; }

                            d[column.ColumnName] = ayear + '-' + amonth + '-' + adt + ' ' + aHour + ':' + aMin;

                        }

                    });
                });
                })

            }
        });


        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });

        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.SaveSubmodules(ID, form, dt, this.UserName, appId).subscribe(resp => {
            console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            if (resp["Message"] == "Record saved successfully!!") {
                this.getPageMenuDetails();
                
                if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                    this.navigateOnFormSubmit(LinkedMenu);
                }
            }

            toast.then(toast => toast.present());
            this.show = false;
            formObject.reset();
        });
    }

    loadNodes(event) {
        this.loading = true;
        this.GetPaginatedTreeTable(event);

    }

    calculateRowWiseTotalPrimeNG(rowData, dtIndex) {

        var columnsForSum = this.Module[0].moduleList[dtIndex].ColumnsForSum;
        var ShowSumIn = this.Module[0].moduleList[dtIndex].GetSumIn;
        let rowWiseTotal: number = 0;
        if (columnsForSum) {
            columnsForSum.forEach(element => {
                rowWiseTotal = rowWiseTotal + parseInt(rowData[element]);
            });
        }
        rowData[ShowSumIn] = rowWiseTotal;
    }

    calculateRowWiseTotalSubmodule(rowData, moduleIndex, subModuleIndex) {
        debugger;
        var columnsForSum = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].ColumnsForSum;
        var ShowSumIn = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].GetSumIn;
        let rowWiseTotal: number = 0;
        if (columnsForSum) {
            columnsForSum.forEach(element => {
                if (!isNaN(rowData[element]))
                    rowWiseTotal = rowWiseTotal + parseInt(rowData[element]);
            });
        }
        rowData[ShowSumIn] = rowWiseTotal;
    }

    GetRuleIcon(pkColumn, pkColumnValue, column, moduleIndex?: number) {
        let icon: string = "";
        if (moduleIndex == null) moduleIndex = 0;
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            return "";
        let dataWithRules = this.ModuleDataWithRuleArray[moduleIndex];
        if (dataWithRules != null) {
            let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
                let data = dataWithRules[index];
                return data;
            });
            debugger;

            icon = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].Icon;
        }
        return icon;
    }


    GetPropertyRule(pkColumn, pkColumnValue, column, moduleIndex?: number) {
        let propertyList: string = "";
        if (moduleIndex == null) moduleIndex = 0;
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            return "";
        let dataWithRules = this.ModuleDataWithRuleArray[moduleIndex];
        if (dataWithRules != null) {
            let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
                let data = dataWithRules[index];
                return data;
            });


            if (column != "") {
                propertyList = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].PropertyList;

                let applyRuleForRow: boolean;
                applyRuleForRow = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].ApplyForRow;

                this.applyCSSRuleToRow = applyRuleForRow;
            }
        }

        return propertyList;
    }

    GetRuleLink(pkColumn, pkColumnValue, column, dt_count) {//navigate to menu when rule is applied
        if (pkColumn == null || pkColumn == "" || pkColumn == undefined)
            this.navigate(pkColumnValue, this.Sys_Menu_ID);
        else {
            let dataWithRules = this.ModuleDataWithRuleArray[dt_count];
            let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
                let data = dataWithRules[index];
                return data;
            });

            let ruleLinkMenu: number;
            ruleLinkMenu = dataArray.find(d => d[pkColumn].Value == pkColumnValue)[column].LinkMenuId;
            if (ruleLinkMenu != 0) this.navigate(pkColumnValue, ruleLinkMenu);
        }
    }

    hasRowsInTable(tables) {//Disables submit button when table is empty in FormTable Display type
        let hasRow: boolean = false;
        tables.forEach(table => {
            if (table.length > 0)
                hasRow = true;
            else
                hasRow = false;
        });
        return hasRow;
    }
    showDialogToAddInCrudTable(dt_count) {  // add new row in crudtable
        this.newrow = true;
        this.thisrow = {};
        this.disable = false;
        this.displayDialogModule[dt_count] = true;
    }
    showDialogToAdd(module_dt_count: number, submodule_dt_count: number) {  // add new row in formtable
        this.newrow = true;
        this.thisrow = {};
        this.disable = false;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
    }
    resetDialogInCrudTable(module_dt_count: number) {   //reset dialog box input fields in crudtable
        this.thisrow = {};
        this.disable = false;
        this.displayDialogModule[module_dt_count] = true;
    }
    resetDialog(module_dt_count: number, submodule_dt_count: number) {  //resetDialog in formtbale
        this.thisrow = {};
        this.disable = false;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
    }
    PivotDialogReset() {    // used in pivottablewithdialog reset
        this.thisrow = {};
        this.disable = false;
        this.displayDialog = true;
    }
    PivotDialogSave(subModuleIndex: number) {   // save record in pivottablewithdialog table dispalytype
        let thisrows = [...this.SubmoduleDataSourceArray[subModuleIndex]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Text"))
                    row[key] = row[key].Text;

                if (typeof (row[key]) == "object" && !(row[key] instanceof Date)) {
                    let multiDropdownValues: string = "";
                    row[key].forEach(element => {
                        multiDropdownValues = element.Value + "," + multiDropdownValues;
                    });
                    multiDropdownValues = multiDropdownValues.substring(0, multiDropdownValues.length - 1);
                    row[key] = multiDropdownValues;
                }
            }

        })
        this.thisrow = row;
        console.log(this.thisrow);
        if (this.newrow)
            thisrows.push(this.thisrow);
        else
            thisrows[this.SubmoduleDataSourceArray[subModuleIndex].indexOf(this.selectedValues)] = this.thisrow;

        this.SubmoduleDataSourceArray[subModuleIndex] = thisrows;
        console.log(this.SubmoduleDataSourceArray[subModuleIndex]);
        this.thisrow = null;
        this.displayDialog = false;
    }

    saveRecordInCrudTable(module_dt_count: number) {    //Temporary save of record in crudtable for browser app only// data is not added to database until submit button is clicked
        let thisrows = [...this.primeNgTableArray[module_dt_count]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Text"))
                    row[key] = row[key].Value;

                if (typeof (row[key]) == "object" && !(row[key] instanceof Date) && (Array.isArray(row[key]))) {
                    let multiDropdownValues: string = "";
                    row[key].forEach(element => {
                        multiDropdownValues = element.Text + "," + multiDropdownValues;

                    });
                    multiDropdownValues = multiDropdownValues.substring(0, multiDropdownValues.length - 1);
                    row[key] = multiDropdownValues;
                }
                // Added for FileUpload
                if (typeof (row[key]) == "object" && (Array.isArray(row[key]))) {

                    if (row[key][0] instanceof File) {
                        var fileNames = "";

                        row[key].forEach(file => {
                            fileNames = file["name"] + "," + fileNames;
                        });
                        row[key] = fileNames.substring(0, fileNames.length - 1);
                        //console.log(row[key],'rowkey'); 
                    }
                }
            }

        })
        this.thisrow = row;
        console.log(this.thisrow, 'rowdata');
        if (this.newrow) {
            thisrows.push(this.thisrow);
            //console.log(this.thisrow,'newrowdata');
        }
        else {
            thisrows[this.primeNgTableArray[module_dt_count].indexOf(this.selectedValues)] = this.thisrow;
            //console.log(this.thisrow,'editeddata');
        }

        this.primeNgTableArray[module_dt_count] = thisrows;
        console.log(this.primeNgTableArray[module_dt_count]);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count] = false;
        //console.log(this.thisrow,'newrowdata');
    }

    save(module_dt_count: number, submodule_dt_count: number) {         //save record in formtable It is not saved in database until sumbit button is clicked
        debugger;
        let thisrows = [...this.primeNgTableArray[module_dt_count][submodule_dt_count]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Value"))
                    row[key] = row[key].Value;

                if (typeof (row[key]) == "object" && !(row[key] instanceof Date) && !(Array.isArray(row[key]))) {
                    let multiDropdownValues: string = "";
                    row[key].forEach(element => {
                        multiDropdownValues = element.Value + "," + multiDropdownValues;

                    });
                    multiDropdownValues = multiDropdownValues.substring(0, multiDropdownValues.length - 1);
                    row[key] = multiDropdownValues;
                }
                // Added for FileUpload
                if (typeof (row[key]) == "object" && (Array.isArray(row[key]))) {

                    if (row[key][0] instanceof File) {
                        var fileNames = "";

                        row[key].forEach(file => {
                            fileNames = file["name"] + "," + fileNames;
                        });
                        row[key] = fileNames.substring(0, fileNames.length - 1);
                        //console.log(row[key],'rowkey'); 
                    }
                }
            }

        })
        this.thisrow = row;
        console.log(this.thisrow, 'rowdata');
        if (this.newrow) {
            thisrows.push(this.thisrow);
            //console.log(this.thisrow,'newrowdata');
        }
        else {
            thisrows[this.primeNgTableArray[module_dt_count][submodule_dt_count].indexOf(this.selectedValues)] = this.thisrow;
            //console.log(this.thisrow,'editeddata');
        }

        this.primeNgTableArray[module_dt_count][submodule_dt_count] = thisrows;
        console.log(this.primeNgTableArray[module_dt_count][submodule_dt_count]);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = false;
        //console.log(this.thisrow,'newrowdata');
    }

    // SubModuleTabel : FileUpload : Sonal
    isString(value: any) {
        if (typeof value == "string")
            return true;
        else
            return false;
    }

    //deleteRecordFromCrudTable(module_dt_count: number) {    //record will be deleted after submitting the table
    //    let index = this.primeNgTableArray[module_dt_count].indexOf(this.selectedValues);
    //    this.primeNgTableArray[module_dt_count] = this.primeNgTableArray[module_dt_count].filter((val, i) => i != index);
    //    this.thisrow = null;
    //    this.displayDialogModule[module_dt_count] = false;
    //}

    // Sudarshan's Code Starts Here

    dependentTables;
    DeleteTableName: string;
    DeleteColumnName: string;
    DeleteColumnValue: number;

    deleteRecordFromCrudTable(TableName, ColumnName, RowData) {

        this.DeleteTableName = TableName;
        this.DeleteColumnName = ColumnName;
        this.DeleteColumnValue = RowData[ColumnName];

        this.mainpageservice.GetDependetTables(TableName, ColumnName, RowData[ColumnName]).subscribe(resp => {
            this.dependentTables = resp;
            this.displayDialog = true;
        });
    }

    confirmDeleteAction() {
        this.mainpageservice.DeActivateTableData(this.DeleteTableName, this.DeleteColumnName, this.DeleteColumnValue).subscribe(resp => {

            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
            });

            this.displayDialog = false;
            toast.then(toast => toast.present());
            this.getPageMenuDetails();
            this.show = false;
        });
    }

    cancelDeleteAction() {
        // Cancel action, close the dialog
        this.displayDialog = false;
    }

    // Sudarshan's Code Ends Here //

    delete(module_dt_count: number, submodule_dt_count: number) {   //used in formtable
        let index = this.primeNgTableArray[module_dt_count][submodule_dt_count].indexOf(this.selectedValues);
        this.primeNgTableArray[module_dt_count][submodule_dt_count] = this.primeNgTableArray[module_dt_count][submodule_dt_count].filter((val, i) => i != index);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = false;
    }
    duplicateRecordInCrudTable(module_dt_count: number) {   // crudtable
        let thisrows = [...this.primeNgTableArray[module_dt_count]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Text"))
                    row[key] = row[key].Text;
            }
            if (key == "ID") {
                row[key] = null;
            }
        })
        this.thisrow = row;
        console.log(thisrows);
        if (this.newrow)
            thisrows.push(this.thisrow);
        else
            thisrows.push(this.thisrow);

        this.primeNgTableArray[module_dt_count] = thisrows;
        console.log(this.primeNgTableArray[module_dt_count]);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count] = false;
    }   
    duplicate(module_dt_count: number, submodule_dt_count: number) {    //used in formtbale
        let thisrows = [...this.primeNgTableArray[module_dt_count][submodule_dt_count]];
        var row = this.thisrow;
        Object.keys(row).map(function (key, index) {
            if (row[key] != null) {
                if (row[key].hasOwnProperty("Value"))
                    row[key] = row[key].Value;
            }
        })
        this.thisrow = row;
        console.log(thisrows);
        if (this.newrow)
            thisrows.push(this.thisrow);
        else
            thisrows.push(this.thisrow);

        this.primeNgTableArray[module_dt_count][submodule_dt_count] = thisrows;
        console.log(this.primeNgTableArray[module_dt_count][submodule_dt_count]);
        this.thisrow = null;
        this.displayDialogModule[module_dt_count][submodule_dt_count] = false;
    }

    //Sudarshan - Cell Context Menu

    RowData;
    ModuleIndex: number;
    DT_Count: number;

    //XXXXXXXXXXXXXXXXXXXXX

    onRowSelectInCrudTable(data, SelectableRowKey, SelectableRowKeyValue, cloneRow: boolean, moduleIndex: number, module_dt_count: number) {   // as name suggest
        console.log(this.thisrow);
        this.newrow = false;
        if (cloneRow == true)
            this.thisrow = this.clonerowOfCrudTable(data, moduleIndex);
        else
            this.thisrow = data;

        //this.disable = true;

        if (SelectableRowKey != "" && SelectableRowKey != null) {
            if (this.thisrow[SelectableRowKey] == SelectableRowKeyValue)
                this.displayDialogModule[module_dt_count] = true;
        }
        else {
            this.displayDialogModule[module_dt_count] = true;
        }
    }

    // function for the view details feature
    onRowView(rowdata, dt, moduleindex) {
        this.displaySiebar = !this.displaySiebar
        this.displayDialogModule[dt] = false
        console.log(rowdata, 'data on view')

        let filterArray: any[] = []
        let filter: any = {};
        this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            filter = data[0].moduleList[moduleindex].moduleDetails.filter(elm => elm.InputControls == "HiddenField")
            filter.forEach(elm => filterArray.push(elm.ColumnName))
            console.log(filter, 'this is the filter')
            //loop
            filterArray.forEach(elm => {
                if (rowdata.hasOwnProperty(elm)) {
                    delete rowdata[elm]
                    console.log(rowdata, 'data ater emove in llop')
                }
            })
            //loop ends
        })
        this.thisrow = rowdata

    }

    onRowSelect(event, SelectableRowKey, SelectableRowKeyValue, cloneRow: boolean, moduleIndex: number, subModuleIndex: number, module_dt_count: number, submodule_dt_count: number) { // on row select in formtable
        console.log(this.thisrow);
        this.newrow = false;
        if (cloneRow == true)
            this.thisrow = this.clonerow(event.data, moduleIndex, subModuleIndex);
        else
            this.thisrow = event.data;

        this.filterDropdownValues(moduleIndex, subModuleIndex);//filter dependent dropdowns on dialog load
        this.disable = true;

        if (SelectableRowKey != "" && SelectableRowKey != null) {
            if (this.thisrow[SelectableRowKey] == SelectableRowKeyValue)
                this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
        }
        else {
            this.displayDialogModule[module_dt_count][submodule_dt_count] = true;
        }

    }

    clonerowOfCrudTable(c: any, moduleIndex: number) {  //this clone is used for duplicating and copying rowdata from crudtable/cardview to dialog box
        let row = {};
        var ModuleDetails = this.Module[0].moduleList[moduleIndex].moduleDetails;
        for (let prop in c) {
            row[prop] = c[prop];
            var ModuleDetail = ModuleDetails.find(smd => smd.ColumnName == prop);
            if (c[prop] != null && ModuleDetail != undefined) {
                if (ModuleDetail.InputControls == "DropDownList") {
                    if (typeof c[prop] == "string") {
                        row[prop] = ModuleDetail.DropDownValues.find(v => v.Text == c[prop]);
                    }
                    else if (typeof c[prop] == "object") {
                        row[prop] = ModuleDetail.DropDownValues.find(v => v.Text == c[prop].Text);
                    }
                    else if (typeof c[prop] == "number") {
                        row[prop] = ModuleDetail.DropDownValues.find(v => v.Value == c[prop]);
                    }
                }
                // Sudarshan - Radio Button
                if (ModuleDetail.InputControls == "RadioButton") {
                    if (typeof c[prop] == "string") {
                        row[prop] = ModuleDetail.DropDownValues.find(v => v.Text == c[prop]);
                    }
                    else if (typeof c[prop] == "object") {
                        row[prop] = ModuleDetail.DropDownValues.find(v => v.Text == c[prop].Text);
                    }
                    else if (typeof c[prop] == "number") {
                        row[prop] = ModuleDetail.DropDownValues.find(v => v.Value == c[prop]);
                    }
                }
                else if (ModuleDetail.InputControls == "MultiDropDownList") {
                    if (typeof c[prop] == "string") {

                        if (c[prop].indexOf(',') > -1) {
                            let selected = c[prop];
                            selected = c[prop].split(",");
                            row[prop] = [];
                            selected.forEach(value => {
                                row[prop].push(ModuleDetail.DropDownValues.find(v => v.Text == value));
                            });
                        }
                        else {
                            row[prop] = [];
                            row[prop].push(ModuleDetail.DropDownValues.find(v => v.Text == c[prop]));
                        }
                    }
                    else if (Array.isArray(c[prop])) {
                        row[prop] = [];
                        c[prop].forEach(element => {
                            row[prop].push(ModuleDetail.DropDownValues.find(v => v.Text == element));
                        });
                    }
                }
                else if (ModuleDetail.DataType == "date" || ModuleDetail.DataType == "datetime") {
                    if (typeof c[prop] == "string" && c[prop] != "") {
                        var dateObject = new Date();
                        dateObject = new Date(c[prop]);
                        row[prop] = dateObject;
                    }
                }
            }


        }
        return row;
    }


    clonerow(c: any, moduleIndex: number, subModuleIndex: number): any {    // formtable 
        let row = {};
        var subModuleDetails = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails;
        for (let prop in c) {
            row[prop] = c[prop];
            var subModuleDetail = subModuleDetails.find(smd => smd.ColumnName == prop);
            if (c[prop] != null && subModuleDetail != undefined) {
                if (subModuleDetail.InputControls == "DropDownList") {
                    row[prop] = subModuleDetail.DropDownValues.find(v => v.Text == c[prop]);
                }
                else if (subModuleDetail.InputControls == "MultiDropDownList") {
                    let selected: any[] = [];
                    selected = c[prop].split(",");
                    row[prop] = [];
                    selected.forEach(value => {
                        row[prop].push(subModuleDetail.DropDownValues.find(v => v.Text == value));
                    });

                }
            }
        }
        return row;
    }

    PivotWithDialogRowSelect(event, SelectableRowKey, SelectableRowKeyValue, cloneRow: boolean, moduleIndex: number, subModuleIndex: number) {  //as name suggest
        
        this.newrow = false;

        var SplitVariable = [];
        let StringSplit = SelectableRowKeyValue;
        SplitVariable = StringSplit.split(",");
        console.log(SplitVariable);


        if (cloneRow == true)
            this.thisrow = this.PivotWithDialogclonerow(event.data, moduleIndex, subModuleIndex);
        else
            this.thisrow = event.data;

        this.disable = true;

        if (!SelectableRowKeyValue.includes(",")) {
            if (SelectableRowKey != "" && SelectableRowKey != null) {
                if (this.thisrow[SelectableRowKey] == SelectableRowKeyValue)
                    this.displayDialog = true;
            }
            else {
                this.displayDialog = true;
            }
        }
        else if (SplitVariable.length > 0) {//for multiple keywords

            SplitVariable.forEach(element => {
                if (this.thisrow[SelectableRowKey] == element)
                    this.displayDialog = true;
            });
        }
    }


    PivotWithDialogclonerow(c: any, moduleIndex: number, subModuleIndex: number): any { //as name suggest
        let row = {};
        var subModuleDetails = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails;
        for (let prop in c) {
            row[prop] = c[prop];
            var subModuleDetail = subModuleDetails.find(smd => smd.ColumnName == prop);
            if (c[prop] != null && subModuleDetail != undefined) {
                if (subModuleDetail.InputControls == "DropDownList") {
                    row[prop] = subModuleDetail.DropDownValues.find(v => v.Text == c[prop]);
                }
                else if (subModuleDetail.InputControls == "MultiDropDownList") {
                    let selected: any[] = [];
                    selected = c[prop].split(",");
                    row[prop] = [];
                    selected.forEach(value => {
                        row[prop].push(subModuleDetail.DropDownValues.find(v => v.Text == value));
                    });

                }
            }
        }
        return row;
    }
    convertStringToArray(value: string) {
        return value.split(",");
    }


    GetModulewithFilter(filter: any) {
        filter = JSON.stringify(filter);
        this.applyCSSRuleToRow = false;
        this.show = true;
        let appId: any = null;
        if (this.CurrentApp != null || this.CurrentApp != undefined) appId = this.CurrentApp.ID;
        this.mainpageservice.GetModulesWithFilter(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}', appId).subscribe(data => {
            this.expandedElementArray = [];
            this.frozenColsArray = [];
            this.scrollableColsArray = [];
            this.primeNgTableArray = [];
            this.frozenWidthArray = [];
            this.selectedColumnsArray = [];
            this.treetabledataArray = [];
            this.filterColsArray = [];
            this.headerLevelOneArray = [];
            this.ModuleDataWithRuleArray = [];
            this.visitedPagesData = [];

            this.SubmoduleDataSourceArray = [];
            this.SubmoduleFrozenColsArray = [];
            this.SubModuleFrozenWidthArray = [];
            this.SubmoduleScrollableColsArray = [];
            this.reportTableArray = [];
            this.reportTableColumnsArray = [];
            this.RowRightClickOptions = [];

            this.temptableArray = [];
            this.totalRecords = [];

            this.multichartdata = [];
            console.log("Moduleapi", data);
            this.Module = data;
            this.events.publish('breadcrumb', this.Module[0].breadcrumb);//subscribed in MainPage
            //console.log(data);
            const rows = [];
            let count: number = 0;
            //res.forEach(element => rows.push(element, { detailRow: true, element }));
            ////console.log(rows);
            this.scrollableColsArray = [];
            this.primeNgTableArray = [];
            if (this.Module != undefined && this.Module[0].moduleList.length > 0) {
                this.Module[0].moduleList.forEach((val) => {
                    let headerLevels: number;
                    headerLevels = val.HeaderLevels;
                    let moduleIndex: number;
                    moduleIndex = this.Module[0].moduleList.indexOf(val);

                    let data = val.moduleData.map(a => ({ ...a })); //original data which will be use to compare changes in data before leaving current page
                    let temptabledata = [...data]
                    this.temptableArray.push(temptabledata);//this array is compared in AfterViewChecked


                    if (val.DisplayType == 'Form') {
                        val.moduleDetails.map(d => {
                            if (d.DataType == 'date' || d.DataType == 'datetime') {
                                if (d.value != null && d.value != "") {
                                    d.value = new Date(d.value);
                                }
                            }
                        });
                    }
                    if (val.DisplayType == '' || val.DisplayType.toLowerCase() == "primeunfrozentable" || val.DisplayType.toLowerCase() == "crudtable" || val.DisplayType.toLowerCase() == 'primengpivottable')
                    {//tables with lazy loading
                        this.frozenCols = [];
                        this.scrollableCols = [];
                        this.childColumns = [];
                        this.displayedColumns = [];
                        this.filterCols = [];

                        if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                            val.moduleDetails.forEach((column) => {
                                ///frozen and scrollable columns primeng
                                if (column.HeaderLevel != 1 && column.InputControls != 'HiddenField') {
                                    if (column.FrozenCols == true) {
                                        this.frozenCols.push(column);
                                    }
                                    else {
                                        this.scrollableCols.push(column);
                                    }
                                }
                                else if (column.HeaderLevel == 1 && column.InputControls != 'HiddenField') {
                                    this.headerLevelOne.push(column);
                                }
                                this.filterCols.push(column.ColumnName);
                                //Sudarshan - Cell Context Menus
                                if (column.ShowCellContextMenu) {
                                    this.mapContextMenuCommand(column.ContextMenus, column.ColumnName);//cell wise context menu
                                    this.cellContextMenus = column.ContextMenus;
                                }
                            });

                            /*type cast date data*/
                            var dateColumns = [];
                            dateColumns = val.moduleDetails.filter(md => md.DataType.includes("date") || md.DataType.includes("time"));
                            val.moduleData.map(d => {
                                dateColumns.forEach(column => {
                                    if (d[column.ColumnName] != null)
                                        d[column.ColumnName] = new Date(d[column.ColumnName]);
                                })
                            });
                            if (val.ShowRowContextMenu) this.mapContextMenuCommand(val.RowContextMenus, val.PrimaryKey);

                            if (val.GroupBy != "")//row highlight group by in pivot table
                                this.updateRowGroupMetaData(val.moduleData, val.GroupBy, val.DefaultSortByColumn);

                            let data = val.moduleData.slice(0, val.Rows);
                            this.frozenColsArray.push(this.frozenCols);
                            this.scrollableColsArray.push(this.scrollableCols);
                            this.selectedColumnsArray.push(this.scrollableCols);
                            this.filterColsArray.push(this.filterCols);
                            this.displayDialogModule.push(false);
                            this.totalRecords.push(val.moduleData.length);
                            this.primeNgTableArray.push(data);
                            this.visitedPagesData.push(data);
                            this.ModuleDataWithRuleArray.push(val.ModuleDataWithRule);
                            let frozenWidth = this.frozenCols.length * 150 + 50 + "px";
                            this.frozenWidthArray.push(frozenWidth);
                        }
                    }
                    if (val.DisplayType == 'PrimeNgTable' || val.DisplayType.toLowerCase() == 'primengmultiheadertable' || val.DisplayType == 'MultiHeaderTable' || val.DisplayType == 'Report'
                        || val.DisplayType.toLowerCase() == 'dataview' || val.DisplayType.toLowerCase() == "cardview" || val.DisplayType.toLowerCase() == "tablewithrowgrouping") {

                        //if (val.DisplayType == 'Report') {
                        //    if (val.moduleData != null && val.moduleData != undefined && val.moduleData != "") {
                        //        this.reportTableArray.push(val.moduleData);
                        //        this.reportTableColumnsArray.push(Object.keys(val.moduleData[0]));
                        //    }
                        //}

                        this.frozenCols = [];
                        this.scrollableCols = [];
                        this.childColumns = [];
                        this.displayedColumns = [];
                        this.filterCols = [];
                        this.headerLevelOne = [];
                        this.Dataview.sortOptions = [];


                        let headerLevels: number;
                        headerLevels = val.HeaderLevels;
                        if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                            val.moduleDetails.forEach((column) => {
                                ///frozen and scrollable columns primeng
                                if (column.HeaderLevel != 1 && column.InputControls != 'HiddenField') {
                                    if (column.FrozenCols == true) {
                                        this.frozenCols.push(column);
                                    }
                                    else {
                                        this.scrollableCols.push(column);
                                    }
                                }
                                else if (column.HeaderLevel == 1 && column.InputControls != 'HiddenField') {
                                    this.headerLevelOne.push(column);
                                }
                                this.filterCols.push(column.ColumnName);
                                this.Dataview.sortOptions.push({ label: column.ColumnHeader, value: column.ColumnName });
                            });

                            /*type cast date data*/
                            var dateColumns = [];
                            dateColumns = val.moduleDetails.filter(md => md.DataType.includes("date"));
                            val.moduleData.map(d => {
                                dateColumns.forEach(column => {
                                    if (d[column.ColumnName] != null)
                                        d[column.ColumnName] = new Date(d[column.ColumnName]);
                                })
                            });
                            if (val.DisplayType.toLowerCase() == 'cardview') {
                                this.CardViewModuleDetails = val.moduleDetails;
                                let data = val.moduleData;
                                let that = this;
                                data.map(function (r) {
                                    //To convert string or comma separated values to array for toggle buttons
                                    val.moduleDetails.forEach(md => {
                                        if (md.InputControls.toLowerCase() == "multidropdownlist") {
                                            let columnName = md.ColumnName;
                                            if (r[columnName] != undefined) {
                                                r[columnName] = that.convertStringToArray(r[columnName]);
                                            }

                                        }
                                    });
                                })
                                console.log("Card View", val, this.CardViewModuleDetails)
                            }
                            console.log("Card view data", val.moduleData);

                            this.selectedrow = {};
                            if (val.ShowRowContextMenu) this.mapContextMenuCommand(val.RowContextMenus, val.PrimaryKey);
                            this.ModuleDataWithRule = val.ModuleDataWithRule;
                            this.ModuleDataWithRuleArray.push(this.ModuleDataWithRule);
                            this.primengTableData = val.moduleData;

                            this.frozenColsArray.push(this.frozenCols);
                            this.scrollableColsArray.push(this.scrollableCols);
                            this.primeNgTableArray.push(this.primengTableData);
                            this.selectedColumnsArray.push(this.scrollableCols);
                            this.filterColsArray.push(this.filterCols);
                            this.headerLevelOneArray.push(this.headerLevelOne);
                            this.displayDialogModule.push(false);
                            this.totalRecords.push(val.moduleData.length);
                            if (val.GroupBy != "" && val.DisplayType == "tablewithrowgroup")
                                this.updateRowGroupMetaData(this.primengTableData, val.GroupBy, val.DefaultSortByColumn);
                            else
                                this.updateRowGroupMetaDataSingle(this.primengTableData, val.GroupBy);
                            //this.selectedColumnsArray.push(this.scrollableCols.filter((c,index) => index < 10));

                            let frozenWidth = this.frozenCols.length * 150 + 50 + "px";
                            this.frozenWidthArray.push(frozenWidth);

                            if (val.DisplayType.toLowerCase() == 'dataview') {
                                let filterByColumn: string;
                                filterByColumn = val.FilterByColumn;
                                this.Dataview.filterByValue = this.Dataview.GetFilterOptions(filterByColumn, this.primeNgTableArray[0]);
                            }

                        }
                    }
                    else if (val.DisplayType.toLowerCase() == "listviewsubmodule") {
                        this.listviewdata = val.moduleData;
                        console.log(this.listviewdata);
                        //this.listviewsubmoduledata = val.submodule[0].moduleData;
                        //console.log(this.listviewsubmoduledata);
                    }
                    else if (val.DisplayType == "TreeTable") {
                        this.frozenCols = [];
                        this.scrollableCols = [];
                        this.filterCols = [];
                        if (val.moduleDetails != null && typeof (val.moduleDetails) != undefined) {
                            val.moduleDetails.forEach((column) => {
                                ///frozen and scrollable columns primeng
                                if (column.InputControls != "HiddenField") {

                                    if (column.FrozenCols == true) {
                                        this.frozenCols.push(column);
                                    }
                                    else {
                                        this.scrollableCols.push(column);
                                    }
                                }
                                this.filterCols.push(column.ColumnName);
                            });


                            this.RowRightClickOption = [];
                            var linkColumns = val.moduleDetails.filter(md => md.InputControls.includes("Link"));
                            linkColumns.forEach(column => {
                                this.RowRightClickOption.push({ label: column.ColumnHeader, icon: 'pi pi-fw pi-external-link', command: () => this.navigate(this.selectedProduct[column.ColumnName], column.LinkedMenu) });
                            });
                            this.RowRightClickOptions.push(this.RowRightClickOption);


                            var treedata = [];
                            treedata = val["TreeOutputDataRoot"];
                            let file: FileSystemNode[] = [];

                            for (let i = 0; i < (<any>treedata).data.length; i++) {

                                let rootObj = (<any>treedata).data[i];
                                let node = new FileSystemNode(rootObj, null, val.moduleDetails);
                                file.push(node);
                            }
                            this.files = file;
                            let frozenWidth = this.frozenCols.length * 150 + 50 + "px";

                            this.scrollableColsArray.push(this.scrollableCols);
                            this.frozenColsArray.push(this.frozenCols);
                            this.primeNgTableArray.push(this.files);
                            this.filterColsArray.push(this.filterCols);
                            this.frozenWidthArray.push(frozenWidth);
                            this.selectedColumnsArray.push(this.scrollableCols);
                            this.filterColsArray.push(this.filterCols);
                            this.headerLevelOneArray.push(null);
                            this.displayDialogModule.push('no dialog');
                            this.loading = false;
                        }
                    }
                    else if (val.DisplayType.toLowerCase() == 'pivottablewithdialog') {

                        val.submodule.forEach(submodule => {
                            let subModuleIndex: number;
                            subModuleIndex = val.submodule.indexOf(submodule);
                            if (submodule.DisplayType == 'PrimeNgPivotTable' || submodule.DisplayType == 'PivotTable' ||
                                submodule.DisplayType == 'PrimeNgTable' || submodule.DisplayType == 'PrimeUnfrozenTable' ||
                                submodule.DisplayType == '' || submodule.DisplayType.toLowerCase() == 'table') {

                                this.frozenCols = [];
                                this.scrollableCols = [];
                                this.childColumns = [];
                                this.displayedColumns = [];
                                this.filterCols = [];
                                this.headerLevelOne = [];

                                let headerLevels: number;
                                headerLevels = val.HeaderLevels;
                                if (submodule.subModuleDetails != null && typeof (submodule.subModuleDetails) != undefined) {
                                    submodule.subModuleDetails.forEach((column) => {
                                        ///frozen and scrollable columns primeng
                                        if (column.InputControls != "HiddenField" && column.HeaderLevel != 1) {
                                            if (column.FrozenCols == true) {
                                                this.frozenCols.push(column);
                                            }
                                            else {
                                                this.scrollableCols.push(column);
                                            }
                                        }
                                        else if (column.InputControls != "HiddenField" && column.HeaderLevel == 1) {
                                            this.headerLevelOne.push(column);
                                        }
                                        this.filterCols.push(column.ColumnName);

                                    });

                                    /*type cast date data*/
                                    var dateColumns = [];
                                    dateColumns = submodule.subModuleDetails.filter(md => md.DataType.includes("date"));
                                    submodule.moduleData.map(d => {
                                        dateColumns.forEach(column => {
                                            if (d[column.ColumnName] != null)
                                                d[column.ColumnName] = new Date(d[column.ColumnName]);
                                        })
                                    });
                                    this.SubmoduleDataSourceArray.push(submodule.moduleData);
                                    if (this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].ColumnsForSum) {
                                        this.SubmoduleDataSourceArray[subModuleIndex].forEach(rowdata => {
                                            this.calculateRowWiseTotalSubmodule(rowdata, moduleIndex, subModuleIndex)
                                        });
                                    }

                                    this.displayedSubmoduleColumns.push(this.scrollableCols);
                                    this.frozenColsSubmoduleArray.push(this.frozenCols);
                                    let frozenWidth = this.frozenCols.length * 150 + 50 + "px";
                                    this.frozenWidthSubModuleArray.push(frozenWidth);
                                    this.displayDialogModule.push('no dialog');
                                }
                            }
                        });
                        console.log("ARRAYS", this.displayedSubmoduleColumns, this.SubmoduleDataSourceArray, this.frozenColsSubmoduleArray, this.frozenWidthSubModuleArray)
                    }

                    else if (val.DisplayType == 'FormTableSubmodule') {
                        this.SubmoduleDataSource = [];
                        this.SubmoduleScrollableCols = [];
                        this.SubmoduleFrozenCols = [];
                        this.SubModuleFrozenWidth = [];
                        this.displayDialogSubmodule = [];

                        val.submodule.forEach(moduleinsidesubmodule => {
                            let subModuleIndex: number;
                            subModuleIndex = val.submodule.indexOf(moduleinsidesubmodule);
                            if (moduleinsidesubmodule.DisplayType == 'Form') {
                                moduleinsidesubmodule.subModuleDetails.map(d => {
                                    if (d.DataType == 'date' || d.DataType == 'datetime') {
                                        if (d.value != null && d.value != "") {
                                            d.value = new Date(d.value);
                                        }
                                    }
                                });
                            }

                            if (moduleinsidesubmodule.DisplayType == 'PrimeNgPivotTable' || moduleinsidesubmodule.DisplayType == 'PivotTable' ||
                                moduleinsidesubmodule.DisplayType == 'PrimeNgTable' || moduleinsidesubmodule.DisplayType == 'PrimeUnfrozenTable' ||
                                moduleinsidesubmodule.DisplayType == '' || moduleinsidesubmodule.DisplayType.toLowerCase() == 'table') {

                                this.frozenCols = [];
                                this.scrollableCols = [];
                                this.childColumns = [];
                                this.displayedColumns = [];
                                this.filterCols = [];
                                this.headerLevelOne = [];

                                let headerLevels: number;
                                headerLevels = val.HeaderLevels;
                                if (moduleinsidesubmodule.subModuleDetails != null && typeof (moduleinsidesubmodule.subModuleDetails) != undefined) {
                                    moduleinsidesubmodule.subModuleDetails.forEach((column) => {
                                        ///frozen and scrollable columns primeng
                                        if (column.InputControls != "HiddenField" && column.HeaderLevel != 1) {
                                            if (column.FrozenCols == true) {
                                                this.frozenCols.push(column);
                                            }
                                            else {
                                                this.scrollableCols.push(column);
                                            }
                                        }
                                        else if (column.InputControls != "HiddenField" && column.HeaderLevel == 1) {
                                            this.headerLevelOne.push(column);
                                        }
                                        this.filterCols.push(column.ColumnName);

                                    });
                                    /*type cast date data*/
                                    var dateColumns = [];
                                    dateColumns = moduleinsidesubmodule.subModuleDetails.filter(md => md.DataType.includes("date"));
                                    moduleinsidesubmodule.moduleData.map(d => {
                                        dateColumns.forEach(column => {
                                            if (d[column.ColumnName] != null)
                                                d[column.ColumnName] = new Date(d[column.ColumnName]);
                                        })
                                    });

                                    if (this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].ColumnsForSum) {
                                        this.SubmoduleDataSource[0].forEach(rowdata => {
                                            this.calculateRowWiseTotalSubmodule(rowdata, moduleIndex, subModuleIndex)
                                        });
                                    }
                                }
                                let frozenWidth = this.frozenCols.length * 150 + 50 + "px";
                                this.SubmoduleDataSource.push(moduleinsidesubmodule.moduleData);
                                this.SubmoduleScrollableCols.push(this.scrollableCols);
                                this.SubmoduleFrozenCols.push(this.frozenCols);
                                this.SubModuleFrozenWidth.push(frozenWidth);
                                this.displayDialogSubmodule.push(false);
                            }
                        });
                        this.primeNgTableArray.push(this.SubmoduleDataSource);
                        this.scrollableColsArray.push(this.SubmoduleScrollableCols);
                        this.frozenColsArray.push(this.SubmoduleFrozenCols);
                        this.selectedColumnsArray.push(this.scrollableCols);
                        this.frozenWidthArray.push(this.SubModuleFrozenWidth);
                        this.filterColsArray.push(this.filterCols);
                        this.headerLevelOneArray.push(null);
                        this.displayDialogModule.push(this.displayDialogSubmodule);
                    }
                    else if (val.DisplayType.toLowerCase() == "tree") {
                        //var treeModuleData=<TreeNode[]>val.TreeData;
                        this.treeModuleData = <TreeNode[]>val.TreeData;
                        this.treeModuleData = this.treeModuleData[0]['children'];
                        this.treeModuleData.forEach(node => {
                            this.expandRecursive(node, true);
                        });

                        console.log(this.treeModuleData);
                        //this.treeModuleArray.push(treeModuleData);
                    }
                    else if (val.DisplayType.toLowerCase() == "summernoteview") {
                        this.treeModuleData = <TreeNode[]>val.TreeData;
                        this.treeModuleData.forEach(node => {
                            this.expandTreeRecursive(node, false);
                        })
                    }
                    else if (val.DisplayType.toLowerCase() == "menuflow" || val.DisplayType.toLowerCase() == "processflow") {
                        this.timelineTableArray = [];
                        this.timelineTableColumnsArray = [];
                        val.moduleData.sort((a, b) => (a.orderby > b.orderby) ? 1 : ((a.orderby < b.orderby) ? -1 : 0));
                        this.timelineData = val.TimelineData.filter(item => item.Submodule.tilesgroupdata.length > 0);
                        this.events.publish('timelineData', this.timelineData);
                        localStorage.setItem('timelineData', JSON.stringify(this.timelineData));
                        console.log(this.timelineData);
                        if (this.timelineData != null && this.timelineData != undefined && this.timelineData != "") {
                            this.timelineData.forEach(event => {
                                if (event.Submodule.moduleData != null && event.Submodule.moduleData != undefined) {
                                    this.timelineTableArray.push(event.Submodule.moduleData);
                                    // this.timelineTableColumnsArray.push(Object.keys(event.Submodule.moduleData[0]));
                                }
                                else {
                                    this.timelineTableArray.push([]);
                                    // this.timelineTableColumnsArray.push([]);
                                }

                                if (event.Submodule.subModuleDetails != null && event.Submodule.subModuleDetails != undefined) {
                                    let columns = [];
                                    event.Submodule.subModuleDetails.forEach(submoduledetail => {
                                        if (submoduledetail.InputControls != 'HiddenField') {
                                            columns.push(submoduledetail);
                                        }

                                    });
                                    this.timelineTableColumnsArray.push(columns);
                                }
                            });

                        }
                        console.log(this.timelineTableArray, this.timelineTableColumnsArray);
                        localStorage.setItem("MenuFlowData", this.timelineData);
                    }
                    else if (val.DisplayType.toLowerCase() == "multichart" && val.moduleDetails != null) {
                        let dataWithRules = val.ModuleDataWithRule;
                        if (dataWithRules != null) {
                            let dataArray = Object.keys(dataWithRules).map(function (index) {//convert Object to array
                                let data = dataWithRules[index];
                                return data;
                            });
                            this.multichartdata = dataArray;
                        }
                    }
                });
            }
            
            this.show = false;
        });
    }
    EditCard(pkColumnValue, pkColumn, Moduleindex) {
        let data = this.Module[0].moduleList[Moduleindex].moduleData;
        let row = data.find(r => r[pkColumn] == pkColumnValue);
        this.thisrow = row;
        this.thisrow = this.clonerowOfCrudTable(this.thisrow, Moduleindex);

    }

    closeCardDialog() {
        this.addNew = false;
        this.thisrow = {}
    }
    expandRecursive(node: TreeNode, isExpand: boolean) {    //Created to expand the current node as well as all the child node of current node.
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }
    expandTreeRecursive(node: TreeNode, isExpand: boolean) {    //Created to expand the current node as well as all the child node of current node.

        node.expanded = isExpand;

        if (node.children) {

            node.children.forEach(childNode => {

                this.expandTreeRecursive(childNode, false);

            });

        }

    }
    modelChange(event, dtcount) {                       // Created to toggle the columns of pivot table and restore it with orderby 
        console.log(this.selectedColumnsArray[dtcount])
        this.selectedColumnsArray[dtcount] = event;
        this.selectedColumnsArray[dtcount].sort(
            function compare(a, b) {
                if (a.OrderBy < b.OrderBy)
                    return -1;
                if (a.OrderBy > b.OrderBy)
                    return 1;
                return 0;
            }

        );
        console.log(this.selectedColumnsArray[dtcount]);
    }

    GetPaginatedTreeTable(filter: any) {    
        this.expandedElementArray = [];
        filter = JSON.stringify(filter);
        this.show = true;

        this.mainpageservice.GetPaginatedTreeTable(this.Sys_Menu_ID, this.UserName, '{Filter:' + filter + '}').subscribe(data => {
            console.log("Moduleapi", data);
            this.Module = data;
            var columns = [];
            columns = data["moduleDetails"];
            var treedata = [];
            treedata = data["TreeOutputDataRoot"];
            var moduleDetails = data["moduleDetails"];
            var temp = [];
            if (moduleDetails != null && typeof (moduleDetails) != undefined) {
                moduleDetails.forEach((column) => {
                    if (column.InputControls != 'HiddenField')
                        temp.push(column);
                });
            }
            this.cols = temp;

            let file: FileSystemNode[] = [];

            for (let i = 0; i < (<any>treedata).data.length; i++) {

                let rootObj = (<any>treedata).data[i];
                let node = new FileSystemNode(rootObj, null, columns);
                file.push(node);
            }
            this.files = file;
            console.log("treedata", this.files);
        });
    }


    nestedFilterCheck(search, data, key) {
        if (typeof data[key] === 'object') {
            for (const k in data[key]) {
                if (data[key][k] !== null) {
                    search = this.nestedFilterCheck(search, data[key], k);
                }
            }
        } else {
            search += data[key];
        }
        return search;
    }//searching nested object array

    showInFilter(ParameterName) {//to show/hide filter fields userwise(userwise menu detail mapping)
        if (this.PageMenuDetails != undefined && this.PageMenuDetails != null)
            return this.PageMenuDetails.filter(menudetail => menudetail.ParameterName == ParameterName)[0].ShowInFilter;
    }


    getPageMenuDetails() {
        let appid = localStorage.getItem("CurrentApp");
        this.CurrentApp = JSON.parse(appid);

        var appliedFilters = localStorage.getItem("filterdata");
        var filterJson = JSON.parse(appliedFilters);
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let filter1 = localStorage.getItem('navigationExtras');
                this.mainpageservice.getPageMenuDetails(this.Sys_Menu_ID, this.UserName).toPromise().then(filter => {
                    this.PageMenuDetails = filter;
                    if (filterJson != null && filterJson != undefined && Object.keys(filterJson).length !== 0) {
                        //apply default value to all menus with same menudetails
                        if (this.PageMenuDetails != undefined && this.PageMenuDetails.length > 0) {
                            Object.keys(filterJson).forEach(key => {
                                var pagemenudetail = this.PageMenuDetails.find(pgm => pgm.ParameterName == key);
                                if (pagemenudetail != undefined && pagemenudetail != null) {
                                    if (typeof (filterJson[key]) == "object" && pagemenudetail.InputControl.toLowerCase() == "dropdownlist" && filterJson[key].hasOwnProperty("Value"))
                                        pagemenudetail.DefaultValue = filterJson[key].Value;
                                    else
                                        pagemenudetail.DefaultValue = filterJson[key];
                                }

                            });
                        }
                    }
                    this.show = false;
                    if (this.PageMenuDetails != undefined && this.PageMenuDetails.length > 0) {
                        if (this.PageMenuDetails[0].ParameterName != "rowval") {
                            //get default value from database if parameter name is not rowval
                            var pageFilter = {};
                            this.PageMenuDetails.forEach(menudetail => {
                                if (menudetail.MinDate != "" && menudetail.MaxDate != "") {
                                    var MinDateObject = new Date();

                                    var DDObject = new Date(menudetail.MinDate);
                                    var mon = DDObject.getMonth();
                                    var day = DDObject.getDate();
                                    var year = DDObject.getFullYear();
                                    var hour = DDObject.getHours();
                                    var minutes = DDObject.getMinutes();

                                    MinDateObject = new Date(year, mon, day, hour, minutes);
                                    menudetail.MinDate = MinDateObject;

                                    var MaxDateObject = new Date();

                                    var DObject = new Date(menudetail.MaxDate);
                                    var mon = DObject.getMonth();
                                    var day = DObject.getDate();
                                    var year = DObject.getFullYear();
                                    var hour = DObject.getHours();
                                    var minutes = DObject.getMinutes();

                                    MaxDateObject = new Date(year, mon, day, hour, minutes);
                                    menudetail.MaxDate = MaxDateObject;
                                }

                                if (menudetail.DataType == "month") {//convert default date string to date object
                                    var dateObject = new Date();
                                    if (menudetail.DefaultValue.toLowerCase() == "last") {
                                        dateObject.setMonth(dateObject.getMonth() - 1);

                                        menudetail.DefaultValue = dateObject;
                                        pageFilter[menudetail.ParameterName] = "" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();

                                    }
                                    else if (menudetail.DefaultValue.toLowerCase() == "current" || menudetail.DefaultValue.toLowerCase() == "") {
                                        dateObject.setMonth(dateObject.getMonth());

                                        menudetail.DefaultValue = dateObject;
                                        pageFilter[menudetail.ParameterName] = "" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();

                                    }
                                    else if (menudetail.DefaultValue.toLowerCase() != "last" && menudetail.DefaultValue.toLowerCase() != "" && menudetail.DefaultValue.toLowerCase() != "current") {
                                        var dateArray = menudetail.DefaultValue.split("/");

                                        dateObject.setMonth(dateArray[0] - 1);
                                        dateObject.setFullYear(dateArray[1]);

                                        menudetail.DefaultValue = dateObject;
                                        if (dateArray[0] < 10)
                                            pageFilter[menudetail.ParameterName] = "0" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();
                                        else
                                            pageFilter[menudetail.ParameterName] = "" + (dateObject.getMonth() + 1) + "/" + dateObject.getFullYear();
                                    }

                                }
                                else if (menudetail.DataType == "date" || menudetail.DataType == "datetime") {//convert default date string to date object
                                    var dateObject = new Date();
                                    if (menudetail.DefaultValue != "") {
                                        dateObject = new Date(menudetail.DefaultValue);
                                    }

                                    menudetail.DefaultValue = dateObject;
                                    var month;
                                    if ((dateObject.getMonth() + 1) < 10)
                                        month = "0" + (dateObject.getMonth() + 1);
                                    else
                                        month = dateObject.getMonth() + 1;
                                    pageFilter[menudetail.ParameterName] = "" + dateObject.getFullYear() + "-" + month + "-" + (dateObject.getDate());
                                }
                                else if (menudetail.InputControl.toLowerCase() == "dropdownlist") {
                                    let defaultValue = menudetail.DropDownValues.find(v => v.Value == parseInt(menudetail.DefaultValue));
                                    menudetail.DefaultValue = defaultValue;
                                    pageFilter[menudetail.ParameterName] = defaultValue;
                                }
                                else {
                                    pageFilter[menudetail.ParameterName] = menudetail.DefaultValue;//to create filter json
                                }
                            });
                            this.GetModulewithFilter(pageFilter);
                            console.log("PageMenuDetails", this.PageMenuDetails);
                            console.log("PageFilter", pageFilter);
                        }
                        else {
                            //get modules when rowval exist
                            this.GetModulewithFilter(filter1);
                        }
                    }
                    else {
                        //get modules when menu without menu details/filters
                        let tempFilter = localStorage.setItem('navigationExtras', "");
                        this.GetModulewithFilter(filter1);
                    }
                    this.appliedFilters = JSON.parse(localStorage.getItem("filterdata"));
                    this.filterValues = [];
                    this.PageMenuDetails.forEach((item) => {
                        if (Array.isArray(item)) {
                            item['DefaultValue'].forEach(elm => {
                                this.filterValues.push(elm.Text)
                                // this.appliedFilters.push(elm)
                            })
                        }
                        else if (item.DefaultValue !== null && typeof item.DefaultValue == 'object' && item.DefaultValue.hasOwnProperty('Text')) {
                            console.log(item)
                            this.filterValues.push(item.DefaultValue.Text)
                            // this.appliedFilters.push(item.DefaultValue)
                        }
                        else if (typeof item.DefaultValue == 'string' && item.DefaultValue !== '') {
                            let pattern = /(0[1-9]|10|11|12)\/[0-9]{4}/;
                            if (pattern.test(item.DefaultValue)) {
                                let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                let monthInFilter = months[Number(item.DefaultValue.slice(-7, -5)) - 1];
                                // console.log(month3 + " " + item.slice(-4),'month3')
                                this.filterValues.push(monthInFilter + " " + item.DefaultValue.slice(-4))
                            }
                            else {
                                this.filterValues.push(item.DefaultValue)
                            }
                           // this.filterValues.push(item.DefaultValue)
                            // this.appliedFilters.push(item.DefaultValue)
                        }
                    })

                });

                resolve();
            }, 100)

        });
        return promise;

    }
    getModulesToShow() {//to get modules list to show userwise
        this.mainpageservice.getModulesToShow(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleIDList.push(dataArray)
            }

        })
    }

    getModuleDetailIdToHide() {     
        //To get ModuledetailIDTo hide
        this.mainpageservice.getModuleDetailIdToHide(this.Sys_Menu_ID, this.UserName).subscribe(data => {
            let dataArray: Array<any> = [];
            for (let item in data) {
                dataArray = data[item];
                this.ModuleDetailIDList.push(dataArray)
            }
            this.getFeedback();
        })
    }

    getFeedback() { 
        this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {
            this.Feedback = data;
        });
    }

    @HostListener('window:beforeunload')    //before unloading a page it checks if page include changes if true then alert a popup to save changes
    canDeactivate(): Observable<boolean> | boolean {
        // insert logic to check if there are pending changes here;
        // returning true will navigate without confirmation
        // returning false will show a confirm dialog before navigating away
        return !this.haveChangesOnPage;
    }

    ngAfterViewChecked() {
        if (this.Module) {
            let tableModules;
            if (this.Module && this.Module.length > 0) {
                tableModules = this.Module[0].moduleList.filter(m => m.DisplayType.toLowerCase().includes("table") || m.DisplayType.toLowerCase() == "" || m.DisplayType.toLowerCase() == "dataview");
                if (this.primeNgTableArray && this.temptableArray && tableModules.length > 0)//to detect changes before leaving the page
                {
                    const data = this.temptableArray.map(a => ({ ...a }));
                    const tempdata = this.temptableArray;
                    const tableCount = this.primeNgTableArray.length;

                    for (var i = 0; i < tableCount; i++) {
                        if (tableModules[i].DisplayType.toLowerCase() != "treetable" && tableModules[i].DisplayType.toLowerCase() != "tree") {
                            if (JSON.stringify(this.primeNgTableArray[i]) != JSON.stringify(tempdata[i])) {
                                this.haveChangesOnPage = true;
                            }
                        }
                        else {
                            if (tableModules[i].DisplayType.toLowerCase() == "treetable") {
                                var updateData = this.files.filter(element => element.data.Updated == true)
                                if (updateData.length > 0) {
                                    this.haveChangesOnPage = true;
                                    console.log(this.haveChangesOnPage);
                                }
                            }
                        }
                    }
                }
            }
            
        }

    }

    ngOnInit() {
        this.show = true;
        this.loading = true;
        this.haveChangesOnPage = false;
        let filter1 = localStorage.getItem('navigationExtras');
        this.Locale = localStorage.getItem("Locale");
        this.Currency = localStorage.getItem("Currency");
        this.DateFormat = localStorage.getItem("DateFormat");
        this.TimeZone = localStorage.getItem("TimeZone");
        this.hourFormat = parseInt(localStorage.getItem("HourFormat"));
        if (this.hourFormat == 24) {
            this.dateTimeFormat = this.DateFormat + " HH:mm";
        }
        else
            this.dateTimeFormat = this.DateFormat + " hh:mm";
        // this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        // ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
        //     this.GetModulewithFilter(filter1);
        // });

        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.rowval = this.activateRouter.snapshot.queryParamMap.get('rowval');
            }
        });
        //this.database.getTopic(1).subscribe((response) => {
        //    this.topicId = 1;
        //    this.html = response.Content;
        //    this.editorDisabled = true;
        //    this.isEditButtonClicked = true;
        //});
        this.UserName = localStorage.getItem('username');

        //this.getPageMenuDetails().then(
        //    () => this.getModuleDetailIdToHide(),
        //    () => this.getModulesToShow(),
        //)
        //this.database.getTopic(1).subscribe((response) => {

        //    this.topicId = 1;

        //    this.html = response.Content;

        //    this.editorDisabled = true;

        //    this.isEditButtonClicked = true;

        //});
    }

    GetDependentFilterDropDown(ParameterId, event, index) {
        debugger;
        var IDvalue = event.value.Value == undefined ? event.value.Value : event.value.Value;
        this.mainpageservice.getDependentFilterDropDownValue(ParameterId, IDvalue).subscribe(response => {
            debugger;
            console.log(response);
            var pagemenudetail = this.PageMenuDetails.find(pgm => pgm.ParameterName == index);
            pagemenudetail.DropDownValues = <any>(response)["Table"];
        }, err => {
            console.log(err);
        });

    }

    //filterDropdownValues(moduleIndex, subModuleIndex) {//filter dependent dropdowns on dialog load
    //    let moduleDetails = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails;

    //    let mdWithDependentOn = moduleDetails.filter(md => md.DependentOn != 0 && md.AutoPopulateSP == null)//moduledetails with dependentOn and without autoPopulateSP

    //    if (mdWithDependentOn != null) {
    //        mdWithDependentOn.forEach(md => {
    //            let dependentOnID = md.DependentOn;
    //            let moduleDetail = moduleDetails.find(md => md.ID == dependentOnID);
    //            let filterValue = this.thisrow[moduleDetail.ColumnName];
    //            let that = this;
    //            this.mainpageservice.populateDropdown(moduleDetail.ColumnName, filterValue, md.DropDownSP, this.UserName).subscribe(resp => {
    //                console.log(md.ColumnName, resp);
    //                let submoduleDetailIndex = that.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.indexOf(md);
    //                that.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails[submoduleDetailIndex].DropDownValues = resp;
    //            })
    //        });
    //    }
    //}

    //Ruchita Mam - Multi Dependent Dropdown Filters
    filterDropdownValues(moduleIndex, subModuleIndex) {//filter dependent dropdowns on dialog load
        let moduleDetails = [];
        if (subModuleIndex != null) {
            moduleDetails = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails;
        }
        else {
            moduleDetails = this.Module[0].moduleList[moduleIndex].moduleDetails;
        }


        let mdWithDependentOn = moduleDetails.filter(md => md.DependentOn != 0 && md.AutoPopulateSP == null)//moduledetails with dependentOn and without autoPopulateSP

        if (mdWithDependentOn != null) {
            mdWithDependentOn.forEach(md => {
                let dependentOnID = md.DependentOn;
                let moduleDetail = moduleDetails.find(md => md.ID == dependentOnID);
                let filterValue;
                if (subModuleIndex != null) {
                    filterValue = this.thisrow[moduleDetail.ColumnName];
                }
                else {
                    if(moduleDetail.value != null)
                    {
                        filterValue = moduleDetail.value.Value;
                    }
                    //For Crud Table
                    else
                    {
                        filterValue = this.thisrow[moduleDetail.ColumnName].Value;
                    }
                }


                let that = this;
                this.mainpageservice.populateDropdown(moduleDetail.ColumnName, filterValue, md.DropDownSP, this.UserName, this.CurrentApp.ID).subscribe(resp => {
                    console.log(md.ColumnName, resp);
                    if (subModuleIndex != null) {
                        let submoduleDetailIndex = that.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.indexOf(md);
                        that.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails[submoduleDetailIndex].DropDownValues = resp;
                    }
                    else {
                        let moduleDetailIndex = that.Module[0].moduleList[moduleIndex].moduleDetails.indexOf(md);
                        that.Module[0].moduleList[moduleIndex].moduleDetails[moduleDetailIndex].DropDownValues = resp;
                    }

                })
            });
        }
    }
    //XXXXXXXXXXX XXXXXXX XXXXXXXXXXXXXX


    DropdownChange(ModuleDetailId, event, index) {

        if (event.value.Value != '') {
            this.mainpageservice.getDependentDropDownValue(ModuleDetailId, event.value.Value).subscribe(response => {

                //console.log(response);
                this.DropDown[index] = <any>(response)["Table"];
            }, err => {

            });
        }

    }

    //susan added - start
    GetDependentDropDown(ParameterId, event, index, moduleIndex) {
        debugger;
        var IDvalue = event.value.Value == undefined ? event.value.Value : event.value.Value;
        //var IDvalue = event.detail.value.Value == undefined ? event.detail.value.Value : event.detail.value.Value;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.getDependentDropDownValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var moduledetail = this.Module[0].moduleList[moduleIndex].moduleDetails.find(md => md.ColumnName == index);
            moduledetail.DropDownValues = <any>(response)["Table"];
            // var DropDown = document.getElementsByName(index)
            // this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            //console.log(err);
        });
    }
    //susan added - end




    GetDependentDropDownThroughValue(ParameterId, event, index) {
        //var IDvalue = event.detail.value.Text == undefined ? event.detail.value.Text : event.detail.value.Text;
        var IDvalue = event.value.Text == undefined ? event.value.Text : event.value.Text;
        var trimValue = $.trim(IDvalue);
        this.mainpageservice.GetDependentDropDownThroughValue(ParameterId, trimValue).subscribe(response => {
            debugger;
            //console.log(response);
            var DropDown = document.getElementsByName(index)
            this.DropDown[index] = <any>(response)["Table"];
        }, err => {
            ////console.log(err);
        });
    }


    navigateOnFormSubmit(LinkedMenu) {
        let filter = localStorage.getItem("navigationExtras");
        this.events.publish('navigationExtras', JSON.parse(filter));
        let navigationExtras: NavigationExtras = {
            queryParams: JSON.parse(filter)

        };
        this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);

    }

    //Button config save/update FormTable
    UpdateSubmodule(form: any, dt, ID, ButtonId,moduleIndex) {
        var submodules = this.Module[0].moduleList[moduleIndex].submodule;
        submodules.forEach(submodule => {
            var dateColumns = [];
            dateColumns = submodule.subModuleDetails.filter(md => md.DataType == "date");
            if (submodule.DisplayType == "Form") {
                dateColumns.forEach(column => {
                    if (form[column.ColumnName] != null) {
                        let adate = new Date(form[column.ColumnName]);
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        form[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                    }
                })
            }
            else if (submodule.DisplayType == "" || submodule.DisplayType == "PrimeUnfrozenTable") {
                //dt.toArray().foreach(table => {
                dt[0].map(d => {
                    dateColumns.forEach(column => {
                        if (d[column.ColumnName] != null) {
                            let adate = new Date(d[column.ColumnName]);
                            var ayear = adate.getFullYear();
                            var amonth: any = adate.getMonth() + 1;
                            var adt: any = adate.getDate();
                            if (adt < 10) { adt = '0' + adt; }
                            if (amonth < 10) { amonth = '0' + amonth; }
                            d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                        }
                    })
                });
                // })

            }
        });


        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });

        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.UpdateSubmodules(ID, form, dt, ButtonId, this.UserName).subscribe(resp => {
            console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);

            toast.then(toast => toast.present());
            this.show = false;

        });
    }

    //Button config save for form
    UpdateData(form: any, ID: any, ButtonId: any, LinkedMenu: any) {
        let filter1 = localStorage.getItem('navigationExtras');
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        //Form Without FileUpload
        this.mainpageservice.UpdateData(ID, ButtonId, form, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter1);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }
    //Button config save for table/pivottable
    UpdateDatatable(ID: any, ButtonId: any, index: number, dt, filter: any, LinkedMenu: any) {
        debugger;
        if (this.rowval != "" && this.rowval != null)
            filter = { 'rowval': this.rowval };

        this.show = true;
        /*type cast date to string*/
        var dateColumns = [];
        dateColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "date");
        dt.value.map(d => {
            dateColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                }
                //        if (d[column.ColumnName] != null) {

                //            d[column.ColumnName] = d[column.ColumnName].toISOString();
                //        }
            })
        });

        var datetimeColumns = [];
        datetimeColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "datetime");
        dt.value.map(d => {
            datetimeColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var ahour: any = adate.getHours();
                    var aminute: any = adate.getMinutes();
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt + " " + ahour + ":" + aminute + ":00";
                }
            })
        });

        //var dropdowns = [];
        //dropdowns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.InputControls.includes("DropDownList"));
        //dt.value.map(d => {
        //    dropdowns.forEach(column => {
        //        if (d[column.ColumnName] != null) {
        //            if (d[column.ColumnName].Text != undefined)
        //                d[column.ColumnName] = d[column.ColumnName].Text;
        //            else
        //                d[column.ColumnName] = d[column.ColumnName];
        //        }
        //    })
        //})

        filter = JSON.stringify(filter);
        var data = {};
        if (dt.hasFilter()) {
            data["data"] = dt.filteredValue.slice(0, parseInt(dt.rows) + parseInt(dt.first))
        }
        else {
            data["data"] = dt.value.slice(0, parseInt(dt.rows) + parseInt(dt.first));
        }
        data["filter"] = filter;

        //Form Without FileUpload
        this.mainpageservice.UpdateDatatablewithfilter(ID, this.Sys_Menu_ID, ButtonId, data, this.UserName).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter);
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;

        });
    }

    public GetPivotTableModule(ID: number, dt: any, filter: any, dtIndex: number) {
        this.mainpageservice.GetPivotTableModule(this.Sys_Menu_ID, ID, this.UserName, filter).subscribe(res => {
            var val = res;
            if (val["moduleDetails"] != null && typeof (val["moduleDetails"]) != undefined) {
                this.primengTableData = val["moduleData"];
                this.primeNgTableArray[dtIndex] = this.primengTableData;
            }


            if (dt.hasFilter()) {
                console.log(dt.filters);
                this.primengTable.filters = dt.filters
            }

        });

    }

    SubmitPrimeNgTable(ID: any, filter: any, dt: any, index: number, dtIndex?: number) {
        debugger;
        let filter1 = localStorage.getItem('navigationExtras');
        let appId: any = null;
        if (this.CurrentApp != null || this.CurrentApp != undefined) appId = this.CurrentApp.ID;

        //var navigationExtras = JSON.parse(filter1);
        var navigationExtras = null;
        if (filter1 != null && filter1.trim() !== "") {
            navigationExtras = JSON.parse(filter1);
        }

        if (filter == null)//dt filter
            filter = {};
        if (navigationExtras != null && navigationExtras != undefined) {//navigation filters like rowval
            Object.keys(navigationExtras).forEach(key => {
                filter[key] = navigationExtras[key];
            });
        }
        let appliedFilters = JSON.parse(sessionStorage.getItem('filterdata'));//page menu filters
        if (appliedFilters != null && appliedFilters != undefined) {
            Object.keys(appliedFilters).forEach(key => {
                filter[key] = appliedFilters[key];
            });
        }

        this.show = true;
        /*type cast date to string*/
        var dateColumns = [];
        dateColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "date");
        dt.value.map(d => {
            dateColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt;
                }
            })
        });

        var datetimeColumns = [];
        datetimeColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.DataType == "datetime");
        dt.value.map(d => {
            datetimeColumns.forEach(column => {
                if (d[column.ColumnName] != null) {
                    let adate = new Date(d[column.ColumnName]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var ahour: any = adate.getHours();
                    var aminute: any = adate.getMinutes();
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    d[column.ColumnName] = ayear + '-' + amonth + '-' + adt + " " + ahour + ":" + aminute + ":00";
                }
            })
        });

        var checkboxColumns = [];
        checkboxColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.InputControls == "Checkbox");
        dt.value.map(d => {
            checkboxColumns.forEach(column => {
                if (d[column.ColumnName] == null || d[column.ColumnName] == "") {
                    d[column.ColumnName] = false;
                }
            })
        });

        var switchColumns = [];
        switchColumns = this.Module[0].moduleList[index].moduleDetails.filter(md => md.InputControls == "Switch");
        dt.value.map(d => {
           switchColumns.forEach(column => {
                if (d[column.ColumnName] == null || d[column.ColumnName] == "") {
                    d[column.ColumnName] = false;
                }
            })
        });

        filter = JSON.stringify(filter);
        var data = {};
        if (!dt.lazy) {
            if (dt.hasFilter()) {
                data["data"] = dt.filteredValue.slice(0, parseInt(dt.rows) + parseInt(dt.first))
            }
            else {
                dt.totalRecords = dt.value.length;
                data["data"] = dt.value.slice(0, parseInt(dt.totalRecords) + parseInt(dt.first));
            }
        }
        else {
            if (dt.first == 0) {//to add changes made on first page
                this.visitedPagesData[dtIndex] = this.primeNgTableArray[dtIndex];
            }
            this.visitedPagesData[dtIndex] = this.getMergedArray(this.visitedPagesData[dtIndex], dt.value,this.Module[0].moduleList[index].PrimaryKey);
            data["data"] = this.visitedPagesData[dtIndex];
        }

        let FilterData = {};
        if (filter1 != null && filter1.trim() !== "") { FilterData = JSON.parse(filter1) };
        FilterData["AppId"] = this.CurrentApp.ID;

        filter1 = JSON.stringify(FilterData);

        data["filter"] = filter1;

        this.mainpageservice.SavePrimeNgTable1(ID, this.Sys_Menu_ID, data, this.UserName,appId).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            if (resp["Message"] == "Record saved successfully!!") {
                this.getPageMenuDetails();
            }
            toast.then(toast => toast.present());
            this.show = false;
        });
    }


    //To submit Form
    onSubmit(form: any, ID: any, LinkedMenu: any, moduleIndex): void {
        let formObject: any = form;
        form = form.value;
        let appId: any = null;
        if (this.CurrentApp != null || this.CurrentApp != undefined) appId = this.CurrentApp.ID;
        let filter1 = localStorage.getItem('navigationExtras');


        let FilterData = {};
        if (filter1 != null && filter1.trim() !== "") { FilterData = JSON.parse(filter1) };
        FilterData["AppId"] = this.CurrentApp.ID;

        filter1 = JSON.stringify(FilterData);


        var moduledetails = this.Module[0].moduleList[moduleIndex].moduleDetails;
        Object.keys(form).forEach(key => {
            var v = moduledetails.filter(md => md.ColumnName == key);
            //if (v[0].DataType == "month") {
            //    if (form[key] != null) {
            //        let adate = new Date(form[key]);
            //        var ayear = adate.getFullYear();
            //        var amonth: any = adate.getMonth() + 1;
            //        if (amonth < 10) { amonth = '0' + amonth; }
            //        form[key] = amonth + "/" + ayear;
            //    }
            //}
            //else 
            if (v[0].DataType == "date") {
                if (form[key] != null) {
                    let adate = new Date(form[key]);
                    var ayear = adate.getFullYear();
                    var amonth: any = adate.getMonth() + 1;
                    var adt: any = adate.getDate();
                    if (adt < 10) { adt = '0' + adt; }
                    if (amonth < 10) { amonth = '0' + amonth; }
                    form[key] = ayear + '-' + amonth + '-' + adt;
                }
            }
            if (v[0].InputControls == "Checkbox") {
                if (form[key] == null || form[key] == "") {
                    form[key] == false;
                }
            }
            if (v[0].InputControls == "Switch") {
                if (form[key] == null || form[key] == "") {
                    form[key] == false;
                }
            }
        })
        this.routerEventSubscription = this.router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)
        ).subscribe(() => {//refresh module on reload/navigation like back or submenu click
            this.GetModulewithFilter(filter1);
        });
        const that = this;
        let Mess = undefined;
        this.UserName = localStorage.getItem('username');
        this.show = true;

        Object.keys(form).map(function (key, index) {//with file upload
            if (Array.isArray(form[key]))// convert file object to file name string
            {
                if (form[key][0] instanceof File) {
                    var fileNames = "";

                    form[key].forEach(file => {
                        fileNames = file["name"] + "," + fileNames;
                    });
                    form[key] = fileNames.substring(0, fileNames.length - 1);
                }
            }
        });


        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, this.UserName, appId, filter1).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            if (LinkedMenu != 0 && LinkedMenu != '' && LinkedMenu != null) {
                this.navigateOnFormSubmit(LinkedMenu);
            }

            toast.then(toast => toast.present());
            this.show = false;
            if (resp["Message"] == "Record saved successfully!!") {
                //this.GetModulewithFilter(filter1);
                this.getPageMenuDetails();
                let module = this.Module[0].moduleList.find(m => m.ID == ID)//if module is for create new User 
                if (module.ModuleName.toLowerCase() == "create new user")
                    this.createACSUserId(form.UserName);//assigns ACS userid to newly created user

                //this.handleFileUploadCancel();
                formObject.reset();
            }

        });


    }
    GetFileSelected(files: Array<File>) {
        debugger;
        this.FileList = files;

    }
    //For Saving The FeedBack of the ccustomers
    onSubmitFeedback(form: any): void {

        const that = this;

        let Mess = undefined;

        this.UserName = localStorage.getItem('username');

        this.show = true;

        //Form Without FileUpload

        this.mainpageservice.SaveFeedback(this.Sys_Menu_ID, form, this.UserName).subscribe(resp => {

            //console.log(JSON.stringify(resp["Message"]));

            let toast = this.toastController.create({

                message: resp["Message"],

                duration: 3000,

                position: 'bottom',

                //closeButtonText: 'Ok',

            });

            toast.then(toast => toast.present());

            this.show = false;

            this.mainpageservice.GetFeedback(this.Sys_Menu_ID, this.UserName, undefined).subscribe(data => {

                //console.log("Moduleapi", data);

                this.Feedback = data;

                this.show = false;



            });

        });

    }

    Savedatatable(form: any, ID: any): void {

        const that = this;
        let Mess = undefined;
        let appId: any = null;
        if (this.CurrentApp != null || this.CurrentApp != undefined) appId = this.CurrentApp.ID;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let filter = localStorage.getItem('navigationExtras');

        //Form Without FileUpload
        this.mainpageservice.SaveDatatable(ID, form, this.UserName, appId).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            this.GetModulewithFilter(filter);
            toast.then(toast => toast.present());
            this.show = false;

        });

    }

    onSubmitFilter(formdata: any): void {

        this.displayedColumnArray = [];

        this.appliedFilters = formdata;

        Object.keys(formdata).forEach(key => {
            var v = this.PageMenuDetails.filter(md => md.ParameterName == key);
            if (v[0].DataType == "month") {
                if (formdata[key] != null) {
                    let adate = new Date(formdata[key]);
                    if (adate != undefined && adate.toString() != "Invalid Date") {
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        if (amonth < 10) { amonth = '0' + amonth; }
                        formdata[key] = amonth + "/" + ayear;
                    }
                }
            }
            else if (v[0].DataType == "date") {
                if (formdata[key] != null) {
                    let adate = new Date(formdata[key]);
                    if (adate != undefined && adate.toString() != "Invalid Date") {
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        formdata[key] = ayear + '-' + amonth + '-' + adt;
                    }
                }
            }
            else if (v[0].DataType == "datetime") {
                if (formdata[key] != null) {
                    let adate = new Date(formdata[key]);
                    if (adate != undefined && adate.toString() != "Invalid Date") {
                        var ayear = adate.getFullYear();
                        var amonth: any = adate.getMonth() + 1;
                        var adt: any = adate.getDate();
                        var hour: any = adate.getHours();
                        var min: any = adate.getMinutes();
                        var sec: any = adate.getSeconds();
                        if (adt < 10) { adt = '0' + adt; }
                        if (amonth < 10) { amonth = '0' + amonth; }
                        formdata[key] = ayear + '-' + amonth + '-' + adt + ' ' + hour + ':' + min + ':' + sec;
                    }
                }
            }
        })
        this.filterValues = []

        Object.values(formdata).forEach((item) => {
            if (Array.isArray(item)) {
                item.forEach(elm => {
                    this.filterValues.push(elm.Text)
                })
            }
            else if (item !== null && typeof item == 'object' && item.hasOwnProperty('Text')) {
                this.filterValues.push(item['Text'])
            }
            else if (typeof item == 'string' && item !== '') {
                let pattern = /(0[1-9]|10|11|12)\/[0-9]{4}/;
                if (pattern.test(item)) { //for the date and month filter
                    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                    let monthInFilter = months[Number(item.slice(-7, -5)) - 1];
                    // console.log(month3 + " " + item.slice(-4),'month3')
                    this.filterValues.push(monthInFilter + " " + item.slice(-4))
                }
                else {
                    this.filterValues.push(item)
                }
            }
        })

        console.log('you submitted value in Filter:', formdata);
        let data: any;
        data = JSON.stringify(formdata);
        //data = '{"Filter":' + data + '}';
        //console.log(data);
        this.UserName = localStorage.getItem('username');
        localStorage.setItem('filterdata', data);
        this.datatableparam = sessionStorage.getItem('datatableparameter');
        //console.log(this.datatableparam);
        this.GetModulewithFilter(formdata);
        this.showfilter = false;
        if (this.showfilter == false && this.horizontalFilter == false) {
            this.showfilter = true;
        }

    }

    //removing the filter values from chips
    remove(i) {
        //console.log(this.appliedFilters, 'in remove before loop')
        //console.log(this.PageMenuDetails, 'PageMenuDetails before remove loop')
        // let filterData = localStorage.getItem("filterdata");
        // this.appliedFilters = filterData;
        //console.log(this.appliedFilters, 'in remove before loop after local storage')
        Object.keys(this.appliedFilters).forEach(item => {
            if (typeof this.appliedFilters[item] === 'object' &&
                !Array.isArray(this.appliedFilters[item]) &&
                this.appliedFilters[item] !== null && this.appliedFilters[item]['Text'] == i) {
                console.log(item)
                this.PageMenuDetails.filter(elem => elem.ParameterName === item)[0].DefaultValue = ''
                // elem.DefaultValue.Text === i)[0].DefaultValue = ''
                this.appliedFilters[item] = ''
            }
            else if (Array.isArray(this.appliedFilters[item])) {
                this.appliedFilters[item].forEach((elm, index) => {
                    if ((elm['Text'] == i)) {
                        this.PageMenuDetails.find(elem => elem.ParameterName === item).DefaultValue.splice(index, 1)
                    }
                })
            }
            else if (typeof this.appliedFilters[item] == 'string' && this.appliedFilters[item] == i) {

                this.PageMenuDetails.find(elem => elem.ParameterName === item).DefaultValue = ''
                this.appliedFilters[item] = ''
            }
        })
        //console.log(this.filterValues, 'remove')
        //console.log(this.PageMenuDetails, 'this is a pageMenuDetails in remove')
        //console.log(this.appliedFilters, 'this.appliedFilters in remove')
    }
//end of removing the filter


    downloadreport(): void {
        //alert(menuid);
        this.show = true;

        let filter: any;
        this.UserName = localStorage.getItem('username');
        filter = localStorage.getItem('filterdata');
        this.mainpageservice.exporttoexcel(this.Sys_Menu_ID, this.UserName, filter).subscribe(resp => {
            //console.log(resp);

            //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            var blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var filename = 'download_report.xlsx';

            saveAs(blob, filename);
            this.show = false;
        })
        

    }

    downloadModuleReport(moduleId,moduleName) {
        debugger;
        this.show = true;

        let filters: any;
        let data: any = {};
        this.UserName = localStorage.getItem('username');
        let filter = localStorage.getItem('navigationExtras');
        var param = {};
        if (filter != "" && filter != undefined && filter != null) {
            param = JSON.parse(filter);
        }

        filters = localStorage.getItem('filterdata');
        if (filters == null) data.Filter = {};
        else {
            data["Filter"] = JSON.parse(filters);
        }
        if (filter != null && filter != undefined && filter != "")
            data.Filter["rowval"] = param["rowval"];
        debugger;

        this.mainpageservice.exporttoexcelModulewise(moduleId, this.UserName, JSON.stringify(data), this.CurrentApp.ID).subscribe(resp => {
            //console.log(resp);

            //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
            var blob = new Blob([resp], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            var filename = moduleName + '.xlsx';

            saveAs(blob, filename);

        })
        this.show = false;
    }


    navigate(i, LinkedMenu, FilterName?: string, rowIndex?: number) {
        if (rowIndex > -1) { this.highlightcard = rowIndex; }
        let qm = {};
        if (FilterName == null) {
            qm = { "rowval": i };
        }
        else {
            qm[FilterName] = {};
            qm[FilterName].Text = i;
        }
        let navigationExtras: NavigationExtras =
            { queryParams: qm };
        localStorage.setItem('navigationExtras', JSON.stringify(qm));
        this.events.publish('navigationExtras', qm);
        if (LinkedMenu != 0) this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);
        // this.router.navigate(["/menu/first/tabs/GotoForm"], navigationExtras);
    }

    ngAfterViewInit(): void {
        // this.slides.lockSwipes(true);
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.routerEventSubscription.unsubscribe();
        this.events.destroy('navigationExtras');
    }

    ionViewDidLeave(): void {
        //localStorage.removeItem("navigationExtras");

        // Do not forget to unsubscribe the event
        this.routerEventSubscription.unsubscribe();
        this.events.destroy('navigationExtras');
    }
    onWizardSubmit(ID: any): void {
        debugger;
        this.UserName = localStorage.getItem('username');
        this.show = true;
        let form = JSON.stringify(this.WizardFormValue)
        //Form Without FileUpload
        this.mainpageservice.Savedata(ID, form, null).subscribe(resp => {
            //console.log(JSON.stringify(resp["Message"]));
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        });

    }


    /////////////////Convert Image to blob////////////////////


    private base64textString: String = "";

    doAsyncConfirmAppend(files: any, columnName, tableName, isAppend: boolean) {

        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.confirmationService.confirm({
                    message: 'Do you want to append data?',
                    header: 'Append or Truncate',
                    accept: () => {
                        isAppend = true;
                        this.uploadFileAppend(files, columnName, tableName, isAppend);
                    },
                    reject: () => {
                        isAppend = false;
                        this.uploadFileAppend(files, columnName, tableName, isAppend);
                    },
                    closeOnEscape: false,

                });
            }, 1000);
        });
        return promise;

    }

    uploadFileAppend(files: any, columnName, tableName, isAppend) {
        this.UserName = localStorage.getItem('username');

        for (let file of files) {
            this.mainpageservice.uploadFile(file, this.UserName, columnName, tableName, isAppend).subscribe(resp => {
                this.show = false;
                debugger;
                if (resp["Message"].toLowerCase().includes("successfully")) {
                    this.workflow.CreateOrUpdateDataset(tableName, this.UserName);
                }

                //To display loader
                let toast = this.toastController.create({
                    message: resp["Message"],
                    duration: 3000,
                    position: 'bottom',
                    //closeButtonText: 'Ok'
                });
                toast.then(toast => toast.present());
            });

        }
        //  this.show = true;
    }

    handleFileInput(files: any, columnName, tableName, updatePlanDataset?: boolean) {
        this.UserName = localStorage.getItem('username');
        let isAppend: boolean = false;
        if (updatePlanDataset) {
            isAppend = true;
        }
        if (isAppend) {
            this.doAsyncConfirmAppend(files, columnName, tableName, isAppend);
        }
        else {
            for (let file of files) {
                this.mainpageservice.uploadFile(file, this.UserName, columnName, tableName, isAppend).subscribe(resp => {
                    this.show = false;
                    //To display loader
                    let toast = this.toastController.create({
                        message: resp["Message"],
                        duration: 3000,
                        position: 'bottom',
                        //closeButtonText: 'Ok'
                    });
                    toast.then(toast => toast.present());
                });

            }
            this.show = true;
        }




    }

    _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        this.id = Math.random();
        sessionStorage.setItem('id', this.id);
        this.base64textString = btoa(binaryString);
        //console.log(btoa(binaryString));
        this.storage.set('sampler-' + this.id, btoa(binaryString));
        let FileType: FileObject = {
            name: this.FileName,
            FileData: this.b64toBlob(btoa(binaryString), '', '')
        };
        //console.log(btoa(binaryString));

        this.storage.set('FileType-' + this.id, FileType);
        let toast = this.toastController.create({
            message: "Your image is stored. Click on Submit to save the form.",
            duration: 3000,
            position: 'bottom',
            //showCloseButton: true,
            //closeButtonText: 'Ok',

        });
        toast.then(toast => toast.present());


        //console.log('blob data while passing to savedata1' + JSON.stringify(FileType));


    }

    replacer(key, val) {
        if (key == "_parent" || key == "parent") return undefined;
        else return val;

    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                let data: any
                this.UserName = localStorage.getItem('username');
                data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


                this.mainpageservice.GenerateReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

                    //  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                    var blob = new Blob([resp], { type: 'application/pdf' });
                    var d = new Date();
                    var montharr = d.getMonth() + 1;
                    var month = (montharr < 10 ? '0' : '') + montharr;
                    var day = ((d.getDate()) < 10 ? '0' : '') + (d.getDate());
                    var year = d.getFullYear();
                    var x = year + '-' + month + '-' + day;
                    var filename = ReportType + x + '.pdf';

                    saveAs(blob, filename);
                    this.show = false;
                });
                resolve();
            }, 1000);
        });

        debugger;
        return promise;
    }

    GenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        if (Rowval == '' || typeof (Rowval) === undefined || Rowval == null) {
            let filter = localStorage.getItem("navigationExtras");
            let param = JSON.parse(filter);
            Rowval = param["rowval"];
        }


        this.doAsyncGenerateReport(Rowval, ModuleId, ModuleDetailId, ReportType).then(
            () => this.show = true,
            // () => //console.log("Task Errored!"),
        );

    }
    SendReport(Rowval, ModuleId, ModuleDetailId, ReportType) {
        let data: any
        this.UserName = localStorage.getItem('username');
        data = '{Rowval:"' + Rowval + '",' + 'ModuleDetailId:"' + ModuleDetailId + '",ModuleId:"' + ModuleId + '"}';


        this.mainpageservice.SendReport(ReportType, this.UserName, JSON.stringify(data)).subscribe(resp => {

            let toast = this.toastController.create({
                message: "Mail sent successfully!",
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;

        })
    }
    toggleOTPEmail(email: any) {
        this.VerifiedEmailId = email;
        this.showContainerEmail = true;
        this.mainpageservice.GenerateOTPEmail(email).subscribe(resp => {
            alert(JSON.stringify(resp));
            let OTP = resp["OTP"];
            localStorage.setItem('OTP', OTP);

        });
    }
    CheckOTP(OTP: any) {
        let OTPValue = localStorage.getItem('OTP');
        if (OTP == OTPValue) {
            alert("Email validated");
            localStorage.removeItem('OTP');
            this.showContainerEmail = false;
            this.ValidatedEmail = true;
        }
        else {
            alert("OTP not valid");
        }
    }

    setStep(index: number) {
        this.step = index;
    }

    nextStep() {
        this.step++;
    }

    prevStep() {
        this.step--;
    }

    multipleDependantDropdown(moduleDetailId, value, dependantSP,moduleIndex,subModuleIndex) {
        var dd = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.filter(md => md.DependentOn == moduleDetailId);
        console.log(dd);
        this.mainpageservice.MultipleDependentDropdown(moduleDetailId, value, dependantSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var i = 0;
            dd.forEach(md => {
                var index = "";
                var dependentDropdown = this.Module[0].moduleList[moduleIndex].submodule[0].subModuleDetails.find(smd => smd.ID == md.ID);
                if (i != 0)
                    index = "" + i;
                dependentDropdown.DropDownValues = resp["Table" + index];
                i++;
            });
        })
    }


    autoPopulateMultipleFields(moduleDetailId, filterByValue, auotoPopulateSP,moduleIndex, subModuleIndex) {
        //For FormTableSubmodule only
        var dd = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.filter(md => md.DependentOn == moduleDetailId);
        if (this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].DisplayType == "Form") {
            var tableSubmoduleIndex = this.Module[0].moduleList[moduleIndex].submodule.findIndex(sm => sm.DisplayType == "PrimeUnfrozenTable");
            var tableDropdown = this.Module[0].moduleList[moduleIndex].submodule[tableSubmoduleIndex].subModuleDetails.filter(md => md.InputControls.toLowerCase() == "dropdownlist")
            tableDropdown.forEach(element => {
                this.GetValuesForDependentDropdown(element.ID, filterByValue, moduleIndex, subModuleIndex);//Form to table dependent dropdown
            });
        }
        this.mainpageservice.AutoPopulateMultipleFields(filterByValue.Text, auotoPopulateSP, this.UserName).subscribe(resp => {
            console.log(resp);
            var value = "";
            dd.forEach(md => {
                var fieldToBePopulated = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.find(smd => smd.ID == md.ID);
                if (fieldToBePopulated.InputControls.toLowerCase() == "dropdownlist")
                    value = fieldToBePopulated.DropDownValues.find(o => o.Text == resp[0][md.ColumnName]);
                else ///other input controls
                    value = resp[0][md.ColumnName];

                if (this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].DisplayType != "Form")
                    this.thisrow[md.ColumnName] = value;//for dialog box fields
                else
                    this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.find(f => f.ColumnName == md.ColumnName).value = value;//for Form fields

                this.disable = true;//to disable auto populated fields
            });
        })
    }


    setImportPanel(importPanel) {//To show file upload modulewise on Upload button click
        importPanel.hidden = !importPanel.hidden;
    }

     async Run(i) {
        let qm = { "rowval": i };
        this.UserName = localStorage.getItem('username');
        this.show = true;

        this.mainpageservice.RunWorkflowTask(i, this.UserName, undefined).subscribe(async resp => {

            console.log(JSON.stringify(resp["Message"]));
            //To display loader
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;
            await this.events.publish('Notification');
            this.events.publish('PopUp');
        });

    }

    GetValuesForDependentDropdown(DDModuleDetailID, filterByValue, moduleIndex, subModuleIndex?: number) {
        if (subModuleIndex != null && subModuleIndex != undefined) {
            var dd = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.filter(md => md.ID == DDModuleDetailID);
            console.log(dd);
            this.mainpageservice.MultipleDependentDropdown(DDModuleDetailID, filterByValue.Text, dd[0].DropDownSP, this.UserName).subscribe(resp => {
                console.log(resp);
                var i = 0;
                dd.forEach(md => {
                    var index = "";
                    var dependentDropdown = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.find(smd => smd.ID == md.ID);
                    if (i != 0)
                        index = "" + i;
                    dependentDropdown.DropDownValues = resp["Table"];
                    i++;
                });
            })
        }
    }

    GetDependentDropDownFormtable(mdID, event, dependentDropdown, moduleIndex, subModuleIndex) {
        debugger;
        let moduledetail = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.find(md => md.ID == parseInt(mdID));
        let submoduleDetailIndex = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.indexOf(moduledetail);
        let dependentDropdowns = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.filter(md => md.DependentOn == moduledetail.ID);
        let isPresentInDependentOn = dependentDropdowns.length > 0 ? true : false;

        if (isPresentInDependentOn) {
            this.filterDependentDropdownvalues(moduleIndex, subModuleIndex, submoduleDetailIndex);
        }
        else {
            var selectedValue = event.value.Value == undefined ? event.value.Value : event.value.Value;
            var filterByValue = $.trim(selectedValue);

            this.mainpageservice.GetDependentDropDownFormtable(mdID, filterByValue).subscribe(response => {
                var moduledetail = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.find(md => md.ColumnName == dependentDropdown);
                moduledetail.DropDownValues = <any>(response)["Table"];
            }, err => {
            });
        }

    }


    filterDependentDropdownvalues(moduleIndex, subModuleIndex, submoduleDetailIndex) {
        debugger;
        let currentModuleDetail = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails[submoduleDetailIndex];

        //to get all dependent dropdowns
        let dependentDropdowns = this.Module[0].moduleList[moduleIndex].submodule[subModuleIndex].subModuleDetails.filter(md => md.DependentOn == currentModuleDetail.ID);
        let filterByList = [];

        if (currentModuleDetail.DependentOn != 0 && currentModuleDetail.DependentOn != null) {
            filterByList = this.findDependentOnModuleDetail(currentModuleDetail, []);//check all dependent fields recursively

            dependentDropdowns.forEach(dropdown => {
                this.mainpageservice.FilterDropdownValues(filterByList, dropdown.DropDownSP, this.UserName).subscribe(response => {
                    dropdown.DropDownValues = response;
                }, err => {
                });
            });
        }

    }


    findDependentOnModuleDetail(moduleDetail, filterByList) {//recursively gets all the field with its value and returns the array
        /* e.g.
         State->District->Tehsil->City
         then Parameters for District will be State, Parameters for Tehsil will be State and District, Paramters for City will be State,District and Tehsil.
         */
        let currentModuleDetail = moduleDetail;

        let filterByValue = this.thisrow[currentModuleDetail.ColumnName];
        let filterByKey = currentModuleDetail.ColumnName;

        let filterBy = {};
        filterBy["key"] = filterByKey;

        if (typeof filterByValue == "string")//for non dropdown fields
            filterBy["value"] = filterByValue
        else if (filterByValue.hasOwnProperty("Text"))//for dropdown
            filterBy["value"] = filterByValue.Text;

        filterByList.push(filterBy);
        currentModuleDetail = this.Module[0].moduleList[0].submodule[1].subModuleDetails.find(md => md.ID == currentModuleDetail.DependentOn);

        if (currentModuleDetail != undefined)
            this.findDependentOnModuleDetail(currentModuleDetail, filterByList);

        return filterByList;
    }

    TimeStamp(value) {
        let todaysDate = new Date()
        let NotifDate = new Date(value)
        let timeStamp = todaysDate.getTime() - NotifDate.getTime()
        let todaysMonth = todaysDate.getMonth()
        let NotifMonth = NotifDate.getMonth()
        let monthDiff = Math.abs(NotifMonth - todaysMonth)
        let cd = 24 * 60 * 60 * 1000;
        let ch = 60 * 60 * 1000;
        let cm = 60 * 1000
        let d = Math.floor(timeStamp / cd);
        let h = Math.floor((timeStamp - d * cd) / ch);
        let m = Math.floor((timeStamp - d * cd - h * ch) / 60000);
        //   let pad = function(n){ return n < 10 ? '0' + n : n; };
        if (m === 60) {
            h++;
            m = 0;
        }
        if (h === 24) {
            d++;
            h = 0;
        }
        return ((m == 0) ? 'few sec ago'
            : (d == 0 && h == 0) ? m + ' min ago'
                : (d == 0 && h != 0) ? h + ' hours ago'
                    : (monthDiff == 0 && todaysDate.getFullYear() == NotifDate.getFullYear()) ? d + ' days ago'
                        : (d > 1 && todaysDate.getFullYear() == NotifDate.getFullYear()) ? monthDiff + ' months ago'
                            : value.slice(0, 10));
    }


    SubmitTreeTable(ID: any) {
        this.show = true;
        var filter1 = localStorage.getItem('navigationExtras');
        let appId: any = null;
        if (this.CurrentApp != null || this.CurrentApp != undefined) appId = this.CurrentApp.ID;
        var updateData = this.files.filter(element => element.data.Updated == true)
        console.log(updateData);
        var dataObject = JSON.stringify(updateData, this.replacer);
        let treetableModuleIndex: number;
        var treetableModule = this.Module[0].moduleList.find(m => m.DisplayType.toLowerCase() == "treetable");
        treetableModuleIndex = this.Module[0].moduleList.indexOf(treetableModule);

        this.mainpageservice.SaveTreetable(ID, dataObject, this.UserName, treetableModuleIndex,appId).subscribe(resp => {
            let toast = this.toastController.create({
                message: resp["Message"],
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
            this.show = false;
            updateData.forEach((node) => {
                node.data.Updated = false;
            });
        });
    }

    treetable(filter) {
        let treeNodes: Array<FileSystemNode> = [];
        treeNodes = filter.serializedValue;
        var filteredNodes = treeNodes.filter(x => x.parent != null)

        filteredNodes.forEach(treenode => {

            let node = new FileSystemNode(null, null, this.scrollableCols, treenode);

        });
    }

    /////RowGroup highlight
    updateRowGroupMetaData(data,groupByColumnName, sortColumnName) {
        //alert();
        this.rowGroupMetadata = {};
        if (data) {
            let groupIndex = 1;
            for (let i = 0; i < data.length; i++) {
                let rowData = data[i];
                let sortColumnValue = rowData[sortColumnName];
                let groupbyColumnValue = rowData[groupByColumnName];
                //this.rowGroupMetadata[sortColumnValue]={};
                if (i == 0) {
                    this.rowGroupMetadata[groupbyColumnValue] = {};
                    this.rowGroupMetadata[groupbyColumnValue][sortColumnValue] = { index: 0, size: 1, GroupIndex: groupIndex };
                }
                else {
                    let previousRowData = data[i - 1];
                    let previousRowGroup = previousRowData[groupByColumnName];
                    if (groupbyColumnValue === previousRowGroup) {
                        if (rowData[sortColumnName] == previousRowData[sortColumnName]) {
                            this.rowGroupMetadata[groupbyColumnValue][sortColumnValue].size++;
                        }
                        else {
                            groupIndex++;
                            this.rowGroupMetadata[groupbyColumnValue][sortColumnValue] = { index: i, size: 1, GroupIndex: groupIndex };
                        }
                    }
                    else {
                        groupIndex++;
                        this.rowGroupMetadata[groupbyColumnValue] = {};
                        this.rowGroupMetadata[groupbyColumnValue][sortColumnValue] = { index: i, size: 1, GroupIndex: groupIndex };
                        //
                    }
                }
                // 
            }
            // console.log(this.rowGroupMetadata);
        }

    }


    updateRowGroupMetaDataSingle(data, groupByColumnName) {//table with row grouping
        this.rowGroupMetadata = {};
        debugger;
        if (data) {
            for (let i = 0; i < data.length; i++) {
                let rowData = data[i];
                let groupByColumnName_value = rowData[groupByColumnName];

                if (i == 0) {
                    this.rowGroupMetadata[groupByColumnName_value] = { index: 0, size: 1 };
                }
                else {
                    let previousRowData = data[i - 1];
                    let previousRowGroup = previousRowData[groupByColumnName];
                    if (groupByColumnName_value === previousRowGroup)
                        this.rowGroupMetadata[groupByColumnName_value].size++;
                    else
                        this.rowGroupMetadata[groupByColumnName_value] = { index: i, size: 1 };
                }
            }
        }
        console.log("rowGroupMetadata", this.rowGroupMetadata);
    }

    isEven(groupIndex) {
        if (groupIndex % 2 == 0)
            return { "background-color": "#F4F4F4", };
        else
            return { "background-color": "white" };
    }

    onSort(data, groupByColumnName, sortColumnName) {
        if (groupByColumnName == sortColumnName)
            this.updateRowGroupMetaDataSingle(data, groupByColumnName);
        else
            this.updateRowGroupMetaData(data, groupByColumnName, sortColumnName);
    }

    onContextMenuSelect(tableIndex) {
        this.contextMenu_tableIndex = tableIndex;
    }

    mapContextMenuCommand(RowContextMenus, PrimaryKey) {
        let dt_count: number;

        RowContextMenus.forEach(contextMenu => {
            console.log("contextMenu", contextMenu)
            var command = contextMenu.command;
            console.log(this);
            contextMenu.command = (onclick) => {

                if (command.toString().toLowerCase() == "deleterow") {
                    alert(command.toString());
                }
                else if (command.toString().toLowerCase() == "copyrow") {
                    alert(command.toString());
                }
                else if (command.toString().toLowerCase() == "addrow") {
                    alert(command.toString());
                }

                //Sudarshan - Cell Context Menu
                else if (contextMenu.label.toString().toLowerCase() == "view details") {
                    this.onRowView(this.RowData,this.ModuleIndex,this.DT_Count);
                }
                else if (contextMenu.label.toString().toLowerCase() == "edit") {
                    this.onRowSelectInCrudTable(this.RowData, '', '', true, this.ModuleIndex, this.DT_Count);
                }
                else if (contextMenu.label.toString().toLowerCase() == "delete") {
                    this.deleteRecordFromCrudTable(this.DeleteTableName, this.DeleteColumnName, this.RowData);
                }
                    //XXXXXXXXXXXXXXXXXXXXXXXXXXXXX

                else if (command.toString().toLowerCase() == "navigate") {
                    this.cellContextMenu.hide();
                    
                        setTimeout(() => {
                            this.navigate(this.selectedCellValue, contextMenu.LinkMenuId, this.selectedCellHeader);
                            //this.navigate(this.selectedrow[PrimaryKey], contextMenu.LinkMenuId);
                        }, 500);
                    

                }
            }
        });
    }

    loadTableData(event: LazyLoadEvent, dtIndex, moduleIndex) {
        this.loading = true;
        var that = this;
        setTimeout(() => {
            if (event.first == this.Module[0].moduleList[moduleIndex].Rows) {//to add changes made on first page
                that.visitedPagesData[dtIndex] = that.primeNgTableArray[dtIndex];
            }
            if (this.Module[0].moduleList[moduleIndex].moduleData) {
                if (this.primeNgTableArray[dtIndex].length > 0 &&
                    this.primeNgTableArray[dtIndex] != undefined &&
                    this.primeNgTableArray[dtIndex] != null) {
                    this.Module[0].moduleList[moduleIndex].moduleData = this.getMergedArray(this.Module[0].moduleList[moduleIndex].moduleData, this.primeNgTableArray[dtIndex], this.Module[0].moduleList[moduleIndex].PrimaryKey)
                }
                let data: any;
                if (Object.keys(event.filters).length > 0)//when column filters applied
                {
                    if (!event.filters.hasOwnProperty("global")) {//if filter doesn't have global filters
                        let filters = Object.keys(event.filters).map(function (fieldName) {
                            if (fieldName != "global") {//to exclude global filter from column filters
                                let filter: any = {};
                                filter = event.filters[fieldName];
                                filter.key = fieldName;
                                return filter;
                            }
                        });

                        let dataToFilter = this.Module[0].moduleList[moduleIndex].moduleData;
                        filters.forEach(filter => {
                            if (filter.length != 1) {
                                dataToFilter = this.columnFilter(dataToFilter, filter);
                            }
                            else if (filter.length == 1 && filter[0].value != null) {
                                dataToFilter = this.columnFilter(dataToFilter, filter);
                            }
                            
                        });
                        this.totalRecords[dtIndex] = dataToFilter.length;
                        data = dataToFilter.slice(event.first, (event.first + event.rows));
                    }
                    else if (event.filters.hasOwnProperty("global")) {//for global filter
                        let dataToFilter = this.Module[0].moduleList[moduleIndex].moduleData;
                        dataToFilter = this.globalFilter(this.filterColsArray[dtIndex], dataToFilter, event.filters.global.value)
                        this.totalRecords[dtIndex] = dataToFilter.length;
                        data = dataToFilter.slice(event.first, (event.first + event.rows));
                    }
                }

                else {//without column filters or global filter
                    data = that.Module[0].moduleList[moduleIndex].moduleData.slice(event.first, (event.first + event.rows));
                    that.totalRecords[dtIndex] = that.Module[0].moduleList[moduleIndex].moduleData.length;
                }
                that.primeNgTableArray[dtIndex] = data;
                let dataArray = Object.keys(data).map(function (index) {//convert Object to array
                    let row = data[index];
                    return row;
                });

                let arr1 = that.visitedPagesData[dtIndex];
                that.visitedPagesData[dtIndex] = this.getMergedArray(arr1, dataArray, this.Module[0].moduleList[moduleIndex].PrimaryKey);

                this.loading = false;
            }
        }, 100);
    }

    getMergedArray(arr1, arr2, PrimaryKey) {
        const map = new Map();
        if (arr1 != undefined && arr2 != undefined) {
            if (PrimaryKey != "" && PrimaryKey != null) {
                if (arr1 != 0) {
                    arr1.forEach(item => map.set(item[PrimaryKey], item));
                }
                if (arr2 != null) {
                    arr2.forEach(item => map.set(item[PrimaryKey], { ...map.get(item[PrimaryKey]), ...item }));
                }
            }
            else {
                arr1.forEach(item => map.set(item.ID, item));
                arr2.forEach(item => map.set(item.ID, { ...map.get(item.ID), ...item }));
            }

            const mergedArr = Array.from(map.values());
            return mergedArr;
        }
        else
            return 0;
    }


    //columnFilter(data, filterObject)//column filter for lazy loading
    //{
    //    return data.filter(filterColumnwiseData);

    //    function filterColumnwiseData(data) { //callback function
    //        if (typeof data[filterObject.key] == 'string')
    //            return data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase());
    //        else if (data[filterObject.key]!=null)
    //            return data[filterObject.key].toString().includes(filterObject[0].value.toString());
    //    }
    //}
    columnFilter(data, filterObject)//column filter for lazy loading
    {
        debugger;
        if (filterObject.length != 1) {
            return data.filter(filterColumnwiseData);
        }
        else if (filterObject[0].matchMode == 'notEquals') {
            return data.filter(filternotEqualsColumnwiseData)
        }
        else if (filterObject[0].matchMode == 'notContains') {
            return data.filter(filternotContainsColumnwiseData)
        }
        else if (filterObject[0].matchMode == 'equals' && filterObject[0].operator == 'and') {
            return data.filter(filterEqualsColumnWiseData)
        }
        else if (filterObject[0].matchMode == 'startsWith') {
            return data.filter(filterStartWithColumnwiseData)
        }
        else if (filterObject[0].matchMode == 'endsWith') {
            return data.filter(filterEndsWithColumnwiseData)
        }
        else if (filterObject[0].matchMode == 'lt' || filterObject[0].matchMode == 'lte') {
            return data.filter(filterLessThanColumWiseData)
        }
        else if (filterObject[0].matchMode == 'gt' || filterObject[0].matchMode == 'gte') {
            return data.filter(filterGreaterThanColumWiseData)
        }
        else if (filterObject[0].matchMode == 'dateIs' || filterObject[0].matchMode == 'dateIsNot' || filterObject[0].matchMode == 'dateBefore' || filterObject[0].matchMode == 'dateAfter') {
            return data.filter(filterDateisColumWiseData)
        }
        else {
            return data.filter(filterColumnwiseData);
        }
        function filternotContainsColumnwiseData(data) { //callback function
            if (typeof data[filterObject.key] == 'string') {
                if (filterObject.length == 1) {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'contains') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
                else if (filterObject[1].matchMode == 'startsWith') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        new RegExp("^" + filterObject[1].value.toLowerCase()).test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'endsWith') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        new RegExp(filterObject[1].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        data[filterObject.key].toLowerCase() === (filterObject[1].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'notEquals') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase())
                }
            }
            else if (data[filterObject.key] != null)
                return !data[filterObject.key].toString().includes(filterObject[0].value.toString());
        }
        function filterEqualsColumnWiseData(data) {
            if (typeof filterObject[0].value == 'string') {
                if (filterObject.length == 1) {
                    return data[filterObject.key].toLowerCase() === (filterObject[0].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'contains') {
                    return data[filterObject.key].toLowerCase() === (filterObject[0].value.toLowerCase()) &&
                        data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
                else if (filterObject[1].matchMode == 'startsWith') {
                    return data[filterObject.key].toLowerCase() === (filterObject[0].value.toLowerCase()) &&
                        new RegExp("^" + filterObject[1].value.toLowerCase()).test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'endsWith') {
                    return data[filterObject.key].toLowerCase() === (filterObject[0].value.toLowerCase()) &&
                        new RegExp(filterObject[1].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'notContains' || filterObject[1].matchMode == 'notEquals') {
                    return data[filterObject.key].toLowerCase() === (filterObject[0].value.toLowerCase()) &&
                        !data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
            }
            // return filterObject[0].value.toLowerCase().includes(data[filterObject.key].toLowerCase());
            else if (typeof filterObject[0].value == 'number') {
                if (filterObject.length == 1) {
                    return data[filterObject.key] === filterObject[0].value;
                }
                else if (filterObject[1].matchMode == 'notEquals') {
                    return data[filterObject.key] === filterObject[0].value &&
                        data[filterObject.key] != filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'lt') {
                    return data[filterObject.key] === filterObject[0].value &&
                        data[filterObject.key] < filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'gt') {
                    return data[filterObject.key] === filterObject[0].value &&
                        data[filterObject.key] > filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'lte') {
                    return data[filterObject.key] === filterObject[0].value &&
                        data[filterObject.key] <= filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'gte') {
                    return data[filterObject.key] === filterObject[0].value &&
                        data[filterObject.key] >= filterObject[1].value;
                }
            }
            else if (data[filterObject.key] != null)
                return !data[filterObject.key].toString().includes(filterObject[0].value.toString());
        }
        function filternotEqualsColumnwiseData(data) { //callback function
            if (typeof data[filterObject.key] == 'string') {
                if (filterObject.length == 1) {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'startsWith') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        new RegExp("^" + filterObject[1].value.toLowerCase()).test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'endsWith') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        new RegExp(filterObject[1].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'contains') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        data[filterObject.key].toLowerCase() === (filterObject[1].value.toLowerCase())
                }
                else if (filterObject[1].matchMode == 'notContains') {
                    return !data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        !data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())

                }
            }
            else if (typeof filterObject[0].value == 'number') {
                if (filterObject.length == 1) {
                    return data[filterObject.key] != filterObject[0].value
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return data[filterObject.key] != filterObject[0].value &&
                        data[filterObject.key] === filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'lt') {
                    return data[filterObject.key] != filterObject[0].value &&
                        data[filterObject.key] < filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'gt') {
                    return data[filterObject.key] != filterObject[0].value &&
                        data[filterObject.key] > filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'lte') {
                    return data[filterObject.key] != filterObject[0].value &&
                        data[filterObject.key] <= filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'gte') {
                    return data[filterObject.key] != filterObject[0].value &&
                        data[filterObject.key] >= filterObject[1].value;
                }
            }
            else if (data[filterObject.key] != null) {
                return !data[filterObject.key].toString().includes(filterObject[0].value.toString());
            }
        }
        function filterStartWithColumnwiseData(data) { //callback function
            if (typeof data[filterObject.key] == 'string') {


                if (filterObject.length == 1) {
                    return new RegExp("^" + filterObject[0].value.toLowerCase()).test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'contains') {
                    return new RegExp("^" + filterObject[0].value.toLowerCase()).test(data[filterObject.key].toLowerCase()) &&
                        data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
                else if (filterObject[1].matchMode == 'endsWith') {
                    return new RegExp("^" + filterObject[0].value.toLowerCase()).test(data[filterObject.key].toLowerCase()) &&
                        new RegExp(filterObject[1].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return new RegExp("^" + filterObject[0].value.toLowerCase()).test(data[filterObject.key].toLowerCase()) &&
                        data[filterObject.key].toLowerCase() === (filterObject[1].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'notContains' || filterObject[1].matchMode == 'notEquals') {
                    return new RegExp("^" + filterObject[0].value.toLowerCase()).test(data[filterObject.key].toLowerCase()) &&
                        !data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
                //return data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase());
                //  return filterObject[0].value.toLowerCase().includes(data[filterObject.key].toLowerCase())
            }
            else if (data[filterObject.key] != null)
                // return data[filterObject.key].toString().includes(filterObject[0].value.toString());
                return new RegExp("^" + filterObject[0].value.toLowerCase()).test(data[filterObject.key].toLowerCase())

        }
        function filterEndsWithColumnwiseData(data) {
            if (typeof data[filterObject.key] == 'string') {
                if (filterObject.length == 1) {
                    return new RegExp(filterObject[0].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'contains') {
                    return new RegExp(filterObject[0].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase()) &&
                        data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
                else if (filterObject[1].matchMode == 'startsWith') {
                    return new RegExp(filterObject[0].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase()) &&
                        new RegExp("^" + filterObject[1].value.toLowerCase()).test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return new RegExp(filterObject[0].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase()) &&
                        data[filterObject.key].toLowerCase() === (filterObject[1].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'notContains' || filterObject[1].matchMode == 'notEquals') {
                    return new RegExp(filterObject[0].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase()) &&
                        !data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
            }
            else if (data[filterObject.key] != null) {
                return new RegExp(filterObject[0].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase())
                // return data[filterObject.key].toString().includes(filterObject[0].value.toString());
            }
        }
        function filterLessThanColumWiseData(data) {
            if (typeof data[filterObject.key] == 'number' && filterObject[0].matchMode == 'lt') {
                if (filterObject.length == 1) {
                    return data[filterObject.key] < filterObject[0].value
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return data[filterObject.key] < filterObject[0].value &&
                        data[filterObject.key] === filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'notEquals') {
                    return data[filterObject.key] < filterObject[0].value &&
                        data[filterObject.key] != filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'lte') {
                    return data[filterObject.key] < filterObject[0].value &&
                        data[filterObject.key] <= filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'gt') {
                    return data[filterObject.key] < filterObject[0].value &&
                        data[filterObject.key] > filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'gte') {
                    return data[filterObject.key] < filterObject[0].value &&
                        data[filterObject.key] >= filterObject[1].value
                }
            }
            else if (typeof data[filterObject.key] == 'number' && filterObject[0].matchMode == 'lte') {
                if (filterObject.length == 1) {
                    return data[filterObject.key] <= filterObject[0].value
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return data[filterObject.key] <= filterObject[0].value &&
                        data[filterObject.key] === filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'notEquals') {
                    return data[filterObject.key] <= filterObject[0].value &&
                        data[filterObject.key] != filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'lt') {
                    return data[filterObject.key] <= filterObject[0].value &&
                        data[filterObject.key] < filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'gt') {
                    return data[filterObject.key] <= filterObject[0].value &&
                        data[filterObject.key] > filterObject[1].value
                }
                else if (filterObject[1].matchMode == 'gte') {
                    return data[filterObject.key] <= filterObject[0].value &&
                        data[filterObject.key] >= filterObject[1].value
                }
            }
        }
        function filterGreaterThanColumWiseData(data) {
            if (typeof data[filterObject.key] == 'number' && filterObject[0].matchMode == 'gt') {
                if (filterObject.length == 1) {
                    return data[filterObject.key] > filterObject[0].value;
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return data[filterObject.key] > filterObject[0].value &&
                        data[filterObject.key] === filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'notEquals') {
                    return data[filterObject.key] > filterObject[0].value &&
                        data[filterObject.key] != filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'lt') {
                    return data[filterObject.key] > filterObject[0].value &&
                        data[filterObject.key] < filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'lte') {
                    return data[filterObject.key] > filterObject[0].value &&
                        data[filterObject.key] <= filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'gte') {
                    return data[filterObject.key] > filterObject[0].value &&
                        data[filterObject.key] >= filterObject[1].value;
                }
            }
            else if (typeof data[filterObject.key] == 'number' && filterObject[0].matchMode == 'gte') {
                if (filterObject.length == 1) {
                    return data[filterObject.key] >= filterObject[0].value
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return data[filterObject.key] >= filterObject[0].value &&
                        data[filterObject.key] === filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'notEquals') {
                    return data[filterObject.key] >= filterObject[0].value &&
                        data[filterObject.key] != filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'lt') {
                    return data[filterObject.key] >= filterObject[0].value &&
                        data[filterObject.key] < filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'lte') {
                    return data[filterObject.key] >= filterObject[0].value &&
                        data[filterObject.key] <= filterObject[1].value;
                }
                else if (filterObject[1].matchMode == 'gt') {
                    return data[filterObject.key] >= filterObject[0].value &&
                        data[filterObject.key] > filterObject[1].value;
                }
            }
        }
        function filterDateisColumWiseData(data) {
            var filterDate = filterObject[0].value;
            console.log(filterDate)
            var month = filterDate.getMonth();
            month++;
            if (month < 10) month = "0" + month;
            console.log(month);
            var dateOfMonth = filterDate.getDate();
            if (dateOfMonth < 10) dateOfMonth = "0" + dateOfMonth;
            var getFullYear = filterDate.getFullYear();
            var format = dateOfMonth + "/" + month + "/" + getFullYear;
            console.log("filterdate " + format);

            var tableDate = data[filterObject.key];
            console.log(tableDate);
            var tableMonth = tableDate.getMonth();
            tableMonth++;
            if (tableMonth < 10) tableMonth = "0" + tableMonth;
            var tableMonthDate = tableDate.getDate();
            if (tableMonthDate < 10) tableMonthDate = "0" + tableMonthDate;
            var tableYear = tableDate.getFullYear();
            var dateFormat = tableMonthDate + "/" + tableMonth + "/" + tableYear;
            console.log("tabledate " + dateFormat);

            if (filterObject.length > 1) {
                var filterDate1 = filterObject[1].value;
                console.log(filterDate1);
                var month1 = filterDate1.getMonth();
                month1++;
                if (month1 < 10) month1 = "0" + month1;
                console.log(month1);
                var dateOfMonth1 = filterDate1.getDate();
                if (dateOfMonth1 < 10) dateOfMonth1 = "0" + dateOfMonth1;
                var getFullYear1 = filterDate1.getFullYear();
                var format1 = dateOfMonth1 + "/" + month1 + "/" + getFullYear1;
                console.log("filterdate1 " + format1)
            }

            if (filterObject[0].matchMode == 'dateIs') {

                if (filterObject.length == 1) {
                    return dateFormat === format;
                }
                else if (filterObject[1].matchMode == 'dateIsNot') {

                    return dateFormat === format && dateFormat != format1
                }
                else if (filterObject[1].matchMode == 'dateBefore') {
                    return dateFormat === format || data[filterObject.key].getTime() < filterObject[0].value.getTime();
                }
                else if (filterObject[1].matchMode == 'dateAfter') {
                    return dateFormat === format || data[filterObject.key].getTime() > filterObject[0].value.getTime();
                }
            }
            else if (filterObject[0].matchMode == 'dateIsNot') {
                if (filterObject.length == 1) {
                    // return data[filterObject.key].getDate() != filterObject[0].value.getDate();
                    return dateFormat != format
                }
                else if (filterObject[1].matchMode == 'dateIs') {
                    return dateFormat != format && dateFormat === format1
                }
                else if (filterObject[1].matchMode == 'dateBefore') {
                    return dateFormat != format && data[filterObject.key].getTime() < filterObject[0].value.getTime();
                }
                else if (filterObject[1].matchMode == 'dateAfter') {
                    return dateFormat != format && data[filterObject.key].getTime() > filterObject[0].value.getTime();
                }
            }
            else if (filterObject[0].matchMode == 'dateBefore') {
                if (filterObject.length == 1) {
                    return data[filterObject.key].getTime() < filterObject[0].value.getTime();
                }
                else if (filterObject[1].matchMode == 'dateIs') {
                    return data[filterObject.key].getTime() < filterObject[0].value.getTime() && dateFormat === format1
                }
                else if (filterObject[1].matchMode == 'dateIsNot') {
                    return data[filterObject.key].getTime() < filterObject[0].value.getTime() && dateFormat != format1
                }
                else if (filterObject[1].matchMode == 'dateAfter') {
                    return data[filterObject.key].getTime() < filterObject[0].value.getTime() &&
                        data[filterObject.key].getTime() > filterObject[1].value.getTime();
                }
            }
            else if (filterObject[0].matchMode == 'dateAfter') {
                if (filterObject.length == 1) {
                    return data[filterObject.key].getTime() > filterObject[0].value.getTime();
                }
                else if (filterObject[1].matchMode == 'dateIs') {
                    return data[filterObject.key].getTime() > filterObject[0].value.getTime() && dateFormat === format1
                }
                else if (filterObject[1].matchMode == 'dateIsNot') {
                    return data[filterObject.key].getTime() > filterObject[0].value.getTime() && dateFormat != format1
                }
                else if (filterObject[1].matchMode == 'dateBefore') {
                    return data[filterObject.key].getTime() > filterObject[0].value.getTime() &&
                        data[filterObject.key].getTime() < filterObject[1].value.getTime();
                }
            }
        }
        function filterColumnwiseData(data) { //callback function
            if (typeof data[filterObject.key] == 'number') {
                return data[filterObject.key].toString().includes(filterObject.value.toString())
            }
            if (typeof data[filterObject.key] == 'string') {
                if (filterObject.length != 1 && filterObject.matchMode == 'contains') {
                    return data[filterObject.key].toLowerCase().includes(filterObject.value.toLowerCase());
                }
                else if (filterObject.length == 1 && filterObject[0].matchMode == 'contains') {
                    return data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'startsWith') {
                    return data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        new RegExp("^" + filterObject[1].value.toLowerCase()).test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'endsWith') {
                    return data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        new RegExp(filterObject[1].value.toLowerCase() + "$").test(data[filterObject.key].toLowerCase())
                }
                else if (filterObject[1].matchMode == 'equals') {
                    return data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        data[filterObject.key].toLowerCase() === (filterObject[1].value.toLowerCase());
                }
                else if (filterObject[1].matchMode == 'notContains' || filterObject[1].matchMode == 'notEquals') {
                    return data[filterObject.key].toLowerCase().includes(filterObject[0].value.toLowerCase()) &&
                        !data[filterObject.key].toLowerCase().includes(filterObject[1].value.toLowerCase())
                }
                //  return filterObject[0].value.toLowerCase().includes(data[filterObject.key].toLowerCase())
            }
            else if (data[filterObject.key] != null) {
                let dateformat = new Date(data[filterObject.key])
                let date = dateformat.getDate();
                return date.toString().includes(filterObject.value.toString());
            }
        }
    }

    clear(table: Table) {
        table.clear()
    }

    globalFilter(columns, data, value)//global filter for lazy loading
    {
        return data.filter(globalFilterData);

        function globalFilterData(data) {//callback function
            let matched: boolean = false;//flag to check if value exist in the row
            columns.forEach(column => {
                if (typeof data[column] == 'string') {//if column datatype is string
                    if (data[column].toLowerCase().includes(value.toLowerCase())) matched = true;//sets flag to true if value matched
                }
                else {//if column datatype is not string(integers,date etc.)
                    if (data[column].toString().includes(value.toString())) matched = true;//sets flag to true if value matched
                }
            });
            return matched;
        }

    }

    disableMultiSelection(formObject, checkboxName)//disable multi selection of checkbox in card view display type
    {
        let array = formObject.value[checkboxName];
        let latestItem = array[array.length - 1];
        array.length = 0;
        array.push(latestItem);
    }

    async createACSUserId(username) {
        var connectionString = "endpoint=https://chatapptest.communication.azure.com/;accesskey=05tg2gMhjPpht5w+x9KftqZpLiRCFiiyrJUdTvOdWlzLZs23B9OHHafoqzEPQrfxqKVUa+4TJ4MV+AcB24pfRA==";
        var userId = await this.ACSUser.createUser(connectionString);
        this.ACS.assignACSUserID(username, userId).subscribe(resp => {
            console.log("assignACSUserID", resp);
            let toast = this.toastController.create({
                message: resp.toString(),
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
        })
    }

    downloadpdf(filename) {

        debugger;

        this.show = true;



        var file = filename.substr(filename.lastIndexOf('\\') + 1);

        file = file.substr(0, file.lastIndexOf('.'));



        this.UserName = localStorage.getItem('username');

        this.mainpageservice.downloadpdf(this.UserName, filename).subscribe((resp) => {

            console.log(resp);

            console.log(filename);

            console.log(file);

            saveAs(resp, file);

        });

        this.show = false;

    }

    onEdit() {
        this.isEditButtonClicked = false;
        this.editorDisabled = false;
    }

    onSave() {
        this.editorDisabled = true;
        var topic: Topic = {
            Content: this.html,
            Id: 0,
            SectionId: 0,
            TopicName: ""
        };
        topic.Content = this.html;
        this.database.updateTopic(this.topicId, topic).subscribe(x => {
            this.messageService.add({ severity: 'success', summary: 'Topic Update', detail: 'Your Topic Data is Updated', life: 5000 });
            this.isEditButtonClicked = true;
        });
    }

   

    onSaveNew(f_form_1083, LinkedMenu) {
        let formObject: any = f_form_1083;

        this.editorDisabled = true;

        var topic: Topic = {

            Content: f_form_1083.form.value.html,

            Id: 0,

            SectionId: f_form_1083.form.value.SectionName.Value,

            TopicName: f_form_1083.form.value.TopicName

        };

        // this.Module[0].moduleList[2].moduleDetails[0].value = 0;

        // this.Module[0].moduleList[2].moduleDetails[1].value = "";

        // this.html = "";

        this.database.saveTopic(topic).subscribe(x => {

            formObject.reset();

            this.messageService.add({ severity: 'success', summary: 'Topic Saved', detail: 'Your Topic Data is Save' });

            setTimeout(() => {

                this.router.navigate(["/menu/first/tabs/" + LinkedMenu]);

            }, 3000);

        }, error => {

            console.log(error);

        });
    }

    filterFromTree(node, topicId, event) {
        this.Module[0].moduleList.forEach((module) => {
            if (module.DisplayType.toLowerCase() == "summernoteview") {
                if (node.parent != undefined) {
                    this.filterByChildrenNodeTree(node, topicId, event);
                }
            }
        });
    }

    filterByChildrenNodeTree(node, topicId, event) {
        if (node.parent != undefined && node.children.length == 0) {
            console.log(event);
            this.database.getTopic(topicId).subscribe((response) => {
                this.topicId = topicId;
                this.html = response.Content;
                this.editorDisabled = true;
                this.isEditButtonClicked = true;
            });
        }
    }

    calculateExcelChanges(data: any, MenuID: number, ModuleID: number, ModuleName: any, otmodulesheet: any, filepath: any) {
        debugger;
        this.show = true;
        this.mainpageservice.UpdatePrimeNgTables1(MenuID, ModuleID, data, localStorage.getItem('username'), ModuleName, this.otmodulesheet, filepath).subscribe(data => {
            let filter1 = localStorage.getItem('navigationExtras');
            this.show = true;
            debugger;
            for (let key of Object.keys(data)) {
                if (+key == 0) {
                    this.Module[0].moduleList[0].multichartdata = data[key];
                }
                else {
                    this.primeNgTableArray[parseInt(key)] = data[parseInt(key)];
                }
            }
            //this.primeNgTableArray[2]=data;
            // this.GetModulewithFilter(filter1);
            this.show = false;
            //otmodulesheet:any={0:'Tiles',2:'Final Production Data',3:'Max Resource Capacity',4:'Resource Utilization',5:'Resource and Material View'}
        });
    }
    otmodulesheet: any = { 0: 'Tiles', 1: 'Production Data' }



//    downloadExcel(filename) {

//        debugger;

//        this.show = true;

//        console.log(filename);

//        var file = filename.substr(filename.lastIndexOf('\\') + 1);

//        console.log(file);

//        //  file = file.substr(0, file.lastIndexOf('.'));

//        this.UserName = localStorage.getItem('username');

//        this.mainpageservice.downloadExcel(this.UserName, filename).subscribe((resp) => {

//            saveAs(resp, file);

//        });

//        this.show = false;

//    }

    AutocompleteFilter(event, dropdownvalue) {
        console.log(event, dropdownvalue)
        let filtered: any = [];
        let query = event.query;
        for (let i = 0; i < dropdownvalue.length; i++) {
            let value = dropdownvalue[i]
            if (value.Text.toLowerCase().indexOf(query.toLowerCase() == 0)) {
                filtered.push(value)
            }
        }
        this.Module[0].moduleList[0].moduleDetails[1].DropDownValues = filtered;
        console.log(filtered)
    }

    //Admin Portal - Expression Maker Submit Function
    saveCssRules(form: any, ID: any, LinkedMenu: any, moduleIndex) {
        var formObject = {
            value: {
                ModuleID: form.value.ModuleID,
                ModuleDetailID: form.value.ModuleDetailID,
                LHSExpression: this.ruleEngine.LHSExp != "" ? this.ruleEngine.LHSExp : this.ruleEngine.currentCol,
                Operator: this.ruleEngine.comparisonOp,
                BenchmarkValue1: this.ruleEngine.benchmarkValue1,
                BenchmarkValue2: this.ruleEngine.benchmarkValue2,
                ApplyForRow: form.value.ApplyForRow
            }
        };

        this.onSubmit(formObject, ID, LinkedMenu, moduleIndex);
        this.ruleEngine.clearExp();
    }
    //Admin Portal - Expression Maker Code Ends Here
    
}

