import { MenuComponent } from './menu/menu.component';
import { Routes } from '@angular/router';
import { MainpagePage } from './mainpage.page';
import { NotFoundComponent }from './pagenotfound.component';
import { MenuGuardService }from '../Services/menu-guard.service';
import {Menu5Page} from './menu5/menu5.component';
import {Menu8Page} from './menu8/menu8.component';
import {Menu9Page} from './menu9/menu9.component';
//import {Menu35Page} from './menu35/menu35.component';
import {Menu36Page} from './menu36/menu36.component';
import {Menu45Page} from './menu45/menu45.component';
import {Menu46Page} from './menu46/menu46.component';
import {Menu47Page} from './menu47/menu47.component';
import {Menu51Page} from './menu51/menu51.component';
import {Menu53Page} from './menu53/menu53.component';
//import {Menu56Page} from './menu56/menu56.component';
import {Menu57Page} from './menu57/menu57.component';
import {Menu58Page} from './menu58/menu58.component';
import {Menu59Page} from './menu59/menu59.component';
import {Menu60Page} from './menu60/menu60.component';
import {Menu61Page} from './menu61/menu61.component';
import {Menu62Page} from './menu62/menu62.component';
import {Menu63Page} from './menu63/menu63.component';
//import {Menu79Page} from './menu79/menu79.component';
import {Menu80Page} from './menu80/menu80.component';
import {Menu81Page} from './menu81/menu81.component';
// import {Menu82Page} from './menu82/menu82.component';
// import {Menu91Page} from './menu91/menu91.component';
import {Menu1130Page} from './menu1130/menu1130.component';
import {Menu1131Page} from './menu1131/menu1131.component';
import {Menu1132Page} from './menu1132/menu1132.component';
import {Menu1153Page} from './menu1153/menu1153.component';
import {Menu1161Page} from './menu1161/menu1161.component';
import {Menu1162Page} from './menu1162/menu1162.component';
import {Menu1163Page} from './menu1163/menu1163.component';
import {Menu1164Page} from './menu1164/menu1164.component';
import {Menu1165Page} from './menu1165/menu1165.component';
import {Menu1166Page} from './menu1166/menu1166.component';
import {Menu1168Page} from './menu1168/menu1168.component';
import {Menu1169Page} from './menu1169/menu1169.component';
//import {Menu1171Page} from './menu1171/menu1171.component';
import {Menu1172Page} from './menu1172/menu1172.component';
//import {Menu1173Page} from './menu1173/menu1173.component';
import {Menu1174Page} from './menu1174/menu1174.component';
//import {Menu1176Page} from './menu1176/menu1176.component';
import {Menu1177Page} from './menu1177/menu1177.component';
import {Menu1178Page} from './menu1178/menu1178.component';
import {Menu1179Page} from './menu1179/menu1179.component';
import {Menu1180Page} from './menu1180/menu1180.component';
import {Menu1181Page} from './menu1181/menu1181.component';
import {Menu1182Page} from './menu1182/menu1182.component';
import {Menu1183Page} from './menu1183/menu1183.component';
import {Menu1184Page} from './menu1184/menu1184.component';
import {Menu1186Page} from './menu1186/menu1186.component';
import {Menu1187Page} from './menu1187/menu1187.component';
import {Menu1188Page} from './menu1188/menu1188.component';
import {Menu1189Page} from './menu1189/menu1189.component';
import {Menu1190Page} from './menu1190/menu1190.component';
import {Menu1191Page} from './menu1191/menu1191.component';
import {Menu1192Page} from './menu1192/menu1192.component';
import {Menu1193Page} from './menu1193/menu1193.component';
import {Menu1194Page} from './menu1194/menu1194.component';
import {Menu1195Page} from './menu1195/menu1195.component';
import {Menu1197Page} from './menu1197/menu1197.component';
//import {Menu1199Page} from './menu1199/menu1199.component';



 export const routes: Routes = [
{
 path: '',
 component: MainpagePage,
children: [
{
path: '', //// This path will be used when no component file is found for the requested url.
component: MenuComponent // loaded when Menu component found for requested url
},
            {
                path: '5',
                canActivate: [MenuGuardService],
                component: Menu5Page
                },
                {
                    path: '8',
                    canActivate: [MenuGuardService],
                    component: Menu8Page
                    },
                    {
                        path: '9',
                        canActivate: [MenuGuardService],
                        component: Menu9Page
                        },
                        {
                            path: '45',
                            canActivate: [MenuGuardService],
                            component: Menu45Page
                        },
                        {
                            path: '47',
                            canActivate: [MenuGuardService],
                            component: Menu47Page
                        },
                        {
                            path: '51',
                            canActivate: [MenuGuardService],
                            component: Menu51Page
                        },
                        
                        {
                            path: '53',
                            canActivate: [MenuGuardService],
                            component: Menu53Page
                        },  
                        {
                            path: '57',
                            canActivate: [MenuGuardService],
                            component: Menu57Page
                        },
                        {
                            path: '58',
                            canActivate: [MenuGuardService],
                            component: Menu58Page
                        },
                        {
                            path: '60',
                            canActivate: [MenuGuardService],
                            component: Menu60Page
                        },
                        {
                            path: '61',
                            canActivate: [MenuGuardService],
                            component: Menu61Page
                        },
                        {
                            path: '62',
                            canActivate: [MenuGuardService],
                            component: Menu62Page
                        },
                        {
                            path: '63',
                            canActivate: [MenuGuardService],
                            component: Menu63Page
                        },
                        {
                            path: '1130',
                            canActivate: [MenuGuardService],
                            component: Menu1130Page
                        },
                        {
                            path: '1131',
                            canActivate: [MenuGuardService],
                            component: Menu1131Page
                        },
                        {
                            path: '1132',
                            canActivate: [MenuGuardService],
                            component: Menu1132Page
                        },
                        {
                            path: '1153',
                            canActivate: [MenuGuardService],
                            component: Menu1153Page
                        },
                        {
                            path: '1161',
                            canActivate: [MenuGuardService],
                            component: Menu1161Page
                        },
                        {
                            path: '1162',
                            canActivate: [MenuGuardService],
                            component: Menu1162Page
                        },
                        {
                            path: '1163',
                            canActivate: [MenuGuardService],
                            component: Menu1163Page
                        },
                        {
                            path: '1164',
                            canActivate: [MenuGuardService],
                            component: Menu1164Page
                        },
                        {
                            path: '1165',
                            canActivate: [MenuGuardService],
                            component: Menu1165Page
                        },
                        {
                            path: '1166',
                            canActivate: [MenuGuardService],
                            component: Menu1166Page
                        },
                        {
                            path: '1168',
                            canActivate: [MenuGuardService],
                            component: Menu1168Page
                        },
                        {
                            path: '1169',
                            canActivate: [MenuGuardService],
                            component: Menu1169Page
                        },
                        {
                            path: '1172',
                            canActivate: [MenuGuardService],
                            component: Menu1172Page
                        },
                        {
                            path: '1174',
                            canActivate: [MenuGuardService],
                            component: Menu1174Page
                        },
                        {
                            path: '1177',
                            canActivate: [MenuGuardService],
                            component: Menu1177Page
                        },
                        {
                            path: '1178',
                            canActivate: [MenuGuardService],
                            component: Menu1178Page
                        },
                        {
                            path: '80',
                            canActivate: [MenuGuardService],
                            component: Menu80Page
                        },
                        {
                            path: '1180',
                            canActivate: [MenuGuardService],
                            component: Menu1180Page
                        },
                        {
                            path: '1181',
                            canActivate: [MenuGuardService],
                            component: Menu1181Page
                        },
                        {
                            path: '1182',
                            canActivate: [MenuGuardService],
                            component: Menu1182Page
                        },
                        {
                            path: '1183',
                            canActivate: [MenuGuardService],
                            component: Menu1183Page
                        },
                        {
                            path: '1184',
                            canActivate: [MenuGuardService],
                            component: Menu1184Page
                        },
                        {
                            path: '1186',
                            canActivate: [MenuGuardService],
                            component: Menu1186Page
                        },
                        {
                            path: '1187',
                            canActivate: [MenuGuardService],
                            component: Menu1187Page
                        },
                        {
                            path: '1188',
                            canActivate: [MenuGuardService],
                            component: Menu1188Page
                        },
                        {
                            path: '1189',
                            canActivate: [MenuGuardService],
                            component: Menu1189Page
                        },
                        {
                            path: '1190',
                            canActivate: [MenuGuardService],
                            component: Menu1190Page
                        },
                        {
                            path: '1191',
                            canActivate: [MenuGuardService],
                            component: Menu1191Page
                        },
                        {
                            path: '1192',
                            canActivate: [MenuGuardService],
                            component: Menu1192Page
                        },
                        {
                            path: '1193',
                            canActivate: [MenuGuardService],
                            component: Menu1193Page
                        },
                        {
                            path: '1194',
                            canActivate: [MenuGuardService],
                            component: Menu1194Page
                        },
                        {
                            path: '1195',
                            canActivate: [MenuGuardService],
                            component: Menu1195Page
                        },
                        {
                            path: '81',
                            canActivate: [MenuGuardService],
                            component: Menu81Page
                        },
                        {
                            path: '1179',
                            canActivate: [MenuGuardService],
                            component: Menu1179Page
                        },
                        {
                            path: '1197',
                            canActivate: [MenuGuardService],
                            component: Menu1197Page
                        },
                        // {
                        //     path: '1198',
                        //     canActivate: [MenuGuardService],
                        //     component: Menu1198Page
                        // },
{
path: '**',
canActivate: [MenuGuardService],
component: NotFoundComponent
},
 ]
}
];