import { ElementRef, Injectable, OnInit, QueryList, ViewChildren } from '@angular/core';
import { WorkFlowService } from '../Services/workflow.service';
import { NavigationExtras, Router } from '@angular/router';
import { Events } from '../Services/events.service';
import { ToastController } from '@ionic/angular';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})
export class WorkFlow implements OnInit {


    filterByValue: { label: string; value: string; }[];

    sortOptions: any[];
    sortKey: string;
    sortField: string;
    sortOrder: number;
    UserName: string;
    datasets: Object;
    constructor(private workflowservice: WorkFlowService,
        private events: Events,
        private router: Router,
        private toastController: ToastController,
        private messageService: MessageService) {
        this.UserName = localStorage.getItem('username');
        this.getDatasetList();
    }


    ngOnInit(): void {
        this.getDatasetList();
    }

    onValidate(form: any, storeProcedure: string, LinkedMenu: number): void {
        console.log("Workflow component", form);
        let datasets = [];
        datasets = form.value.itemIndex;
        this.workflowservice.validateDataset(datasets, storeProcedure).subscribe(resp => {
            console.log("validate response", resp);
            this.navigateOnFormSubmit(LinkedMenu);
        })

    }

    navigateOnFormSubmit(LinkedMenu) {
        let filter = localStorage.getItem("navigationExtras");
        this.events.publish('navigationExtras', JSON.parse(filter));
        let navigationExtras: NavigationExtras = {
            queryParams: JSON.parse(filter)

        };
        // this.router.navigateByUrl("/menu/first/tabs/" + LinkedMenu, navigationExtras);

    }


    RunScenario(form, selectedScenario) {
        debugger;
        let app = localStorage.getItem("CurrentApp");
        let run_id = (JSON.parse(app)).RunTaskId;


        let Dataset=form.value["DatasetList"].Text;
        let UserName = localStorage.getItem('username');
        console.log(run_id);

        let Scenario_id = form.value[selectedScenario];
        if (!Scenario_id && !Dataset) {
            let toast = this.toastController.create({
                message: "Please select scenario and Dataset.",
                duration: 3000,
                position: 'bottom',
                //closeButtonText: 'Ok',

            });
            toast.then(toast => toast.present());
        }
        else {
            this.messageService.add({severity:'info', summary:'Run Task', detail:'Run Task has been started. Please wait for task completion'});
            this.workflowservice.RunWorkflowTask(run_id, Scenario_id, UserName, Dataset).subscribe(resp => {


                //To display loader
                let toast = this.toastController.create({
                    message: resp["Message"],
                    duration: 3000,
                    position: 'bottom',
                    //closeButtonText: 'Ok',

                });
                toast.then(toast => toast.present());
            });
        }

    }

    CreateOrUpdateDataset(tableName: string, username: string) {
        this.workflowservice.CreateOrUpdateDataset(tableName, username).subscribe(resp => {
            console.log(resp);
        });
    }

    onFinalSubmit(MenuId,):void{

        debugger;
        let data: any;

         let CurrentApp = JSON.parse(localStorage.getItem("CurrentApp"));
        this.workflowservice.saveAllModules(MenuId, this.UserName,CurrentApp.ID,data).subscribe(resp => {
        let toast = this.toastController.create({
            message: resp.toString(),

           duration: 3000,

           // position: 'bottom',

            //closeButtonText: 'Ok',

        });
        toast.then(toast => toast.present());
     });


   

     }

     getDatasetList()
     {
        debugger;
            this.workflowservice.getDatasetList(this.UserName).subscribe(resp=>{
            this.datasets=resp;
        })
     }
}