import { Component, NgZone, OnInit } from '@angular/core';
import { MainPageService } from '../Services/MainPage.service'
import { Router } from '@angular/router';
import { Events } from '../Services/events.service';
import { MenuService } from '../Services/menu.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.page.html',
    styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
    UserName: string;
    apps;
    appGroups: any;
    name: string;
    SelectedAppName: any;


    visibleSidebar1;
    constructor(private mainpageservice: MainPageService, private router: Router,
        public events: Events, private menuservice: MenuService, public zone: NgZone) {
        this.events.publish('PageName', 'dashboard');
        events.subscribe('Apps', (apps) => {
            let appNames = [];
            this.appGroups = [];
            const groupCount = apps.reduce((prev, current) => (prev.GroupByIndex > current.GroupByIndex) ? prev : current).GroupByIndex;
            if (groupCount > 1) {
                for (var i = 0; i < groupCount; i++) {
                    let appsGroupWise = apps.filter(app => app.GroupByIndex == (i + 1));
                    this.appGroups[i] = appsGroupWise;
                }
            }
            else {
                appNames = apps.sort(function (a, b) {
                    return a.OrderKey < b.OrderKey ? -1 : (a.OrderKey > b.OrderKey) ? 1 : 0;
                });
                this.appGroups[0] = appNames;

            }
            //console.log(this.appGroups,'appGroups');

            // let appNames=[]; 
            // appNames=apps.sort(function(a,b){
            //     return a.OrderKey < b.OrderKey?-1:(a.OrderKey>b.OrderKey)?1:0;
            // });
            // this.apps=appNames;
        });
    }

    appclicked(app) {
        debugger;
        localStorage.setItem("CurrentApp", JSON.stringify(app));
        this.SelectedAppName = app.label;
        var CacheSubMenu = localStorage.getItem('SubMenu');
        if (CacheSubMenu != null) {
            CacheSubMenu = null;
            localStorage.removeItem('SubMenu');
        }
        localStorage.removeItem('CurrentActiveMenuParentIndex');
        this.events.publish('CurrentActiveMenuParentIndex', null);
        localStorage.removeItem('SubMenu');
        this.name = "";
        localStorage.setItem("CurrentAppName", app.label);
        
        this.events.publish("CurrentAppID", app.ID);
        if (app.DashboardLink != 0) {

            localStorage.setItem('CurrentActiveMenuIndex', '/menu/first/tabs/' + app.DashboardLink);

            this.events.publish("CurrentAppMenus");//subscribed in Mainpage/Menu Component

            this.zone.run(() => {

                this.router.navigate(['menu', 'first', 'tabs', app.DashboardLink], { queryParams: { id: app.ID } });

            })

        }

        else {

            this.router.navigate(['menu', 'first', 'tabs']).then(() => {

                this.events.publish("CurrentAppMenus");//subscribed in Mainpage/Menu Component

            });
        }
        this.visibleSidebar1 = false;
    }

    ngOnInit() {
        this.events.publish('PageName', 'dashboard');
        let dashboardMenu: any;
        this.UserName = localStorage.getItem('username');
        this.menuservice.GetApps(this.UserName).subscribe(resp => {
            let apps = resp;
            this.apps = resp;
            this.events.publish('Apps', apps);//subscribed in header

        });


        // this.mainpageservice.GetDashboardMenu(this.UserName).subscribe(resp=>{

        //   dashboardMenu=resp;
        //   if(resp!="0")
        //   {
        //     this.router.navigate(['menu/dashboard/'+dashboardMenu]);
        //   }
        //  else
        //  {
        //    this.router.navigate(['menu/first']);
        //  }

        // });
    }

    ionViewDidEnter() {
        this.menuservice.GetApps(this.UserName).subscribe(resp => {
            let apps = resp;
            this.events.publish('Apps', apps);
        })
        if (this.apps != undefined && this.apps.length > 0) {
            console.log(this.apps)
            var resultArray = Object.keys(this.apps).map(function (Index) {//converted object
                let Item = this.apps[Index];
                return Item;
            });
            console.log('result', this.apps);
            if (resultArray.length <= 0) {
                this.router.navigate(['menu', 'first', 'tabs']);
            }
        }
        else
            this.router.navigate(['menu', 'first', 'tabs']);
    }

}
