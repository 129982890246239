import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {LocationStrategy,HashLocationStrategy} from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuService } from './Services/menu.service';
//import { DataTablesModule } from 'angular-datatables';
import { AuthGuardService } from './Services/auth-guard.service';
import { MenuGuardService } from './Services/menu-guard.service';

import { AuthenticationService } from './Services/authentication.service';
import { LoginService } from './Services/Login.service';
import { HeaderPage } from './header/header.page';
import { FooterPage } from './footer/footer.page';
import { Badge } from "@ionic-native/badge/ngx";
import { Data } from './Services/data.service';
import {MenuModule} from 'primeng/menu';
import {BadgeModule} from 'primeng/badge';
import {ButtonModule} from 'primeng/button';
import { NotificationsComponent} from './header/notifications/notifications.component';
// import {
//     MatStepperModule, MatInputModule, MatButtonModule, MatExpansionModule,MatTableModule
// } from '@angular/material';
	import { EncrDecrServiceService } from './Services/encr-decr-service.service';		
import { CalculatorComponent } from './calculator/calculator.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {SidebarModule} from 'primeng/sidebar';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
// import { DataCurrencyPipe } from './data-currency.pipe';
// import { FormsModule } from '@angular/forms';



//import { JwtHelperService,  JwtModule } from '@auth0/angular-jwt';

@NgModule({
    declarations: [AppComponent, HeaderPage, FooterPage,NotificationsComponent,CalculatorComponent],
  entryComponents: [NotificationsComponent],
    imports: [BrowserModule, BrowserAnimationsModule,MenuModule,BadgeModule,ButtonModule,SidebarModule,InputSwitchModule,
      // FormsModule,
        //MatStepperModule, MatInputModule, MatButtonModule,MatExpansionModule,MatTableModule,
        IonicModule.forRoot(),
		//  JwtModule.forRoot({		
    //       config: {		
    //         tokenGetter: function  tokenGetter() { 		
    //         return localStorage.getItem('jwt');		
    //         } 		
    //      }		
    //    }),
        AppRoutingModule, HttpClientModule, ServiceWorkerModule.register('ngsw-worker.js', {
  enabled: environment.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: 'registerWhenStable:30000'
})],
  providers: [Badge,
    StatusBar,
      SplashScreen,
      MenuService,
      LoginService,
      AuthenticationService,
      AuthGuardService,
      MenuGuardService,
	  EncrDecrServiceService,MessageService,
    
      Data,
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
