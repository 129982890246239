import {Component} from "@angular/core";

@Component({
  selector: 'app-not-found',
  template: `
    <div style="height:100%;width:100%;text-align:center;margin-top:160px">
        <img  style="height:150px;width:150px;margin-left:-30px;" src="../../../assets/icon/undraw_no_data_re_kwbl.svg" alt="err pic">
        <h4>PAGE NOT AVAILABLE!</h4>
    </div>
  `
})
export class NotFoundComponent {}